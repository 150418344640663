import { ICameraInformation, ICameraState } from './state'

const getters = {
  cameraConfig: (state) => {
    return state.cameraConfig
  },
  liveCamera: (state) => {
    return state.liveCamera
  },
  getReferenceImageUrl: (state: any) => {
    return state.referenceImageUrl
  },
  getHcDueCameras: (state: any) => {
    return state.hcDueCameras
  },
  getLiveStreamError: (state) => {
    return state.liveStreamError
  },
  getCameraLocationList: (state) => {
    return state.cameraLocationList
  },
  getCameraUsers: (state: any) => {
    return state.cameraUsers
  },
  getCameraDocId: (state: { cameraDocId: string }) => {
    return state.cameraDocId
  },
  getPreDeleteReferenceImage: (state: any) => {
    return state.preDeleteReferenceImage
  },
  getCameraInformation: (state: ICameraState): ICameraInformation | null => {
    return state.cameraInformation
  }
  // TODO: Use this when cameraId is not unique issue is resolved
  // getCameraDataFromCameraId:
  //   (state: {
  //     cameraList: {
  //       excludedZones: any[]
  //       cameraId: string
  //     }
  //   }) =>
  //   (cameraId: string) => {
  //     console.log(Array.from(state.cameraList as any))
  //     const camera = Array.from(state.cameraList as any).find(
  //       (camera: any) => camera.cameraId === cameraId
  //     )
  //     return camera ? camera[0]?.excludedZones ?? [] : []
  //   }
}

export default {
  ...getters
}
