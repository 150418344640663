<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
      data-test-id="smtp-configuration-expand-bar"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="smtp-configuration-expand-title"
      >
        SMTP
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>

    <!-- SMTP config data-->
    <div v-if="isExpanded" class="pr-3 pl-3 py-4 form">
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="smtpHost"
        label="SMTP Host"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="smtpHostError"
        :error="smtpHostError.length > 0"
        data-test-id="smtp-host-input"
      ></v-text-field>
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="smtpPort"
        label="SMTP Port"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="smtpPortError"
        :error="smtpPortError.length > 0"
        data-test-id="smtp-port-input"
      ></v-text-field>
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="smtpUser"
        label="SMTP Username"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="smtpUserError"
        :error="smtpUserError.length > 0"
        data-test-id="smtp-user-input"
      ></v-text-field>

      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="smtpPassword"
        label="SMTP Password"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="smtpPasswordError"
        :error="smtpPasswordError.length > 0"
        data-test-id="smtp-password-input"
      ></v-text-field>
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="smtpReceiverAddress"
        label="Receiver Email Address"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="smtpReceiverAddressError"
        :error="smtpReceiverAddressError.length > 0"
        data-test-id="smtp-receiver-address-input"
      ></v-text-field>
      <p
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
        class="text-left red--text"
        data-test-id="smtp-receiver-address-warning"
      >
        *Default senders email address is notification@promiseq.com
      </p>

      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="smtp-notification-switch-title"
        >
          Turn on/off SMTP notifications
        </div>
        <v-switch
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          id="alarm-ftp-notification-switch"
          v-model="isSMTPNotificationEnabled"
          text
          class="notification-switch pt-1"
          color="secondary"
          inset
          @change="onClickIsFTPEnabled"
          :disabled="isToggleDisabled || !isWriteEnabled"
          data-test-id="smtp-notification-switch"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: { Button }
})
export default class SmtpNotificationConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string
  @Prop() isNotificationFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public isSMTPNotificationEnabled: boolean = false
  public smtpHost: string = ''
  public smtpPort = ''
  public smtpReceiverAddress: string = ''
  public smtpUser: string = ''
  public smtpPassword: string = ''

  public smtpHostError: string = ''
  public smtpPortError: string = ''
  public smtpReceiverAddressError: string = ''
  public smtpUserError: string = ''
  public smtpPasswordError: string = ''

  public originalSmtpData: any = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isNotificationFieldEnabled === null
      ? this.configuration?.blockToggles?.notifications !== true
      : this.isNotificationFieldEnabled !== true && this.level !== 'client'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('notificationType')
  @Watch('isNotificationFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      this.isSMTPNotificationEnabled = showingConfig.isSMTPNotificationEnabled
      this.smtpHost = showingConfig.smtpNotificationData?.host ?? ''
      this.smtpPort = showingConfig.smtpNotificationData?.port ?? ''
      this.smtpUser = showingConfig.smtpNotificationData?.user ?? ''
      this.smtpPassword = showingConfig.smtpNotificationData?.password ?? ''
      this.smtpReceiverAddress =
        showingConfig.smtpNotificationData?.receiverAddress ?? ''

      this.isExpanded = this.isSMTPNotificationEnabled

      this.originalSmtpData = {
        isSMTPNotificationEnabled: this.isSMTPNotificationEnabled,
        smtpHost: this.smtpHost,
        smtpPort: this.smtpPort,
        smtpUser: this.smtpUser,
        smtpPassword: this.smtpPassword,
        smtpReceiverAddress: this.smtpReceiverAddress
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isSMTPNotificationEnabled !==
      this.originalSmtpData.isSMTPNotificationEnabled
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.smtpHost?.trim() !== this.originalSmtpData.smtpHost ||
      this.smtpPort?.trim() !== this.originalSmtpData.smtpPort ||
      this.smtpUser?.trim() !== this.originalSmtpData.smtpUser ||
      this.smtpPassword?.trim() !== this.originalSmtpData.smtpPassword ||
      this.smtpReceiverAddress?.trim() !==
        this.originalSmtpData.smtpReceiverAddress
    )
  }

  //watch for FTP start
  public onClickIsFTPEnabled(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  @Watch('smtpHost')
  @Watch('smtpPort')
  @Watch('smtpUser')
  @Watch('smtpPassword')
  @Watch('smtpReceiverAddress')
  @Watch('isSMTPNotificationEnabled')
  public onClickSaveFTPConfig() {
    let isError: boolean = false
    this.smtpHostError = ''
    this.smtpPortError = ''
    this.smtpUserError = ''
    this.smtpPasswordError = ''
    this.smtpReceiverAddressError = ''

    if (this.smtpHost?.trim() == '') {
      isError = true
      this.smtpHostError = 'SMTP host is required'
    }

    if (this.smtpPort?.trim() == '') {
      isError = true
      this.smtpPortError = 'SMTP port is required'
    }

    if (this.smtpUser?.trim() == '') {
      isError = true
      this.smtpUserError = 'SMTP user is required'
    }

    if (this.smtpPassword?.trim() == '') {
      isError = true
      this.smtpPasswordError = 'SMTP password is required'
    }

    if (this.smtpReceiverAddress?.trim() == '') {
      isError = true
      this.smtpReceiverAddressError = 'SMTP receiver address is required'
    }

    const smtpData = {
      host: this.smtpHost?.trim(),
      port: this.smtpPort?.trim(),
      password: this.smtpPassword?.trim(),
      user: this.smtpUser?.trim(),
      receiverAddress: this.smtpReceiverAddress?.trim(),
      isError,
      isChanged: isError
        ? false
        : this.hasDataChanged() || this.hasSwitchChanged()
    }
    this.onChange(smtpData)
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
