import { functions, storage } from '@/provider/firebase'
import { cameraCollection } from '@/provider/firebase'
const cameraNamespace = 'camera'

const wait = (ms) => new Promise((r) => setTimeout(r, ms))

export default {
  namespaced: true,
  getHeitelImage(payload, delay = 5000, retries = 5) {
    return new Promise((resolve, reject) => {
      return functions
        .httpsCallable('getHeitelImage')(payload)
        .then(resolve)
        .catch((err) => {
          if (retries > 0) {
            return wait(delay)
              .then(this.getHeitelImage.bind(this, payload, delay, retries - 1))
              .then(resolve)
              .catch(reject)
          }
          return reject(err)
        })
    })
  },
  async changeWebhookTriggerConfig(uid: string, payload: any) {
    try {
      return cameraCollection
        .doc(uid)
        .set({ webhookTriggerData: payload }, { merge: true })
    } catch (error) {
      console.error('Error adding webhook trigger config ', error)
    }
  },
  async changeIsWebhookTriggerEnabledStatus(uid: string, payload: any) {
    try {
      return cameraCollection
        .doc(uid)
        .set({ isWebhookTriggerEnabled: payload }, { merge: true })
    } catch (error) {
      console.error('Error updating webhook trigger status: ', error)
    }
  },
  async fetchCameraPtzData(uid: string) {
    try {
      const cameraDoc = await cameraCollection.doc(uid).get()
      return cameraDoc.data()
    } catch (error) {
      console.error('Error fetching camera data: ', error)
    }
  },
  async changeIsEvalinkNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return cameraCollection
        .doc(uid)
        .set({ isEvalinkEnabled: payload }, { merge: true })
    } catch (error) {
      console.error('Error updating Evalink notification status: ', error)
    }
  },
  async changeIsEvalinkDeviceOverrideEnabledStatus(uid: string, payload: any) {
    try {
      return cameraCollection
        .doc(uid)
        .set(
          { 'evalinkData.isDeviceOverrideEnabled': payload },
          { merge: true }
        )
    } catch (error) {
      console.error('Error updating Evalink device override status: ', error)
    }
  },
  async addEvalinkConfig(uid: string, payload: any) {
    try {
      return cameraCollection
        .doc(uid)
        .set({ evalinkData: payload }, { merge: true })
    } catch (error) {
      console.error('Error adding evalink config ', error)
    }
  },
  async generateSiaReceiver(uid: string, data: any) {
    try {
      return functions.httpsCallable('generateSiaReceiver')({
        clientId: uid,
        data
      })
    } catch (error) {
      console.error('Error generating sia receiver', error)
    }
  },
  async updatePtzCommand(uid: string, data: string) {
    try {
      return functions.httpsCallable('sendCameraPtzControl')({
        cameraId: uid,
        data
      })
    } catch (error) {
      console.error('Error updating PTZ Command', error)
    }
  },
  async fetchDownloadUrl(ref: string, delay = 3000, retries = 3) {
    return new Promise((resolve, reject) => {
      return storage
        .ref(ref)
        .getDownloadURL()
        .then(resolve)
        .catch((error) => {
          if (retries > 0) {
            return wait(delay)
              .then(this.fetchDownloadUrl.bind(this, ref, delay, retries - 1))
              .then(resolve)
              .catch(reject)
          }
          return reject(error)
        })
    })
  }
}
