<template>
  <v-app
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <material-dialog />

    <!-- Navigation -->
    <Navigation v-if="isNavigationEnabled && !isUserWorker" />
    <!-- /Navigation -->
    <div v-if="isNotFound">
      <v-main>
        <NotFound />
      </v-main>
    </div>

    <core-view v-else />
    <UpdateAlert v-if="isUpdateAlertVisible" />
    <v-footer
      v-if="!smallScreen"
      app
      fixed
      style="display: flex; justify-content: space-between; z-index: 10"
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.darkPrimaryColor
            : getColors.lightPrimaryColor
      }"
    >
      <span
        :style="{
          color: isUserAdmin && isDarkModeToggleEnabled ? '#fff' : '#000'
        }"
      >
        &copy; promiseQ GmbH {{ new Date().getFullYear() }} -
        <router-link to="/imprint" v-slot="{ href, navigate }" custom>
          <button :href="href" @click="navigate"><b>Imprint</b></button>
        </router-link>
      </span>
      <a
        v-if="!isUserAdmin"
        href="https://promiseqknowledgebase.super.site/e7d137d115fd4a219068b8d34be4d771"
        class="clickable-span"
        target="_blank"
        :style="{
          color: isUserAdmin && isDarkModeToggleEnabled ? '#fff' : '#000'
        }"
      >
        {{ this.displayVersion }}
      </a>
      <a
        v-else
        href="https://promiseqadmindocuments.super.site"
        class="clickable-span"
        target="_blank"
        :style="{
          color: isUserAdmin && isDarkModeToggleEnabled ? '#fff' : '#000'
        }"
      >
        {{ this.displayVersion }}
      </a>

      <!-- Snackbar message -->
      <v-snackbar v-model="snackbar">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <Button
            text
            v-bind="attrs"
            @onButtonClick="snackbar = false"
            btnText="Close"
            class="secondary black--text text-subtitle-2"
          />
        </template>
      </v-snackbar>
      <!-- End of Snackbar message -->
    </v-footer>

    <v-footer
      v-if="smallScreen"
      app
      fixed
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        padding: 5px 15px;
      "
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled ? '#938D76' : '#DCDCDC'
      }"
    >
      <span
        :style="{
          color: '#000000',
          fontSize: '10px'
        }"
      >
        &copy; promiseQ GmbH {{ new Date().getFullYear() }} -
        <router-link to="/imprint" v-slot="{ href, navigate }" custom>
          <button
            :href="href"
            @click="navigate"
            style="
              background: none;
              border: none;
              padding: 0;
              font-weight: bold;
              font-size: 10px;
              cursor: pointer;
            "
          >
            Imprint
          </button>
        </router-link>
      </span>

      <a
        v-if="!isUserAdmin"
        href="https://promiseqknowledgebase.super.site/e7d137d115fd4a219068b8d34be4d771"
        class="clickable-span"
        target="_blank"
        :style="{
          color: '#000000',
          fontSize: '10px'
        }"
      >
        {{ this.displayVersion }}
      </a>

      <a
        v-else
        href="https://promiseqadmindocuments.super.site"
        class="clickable-span"
        target="_blank"
        :style="{
          color: '#000000',
          fontSize: '10px'
        }"
      >
        {{ this.displayVersion }}
      </a>

      <!-- Snackbar message -->
      <v-snackbar v-model="snackbar" style="position: absolute; bottom: 50px">
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <Button
            text
            v-bind="attrs"
            @onButtonClick="snackbar = false"
            btnText="Close"
            class="secondary black--text text-subtitle-2"
          />
        </template>
      </v-snackbar>
      <!-- End of Snackbar message -->
    </v-footer>

    <SnackBar />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import 'firebase/analytics'
import Navigation from './components/app/Navigation.vue'
import SnackBar from '@/components/SnackBar.vue'
import { Getter, Mutation, Action } from 'vuex-class'
import NotFound from '../src/components/error-pages/NotFound.vue'
import UpdateAlert from './components/updateAlert.vue'
import { GIT_TAG, PROJECT_ID } from './utils/EnvMapping'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Navigation,
    SnackBar,
    NotFound,
    UpdateAlert
  }
})
export default class App extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Getter('getIsNotFound', namespaceConfig) public getIsNotFound!: boolean

  @Getter('getVersionInfo', namespaceConfig)
  private getVersion!: string | null

  @Action('getVersionNumberFromFirestore', namespaceConfig)
  private getVersionNumberFromFirestore!: () => Promise<string>

  @Mutation('setIsNotFound', namespaceConfig) public setIsNotFound!: any
  public isNavigationEnabled = true

  public snackbar: boolean = false
  public isNotFound: boolean = false
  public snackbarMessage: string = ''

  public async mounted() {
    this.handleNavigationBar()
    this.setTheme()
    await this.getVersionNumberFromFirestore()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserWorker() {
    return this.currentUser?.role === 'Worker'
  }

  public get isUpdateAlertVisible() {
    return this.$route.path !== '/login'
  }

  public get isDarkModeEnabled() {
    return this.currentUser?.isDarkModeEnabled ?? false
  }

  public get smallScreen() {
    return isCompactView()
  }

  @Watch('isUserAdmin')
  @Watch('isDarkModeEnabled')
  public setTheme() {
    if (this.isUserAdmin) {
      // this.$vuetify.theme.dark = this.isDarkModeEnabled
      this.$store.commit('config/setIsDarkModeEnabled', this.isDarkModeEnabled)
    } else {
      // this.$vuetify.theme.dark = false
      this.$store.commit('config/setIsDarkModeEnabled', false)
    }
  }

  @Watch('$route')
  public handleNavigationBar() {
    const pathsForAllUsers = [
      '/terms-and-conditions',
      '/eagle-eye',
      '/login',
      '/disclaimer',
      '/knowledge-base',
      '/user-settings',
      '/worker-space-person',
      '/worker-home',
      '/api'
    ]
    const pathsForWorker = ['/worker-space-info']
    const isIncidentPath = window.location.pathname.startsWith('/incident/')
    const isSandBoxPath = window.location.pathname.startsWith('/sandbox')

    const isRestrictedPath = (paths: string | string[]): boolean => {
      return paths.includes(window.location.pathname) || isIncidentPath
    }

    this.isNavigationEnabled = !isRestrictedPath(pathsForAllUsers)

    if (this.$store.state.user.currentUser?.role === 'Worker') {
      this.isNavigationEnabled = !isRestrictedPath([
        ...pathsForAllUsers,
        ...pathsForWorker
      ])
    }
    if (this.isUserAdmin && isSandBoxPath) {
      this.$router.push('/')
    }
  }

  @Watch('currentUser')
  public checkUserPreConditions() {
    if (this.currentUser?.isTermsAndConditionsAccepted === false) {
      // redirect to route named TermsAndConditions if already not there
      if (this.$route.name !== 'TermsAndConditions') {
        this.$router.push({ name: 'TermsAndConditions' })
      }
    }
  }

  @Watch('getIsNotFound')
  public handleIsNotFoundChange(value: boolean) {
    this.isNotFound = value
  }

  @Watch('$route')
  @Watch('$route.params')
  public async watchRouteParams() {
    this.setIsNotFound(false)
  }

  public get displayVersion() {
    const ID = ['promiseq-production']
    let version = this.getVersion || GIT_TAG || ''
    if (ID.includes(PROJECT_ID) && version != '') {
      version = version.split('-').slice(0, 3).join('-')
    }
    return version
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied.`)
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  overscroll-behavior: none;
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--v-void-base, #ffffff);
  margin: 0;
  padding: 0;
  user-select: none;
}
.firebase-emulator-warning {
  display: none;
}
.additional-info--modal-dark {
  background-color: #333333 !important;
}
.additional-info--modal-light {
  background-color: #ffffff !important;
}

.clickable-span {
  cursor: pointer;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

/* Firefox Scrollbar Styling */
* {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 transparent;
}

.v-data-table__wrapper {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.v-application--wrap {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

@media screen and (width <= 320px) {
  .v-main__wrap {
    position: absolute;
    left: 0;
  }
  .loader-wrapper {
    transform: translateX(calc(50vw - 24px));
  }
}
@media screen and (320px < width <= 480px) {
  .v-main__wrap {
    left: 0;
  }
  .loader-wrapper {
    transform: translateX(calc(50vw - 24px));
  }
}
@media screen and (width > 480px) {
  .v-main__wrap {
    position: unset;
  }
}
</style>
