import firebase from 'firebase/compat/app'

import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/database'
import 'firebase/compat/auth'
import user from './user'
import todos from './todos'
import config from './config'
import navigation from './navigation'
import camera from './camera'
import statistics from './statistics'
import client from './client'
import site from './site'
import worker from './worker'
import {
  EMULATE_FIRESTORE,
  EMULATE_AUTH,
  EMULATE_DATABASE,
  EMULATE_STORAGE,
  EMULATE_FUNCTIONS,
  FIREBASE_CONFIGURATION_FILE,
  CLOUD_FUNCTION_REGION
} from '@/utils/EnvMapping'

const app = firebase.initializeApp(
  await fetch(FIREBASE_CONFIGURATION_FILE).then((response) => response.json())
)

const storage = app.storage()
const firestore = app.firestore()
const rtdb = app.database()
const auth = app.auth()
firestore.settings({
  merge: true,
  experimentalForceLongPolling: EMULATE_FIRESTORE === 'true'
})

if (EMULATE_FIRESTORE === 'true') {
  firebase.firestore().useEmulator('localhost', 8082)
}

if (EMULATE_AUTH === 'true') {
  firebase.auth().useEmulator('http://localhost:9099')
}

if (EMULATE_DATABASE === 'true') {
  firebase.database().useEmulator('localhost', 9000)
}

if (EMULATE_STORAGE === 'true') {
  firebase.storage().useEmulator('localhost', 9199)
}

export const statusCollection = firestore.collection('status')

export const todosCollection = firestore.collection('todos')

export const userCollection = firestore.collection('user')

export const configCollection = firestore.collection('config')

export const cameraCollection = firestore.collection('camera')

export const siteCollection = firestore.collection('site')

export const clientCollection = firestore.collection('client')

export const organizationCollection = firestore.collection('organization')

export const workerQueueCollection = firestore.collection('workerQueue')

export const eagleEyeAccountCollection = firestore.collection('eagleEyeAccount')

export const mailCollection = firestore.collection('mail')

export const Timestamp = firebase.firestore.Timestamp

// For local emulation using local firestore uncomment next 2 lines and comment last line
//export firebase.functions().useEmulator('localhost', 5000)
//export const functions = firebase.app().functions()
const functions = firebase.app().functions(CLOUD_FUNCTION_REGION)
if (EMULATE_FUNCTIONS === 'true') {
  functions.useEmulator('localhost', 5000)
}

// const onPremiseApp = firebase.initializeApp(configuration, 'on-premise')

export {
  firestore,
  rtdb,
  auth,
  functions,
  storage,
  config as configProvider,
  camera as cameraProvider,
  statistics as statisticsProvider,
  user as userProvider,
  todos as todosProvider,
  navigation as navigationProvider,
  client as clientProvider,
  site as siteProvider,
  worker as workerProvider
}
