<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
      data-test-id="email-expand-bar"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="email-expand-title"
      >
        Email
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>
    <div v-if="isExpanded" class="pb-3 form d-flex flex-column">
      <div class="text-h7 text-left py-3">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor
          }"
          class="pl-3"
          data-test-id="receiving-emails-title"
        >
          Receiving Emails
        </div>
      </div>
      <div>
        <template>
          <div
            class="receiving-email-row mb-4 pt-0 pl-3"
            v-for="(notificationEmail, emailIndex) in notificationEmails"
            :key="emailIndex"
          >
            <v-row class="email-row">
              <v-col cols="10">
                <v-text-field
                  :dark="isUserAdmin && isDarkModeToggleEnabled"
                  label="Email"
                  outlined
                  color="secondary"
                  hide-details="true"
                  :value="notificationEmail.email"
                  @input="(value) => onChangeEmail(value, emailIndex)"
                  :disabled="isToggleDisabled || !isWriteEnabled"
                  data-test-id="receiving-email-input"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="email-btn"
                  style="
                    float: right;
                    color: none;
                    width: 35px;
                    height: 45px;
                    margin-left: 7px;
                    margin-right: 14px;
                    margin-top: 4px;
                    margin-bottom: 4px;
                  "
                  elevation="0"
                  @click="onClickRemoveEmail(emailIndex)"
                  :disabled="isToggleDisabled || !isWriteEnabled"
                  data-test-id="remove-receiving-email-button"
                >
                  <v-icon>{{ 'mdi-close-circle' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>
      <div class="pb-3 pl-3">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="10" xl="2" class="text-center">
            <v-btn
              @click="onClickAddEmail"
              block
              class="left-align-btn no-shadow"
              :disabled="isToggleDisabled || !isWriteEnabled"
              data-test-id="add-receiving-email-button"
            >
              <v-icon class="mr-2">{{ 'mdi-plus-circle' }}</v-icon>
              <span class="align-middle">Add Email</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="font-weight-bold text-left notification-title pl-3">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="alarm-email-notification-lable"
        >
          Turn on/off email notifications
        </div>
        <v-switch
          id="alarm-email-notification-switch"
          v-model="isEmailNotificationEnabled"
          text
          inset
          class="notification-switch"
          color="secondary"
          :disabled="isToggleDisabled || !isWriteEnabled"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          @change="onIsEmailNotificationEnabled"
          data-test-id="alarm-email-notification-switch"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class EmailNotificationConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string
  @Prop() isNotificationFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public isEmailNotificationEnabled: boolean = false
  public notificationEmails: { email: string }[] = []

  public originalEmailData: any = null

  public isNotificationDataInherited: boolean = true

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isNotificationFieldEnabled === null
      ? this.configuration?.blockToggles?.notifications !== true
      : this.isNotificationFieldEnabled !== true && this.level !== 'client'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('notificationType')
  @Watch('isNotificationFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      this.isEmailNotificationEnabled =
        showingConfig?.isEmailNotificationEnabled ?? false
      this.notificationEmails = showingConfig?.notificationEmails ?? []

      this.isExpanded = this.isEmailNotificationEnabled

      this.originalEmailData = {
        isEmailNotificationEnabled: this.isEmailNotificationEnabled,
        notificationEmails: JSON.parse(
          JSON.stringify([...this.notificationEmails])
        )
      }
    }
    if (!this.hasDataChanged()) {
      this.notificationEmails = JSON.parse(
        JSON.stringify([...this.originalEmailData.notificationEmails])
      )
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isEmailNotificationEnabled !==
      (this.originalEmailData.isEmailNotificationEnabled || false)
    )
  }

  public hasDataChanged(): boolean {
    return (
      JSON.stringify(
        this.notificationEmails.filter((item) => item.email !== '')
      ) !== JSON.stringify(this.originalEmailData.notificationEmails)
    )
  }

  public onIsEmailNotificationEnabled(value: boolean) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public onClickAddEmail() {
    this.notificationEmails.push({ email: '' })
  }

  public onClickRemoveEmail(fieldIndex: number) {
    const current = [...this.notificationEmails]
    current.splice(fieldIndex, 1)
    this.notificationEmails = current
  }

  public onChangeEmail(value, valueIndex) {
    this.notificationEmails[valueIndex].email = value
    this.onClickSaveNotificationEmail()
  }

  @Watch('notificationEmails')
  public onClickSaveNotificationEmail() {
    const notificationData = {
      notificationEmails: this.notificationEmails.filter(
        (item) => item.email !== ''
      ),
      isChanged: this.hasDataChanged()
    }

    this.onChange(notificationData)
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}
.no-shadow {
  box-shadow: none !important;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}
.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
