import firebase from 'firebase/compat/app'
import {
  auth,
  clientCollection,
  firestore,
  functions,
  userCollection
} from '@/provider/firebase'
import { store } from '@/store'
import EventBus from '@/utils/EventBus'
import { getDefaultState as getConfigDefaultState } from '@/store/modules/config/state'
import { FirebaseError } from 'firebase/app'
import { generateEmailContent } from '../../../utils/emailTemplateUtils'
import { AUTH_DOMAIN } from '@/utils/EnvMapping'

const collectionNamespace = 'user'
let userListener = null
const authUrl = `https://${AUTH_DOMAIN}`
const actionCodeSettings = {
  url: authUrl,
  handleCodeInApp: false
}
export default {
  namespaced: true,
  async updateInitUserData(userId: string) {
    if (userId) {
      const userRef = this.docRef(userId)

      const userSnapshot = await userRef.get()
      const userData = userSnapshot.data()

      store.commit('user/setCurrentUser', {
        ...userData,
        id: userSnapshot.id
      })

      const {
        autoRefreshSettings: defaultAutoRefreshSettings,
        isAlarmRefreshEnabled: defaultIsAlarmRefreshEnabled
      } = getConfigDefaultState()
      const {
        alarmRefreshSettings = defaultAutoRefreshSettings,
        isAlarmRefreshEnabled = defaultIsAlarmRefreshEnabled
      } = userData
      store.commit('config/setIsAlarmRefreshEnabled', isAlarmRefreshEnabled)
      store.commit('config/setAutoRefreshSettings', alarmRefreshSettings)

      store.commit('user/setLoginUser', {
        ...userData,
        id: userSnapshot.id,
        uuid: userSnapshot.id
      })

      return userData
    }
  },
  async fetchUserById(userId) {
    try {
      const userRef = this.docRef(userId)
      const doc = await userRef.get()
      if (doc.exists) {
        return doc.data()
      } else {
        throw new Error('No user found with the provided ID.')
      }
    } catch (error) {
      console.error('Error fetching user by ID:', error)
      throw new Error('Failed to fetch user data.')
    }
  },
  subscribeForUserData(userId: any) {
    if (userId) {
      const userRef = this.docRef(userId)
      return (userListener = userRef.onSnapshot((document) => {
        store.commit('user/setCurrentUser', {
          ...document.data(),
          id: document.id
        })
        const {
          autoRefreshSettings: defaultAutoRefreshSettings,
          isAlarmRefreshEnabled: defaultIsAlarmRefreshEnabled
        } = getConfigDefaultState()
        const {
          alarmRefreshSettings = defaultAutoRefreshSettings,
          isAlarmRefreshEnabled = defaultIsAlarmRefreshEnabled
        } = document.data()

        store.commit('config/setIsAlarmRefreshEnabled', isAlarmRefreshEnabled)
        store.commit('config/setAutoRefreshSettings', alarmRefreshSettings)

        store.commit('user/setLoginUser', {
          ...document.data(),
          id: document.id,
          uuid: document.id
        })
        //@ts-ignore
        window.userSync = true
      }))
    }
  },
  docRef(userId: string) {
    return userCollection.doc(userId)
  },
  async notifyAdmins(
    adminEmails: string[],
    payload: {
      display_name?: string
      first_name?: string
      last_name?: string
      email: string
    }
  ) {
    const baseUrl = window.location.origin
    const approvalPageUrl = `${baseUrl}/user-management`
    const emailContent = await generateEmailContent('approvalNotifyForAdmin', {
      userFirstName: payload.first_name,
      userLastName: payload.last_name,
      email: payload.email,
      approvalPageUrl: approvalPageUrl
    })
    if (adminEmails.length > 0) {
      await this.sendEmail(
        adminEmails.join(','),
        emailContent.subject,
        emailContent.html,
        ''
      )
    }
  },
  async createUser(payload: {
    display_name?: string
    first_name?: string
    last_name?: string
    email: string
    role?: string
    password?: string
  }) {
    payload.password = payload.password
      ? payload.password
      : 'random' + Math.random().toString(36).slice(-8)

    const userSignInMethods = await auth.fetchSignInMethodsForEmail(
      payload.email
    )
    const isAlreadyExists = userSignInMethods?.length > 0

    const createUserFunction = functions.httpsCallable('createUser')
    try {
      await createUserFunction(payload)
      if (payload.password.includes('random') && !isAlreadyExists) {
        const userSnapshot = await userCollection
          .where('email', '==', payload.email)
          .limit(1)
          .get()

        if (!userSnapshot.empty) {
          const userDocRef = userSnapshot.docs[0].ref
          await userDocRef.update({ isApproved: true })
        }
      } else if (!payload.password.includes('random')) {
        const credentials = await auth.signInWithEmailAndPassword(
          payload.email,
          payload.password
        )
        const email = payload.email
        const firstName = payload.first_name
        const lastName = payload.last_name
        await sendEmailForCloudFunction({
          email,
          functionName: 'sendCustomVerificationEmail',
          firstName,
          lastName
        })
        const adminsSnapshot = await userCollection
          .where('role', '==', 'Administrator')
          .get()
        const adminEmails = adminsSnapshot.docs.map((doc) => doc.data().email)
        await this.notifyAdmins(adminEmails, payload)
        EventBus.$emit('spreadSnack', {
          text: 'A verification link has been sent your email account.'
        })
        return {
          status: 'success',
          message: 'User added successfully and admin notified'
        }
      }
    } catch (error) {
      console.error('Error creating user:', error)

      if (error instanceof FirebaseError) {
        throw new Error(error.message)
      }
      throw new Error('Failed to create user')
    }
  },
  load(email: any) {
    return new Promise((resolve, reject) => {
      userCollection
        .where('email', '==', email)
        .get()
        .then((result) => {
          let users = []
          result.forEach((doc) => {
            const currentUser = doc.data()
            users.push(currentUser)
          })
          resolve(users[0])
        })
        .catch((error) => {
          reject(error)
          console.error(error)
        })
    })
  },
  unsubscribeForUserData() {
    if (userListener !== null && userListener !== undefined) {
      userListener()
    }
  },
  login(payload: { email: string; password: string }) {
    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(async (credentials) => {
          if (!credentials.user.emailVerified) {
            credentials.user
              .sendEmailVerification(actionCodeSettings)
              .then(() => {
                reject(new Error('Please verify your email.'))
              })
              .catch((error) => {
                console.error(error)
                reject(error)
              })
          } else {
            const userDoc = await userCollection.doc(credentials.user.uid).get()
            const userData = userDoc.data()
            if (userData && userData.isApproved === false) {
              reject({
                code: 'auth/admin-approval-required',
                message: 'Your account is awaiting admin approval.'
              })
            } else {
              resolve({ uid: credentials.user.uid, ...userData })
            }
          }
        })
        .catch((error) => {
          console.error(error)
          store.commit('setError', error, { root: true })
          store.commit('setLoading', false, { root: true })
          reject(error)
        })
    })
  },
  async restorePassword(restoreEmail: string) {
    try {
      const setPasswordReset = await functions.httpsCallable(
        'setPasswordReset'
      )({ email: restoreEmail })
      if (setPasswordReset.data.success) {
        const email = restoreEmail
        if (email) {
          await sendEmailForCloudFunction({
            email,
            functionName: 'sendCustomPasswordResetEmail'
          })
        }
      } else {
        throw new Error('Error sending password reset email')
      }
    } catch (error) {
      // Silence errors without displaying them
    }
  },
  logout() {
    return firebase.auth().signOut()
  },
  delete(payload: any[]) {
    return new Promise(async (resolve, reject) => {
      try {
        const batch = firestore.batch()
        const userKeys = payload.flatMap((user) => user.keys)
        const userRefs = payload.map((user) => userCollection.doc(user.id))
        const orgRefs = payload.map((user) => user?.rootUser)

        const subCollectionDeletions = payload.map((user) => {
          const eagleEyeRef = userCollection.doc(user.id).collection('eagleEye')
          return eagleEyeRef.get().then((snapshot) => {
            snapshot.forEach((doc) => batch.delete(doc.ref))
          })
        })

        userRefs.forEach((userRef) => batch.delete(userRef))

        await Promise.all(subCollectionDeletions)
        const filteredUserKeys = userKeys.filter((key) => {
          return key
        })

        await batch.commit()
        await this.deleteOrganization(orgRefs)
        resolve(true)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },
  async deleteOrganization(orgRefs: any) {
    return new Promise(async (resolve, reject) => {
      try {
        await orgRefs?.map(async (org: any) => {
          if (org) {
            const orgRef = userCollection.doc(org?.id)
            const docs = await userCollection
              .where('rootUser', '==', orgRef)
              .get()

            if (docs?.docs?.length <= 0) {
              orgRef.delete()
            }
          }
        })

        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  },
  async signInWorker() {
    try {
      const response = await auth.signInAnonymously()
      return response.user.uid
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async changeIsAskCrowdWorkersStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isAskCrowdWorkers: payload })
    } catch (error) {
      console.error('Error updating isAskCrowdWorkers: ', error)
    }
  },
  async changeIsNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isNotificationEnabled: payload })
    } catch (error) {
      console.error('Error updating isNotificationEnabled: ', error)
    }
  },
  // async updateAlarmDefinitions(
  //   uid: string,
  //   payload: any,
  //   isOrganization?: boolean
  // ) {
  //   try {
  //     return firestore
  //       .collection(
  //         isOrganization ? organizationCollectionNamespace : collectionNamespace
  //       )
  //       .doc(uid)
  //       .update({ alarmDefinitionConfig: payload })
  //   } catch (error) {
  //     console.error('Error updating Alarm Definitions: ', error)
  //   }
  // },
  async changeUnarmedTimeRange(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ unarmedTimeRange: payload })
    } catch (error) {
      console.error('Error updating unarmed time range: ', error)
    }
  },
  async changeContact(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ contact: payload })
    } catch (error) {
      console.error('Error updating contact: ', error)
    }
  },
  async sendEmail(
    useremail: string,
    subject: string,
    html: string,
    attachments: string
  ) {
    const message = {
      subject: subject,
      html: html
    }

    if (attachments) {
      message['attachments'] = attachments
    }

    try {
      return firestore.collection('mail').add({
        to: [useremail],
        message
      })
    } catch (error) {
      console.error('Error sending email: ', error)
    }
  },
  async changeUserRefreshEnabledStatus(
    uid: string,
    isAlarmRefreshEnabled: boolean
  ) {
    try {
      return await userCollection.doc(uid).update({
        isAlarmRefreshEnabled
      })
    } catch (error) {
      console.error(
        `Error updating refresh enabled status for user ${uid}:`,
        error
      )
      throw error
    }
  },
  async changeCompanyName(userId: string, companyName: string) {
    const userRef = userCollection.doc(userId)
    await userRef.update({ companyName: companyName })
  },
  async changeUserRefreshSettings(
    uid: string,
    alarmRefreshSettings: {
      autoRefreshPeriod: number
      unit: string
      value: number
    }
  ) {
    try {
      return await userCollection.doc(uid).update({
        alarmRefreshSettings
      })
    } catch (error) {
      console.error(`Error updating refresh settings for user ${uid}:`, error)
      throw error
    }
  },
  // async changeAjaxConfig(uid: string, payload: any, isOrganization?: boolean) {
  //   try {
  //     return firestore
  //       .collection(
  //         isOrganization ? organizationCollectionNamespace : collectionNamespace
  //       )
  //       .doc(uid)
  //       .update({ ajaxData: payload })
  //   } catch (error) {
  //     console.error('Error adding Ajax config ', error)
  //   }
  // },
  // async changeAjaxEnabledStatus(
  //   uid: string,
  //   payload: any,
  //   isOrganization?: boolean
  // ) {
  //   try {
  //     return firestore
  //       .collection(
  //         isOrganization ? organizationCollectionNamespace : collectionNamespace
  //       )
  //       .doc(uid)
  //       .update({ isAjaxEnabled: payload })
  //   } catch (error) {
  //     console.error('Error updating isAjaxEnabled: ', error)
  //   }
  // },
  async getAllCustomers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      userCollection
        .where('role', '==', 'Customer')
        .get()
        .then((result) => {
          let users = []
          result.forEach((doc) => {
            const currentUser = doc.data()
            users.push(currentUser)
          })
          resolve(users)
        })
        .catch((error) => {
          reject(error)
          console.error(error)
        })
    })
  },
  async changeIsWebhookTriggerEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isWebhookTriggerEnabled: payload })
    } catch (error) {
      console.error('Error updating webhook trigger status: ', error)
    }
  },
  async changeWebhookTriggerConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ webhookTriggerData: payload })
    } catch (error) {
      console.error('Error adding webhook trigger config ', error)
    }
  },
  async updateHikCentralNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isHikCentralEnabled: payload })
    } catch (error) {
      console.error('Error updating HikCentral Enabled : ', error)
    }
  },
  async updateHikCentralConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ hikCentralData: payload })
    } catch (error) {
      console.error('Error updating HikCentral Data : ', error)
    }
  },
  async updateHikCentralAcknowledgementEnabledStatus(
    uid: string,
    payload: any
  ) {
    try {
      return firestore.collection(collectionNamespace).doc(uid).update({
        'hikCentralData.isAcknowledgementEnabled': payload
      })
    } catch (error) {
      console.error(
        'Error updating hikCentralData isAcknowledgementEnabled : ',
        error
      )
    }
  },
  async configureHikCentral(payload: any) {
    return functions.httpsCallable('configureHikCentral')(payload)
  },
  async updateGenetecEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isGenetecEnabled: payload })
    } catch (error) {
      console.error('Error updating isGenetecEnabled : ', error)
    }
  },
  async updateGenetecConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ genetecData: payload })
    } catch (error) {
      console.error('Error updating genetecData : ', error)
    }
  },
  async updateGenetecAcknowledgementEnabledStatus(uid: string, payload: any) {
    try {
      return firestore.collection(collectionNamespace).doc(uid).update({
        'genetecData.isAcknowledgementEnabled': payload
      })
    } catch (error) {
      console.error(
        'Error updating genetecData isAcknowledgementEnabled : ',
        error
      )
    }
  },
  async addEvalinkConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ evalinkData: payload })
    } catch (error) {
      console.error('Error adding evalink config ', error)
    }
  },
  async changeIsEvalinkNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isEvalinkEnabled: payload })
    } catch (error) {
      console.error('Error updating Evalink notification status: ', error)
    }
  },
  async changeIsEvalinkDeviceOverrideEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ 'evalinkData.isDeviceOverrideEnabled': payload })
    } catch (error) {
      console.error('Error updating Evalink device override status: ', error)
    }
  },
  async addSIAConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ siaData: payload })
    } catch (error) {
      console.error('Error adding SIA configuration: ', error)
    }
  },
  async changeIsSIANotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isSiaEnabled: payload })
    } catch (error) {
      console.error('Error updating SIA notification status: ', error)
    }
  },
  async changeIsSIAHeartbeatEnabledStatus(
    uid: string,
    isSiaHeartbeatEnabled: boolean
  ) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isSiaHeartbeatEnabled })
    } catch (error) {
      console.error('Error updating isSIAHeartbeatEnabled: ', error)
    }
  },
  async changeIsRelatedSIAAccountNotified(
    uid: string,
    isRelatedSiaAccountNotified: boolean
  ) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isRelatedSiaAccountNotified })
    } catch (error) {
      console.error('Error updating isRelatedSIAAccountNotified: ', error)
    }
  },
  async changeIsSIARoutineMessageEnabledStatus(
    uid: string,
    isSiaRoutineMessageEnabled: boolean
  ) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isSiaRoutineMessageEnabled })
    } catch (error) {
      console.error('Error updating isSiaRoutineMessageEnabled: ', error)
    }
  },
  async changeIsEmailNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isEmailNotificationEnabled: payload })
    } catch (error) {
      console.error('Error updating isEmailNotificationEnabled: ', error)
    }
  },
  async changeNotificationEmails(uid: string, payload: { email: string }[]) {
    try {
      return firestore.collection(collectionNamespace).doc(uid).update({
        notificationEmails: payload
      })
    } catch (error) {
      console.log('Error adding notification emails', error)
    }
  },
  // async updateInitialNotificationEmails(
  //   uid: string,
  //   payload: { email: string }[],
  //   isOrganization?: boolean
  // ) {
  //   try {
  //     if (isOrganization) {
  //       const organizationUserSnapshot = await userCollection
  //         .where('organization', '==', organizationCollection.doc(uid))
  //         .get()
  //       const emails = organizationUserSnapshot.docs.map((item) => ({
  //         email: item.data().email
  //       }))
  //       return organizationCollection.doc(uid).update({
  //         notificationEmails: emails
  //       })
  //     } else {
  //       return userCollection.doc(uid).update({
  //         notificationEmails: payload
  //       })
  //     }
  //   } catch (error) {
  //     console.log('Error updating initial notification emails', error)
  //   }
  // },
  async changeIsFTPNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isFTPNotificationsEnabled: payload })
    } catch (error) {
      console.error('Error updating FTP notification status: ', error)
    }
  },
  async addFTPConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ ftpData: payload })
    } catch (error) {
      console.error('Error adding ftp data: ', error)
    }
  },
  async updateAlarmDefinitionConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ alarmDefinitionConfig: payload })
    } catch (error) {
      console.error('Error adding ftp data: ', error)
    }
  },
  async getUserKeys(userId: string) {
    try {
      const clients = await clientCollection
        .where('users', 'array-contains', userId)
        .get()
      const userKeys = []
      clients.forEach((client) => {
        const keys = client.data()?.keys
        if (keys?.length > 0) {
          userKeys.push(...keys)
        }
      })
      return userKeys
    } catch (error) {
      console.error('Error getting user keys: ', error)
      throw error
    }
  },
  async getUser(userDocId: string) {
    try {
      let user = await userCollection.doc(userDocId).get()
      if (user.exists) {
        return user.data()
      }
    } catch (error) {
      console.error('Error getting user: ', error)
      throw error
    }
  },
  async changeIsSMTPNotificationEnabledStatus(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ isSMTPNotificationEnabled: payload })
    } catch (error) {
      console.error('Error updating FTP notification status: ', error)
    }
  },
  async addSMTPConfig(uid: string, payload: any) {
    try {
      return firestore
        .collection(collectionNamespace)
        .doc(uid)
        .update({ smtpNotificationData: payload })
    } catch (error) {
      console.error('Error adding ftp data: ', error)
    }
  }
}
export async function sendEmailForCloudFunction(data: {
  email: string
  functionName: string
  [key: string]: any
}) {
  const { functionName, ...rest } = data
  const emailFunction = functions.httpsCallable(functionName)
  try {
    await emailFunction({ ...rest, actionCodeSettings })

    return { status: 'success', message: 'email sent' }
  } catch (error) {
    console.error(`Error sending ${functionName} email:`, error)
  }
}
