var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      { active: _vm.isExpanded },
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],attrs:{"data-test-id":"sia-notification-exapand-bar"},on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"sia-notification-exapand-title"}},[_vm._v(" SIA-DC09 ")]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"pl-4 pb-2"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","font-size":"13px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h3',{staticClass:"text-left pt-5 pb-3 my-2 mt-2",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          }),attrs:{"data-test-id":"sia-notification-acc-no-label"}},[_vm._v(" Notify sub folder account number ")])]),_c('div',{staticClass:"mt-2 mr-1"},[_c('v-switch',{staticClass:"notification-switch",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"text":"","color":"secondary","inset":"","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"sia-notification-acc-no-switch"},on:{"change":_vm.onClickIsRelatedAccountNotified},model:{value:(_vm.isRelatedSiaAccountNotified),callback:function ($$v) {_vm.isRelatedSiaAccountNotified=$$v},expression:"isRelatedSiaAccountNotified"}})],1)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","font-size":"13px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h3',{staticClass:"text-left pt-5 pb-3 my-2 mt-2",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          }),attrs:{"data-test-id":"sia-notification-heartbeat-label"}},[_vm._v(" Send SIA heartbeat message ")])]),_c('div',{staticClass:"mt-2 mr-1"},[_c('v-switch',{staticClass:"notification-switch",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"text":"","color":"secondary","inset":"","disabled":!_vm.canHeartbeatEnable || _vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"sia-notification-heartbeat-switch"},on:{"change":_vm.onClickIsHeartbeatEnabled},model:{value:(_vm.isSiaHeartbeatEnabled),callback:function ($$v) {_vm.isSiaHeartbeatEnabled=$$v},expression:"isSiaHeartbeatEnabled"}})],1)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","font-size":"13px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h3',{staticClass:"text-left pt-5 pb-3 my-2 mt-2",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          }),attrs:{"data-test-id":"sia-notification-routine-label"}},[_vm._v(" Send SIA routine message ")])]),_c('div',{staticClass:"mt-2 mr-1"},[_c('v-switch',{staticClass:"notification-switch",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"text":"","color":"secondary","inset":"","disabled":!_vm.canHeartbeatEnable || _vm.isToggleDisabled,"data-test-id":"sia-notification-routine-switch"},on:{"change":_vm.onClickIsRoutineMessageEnabled},model:{value:(_vm.isSiaRoutineMessageEnabled),callback:function ($$v) {_vm.isSiaRoutineMessageEnabled=$$v},expression:"isSiaRoutineMessageEnabled"}})],1)]),_c('div',{staticClass:"pr-3 form"},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"IP Address","outlined":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"error-messages":_vm.siaIpError,"error":_vm.siaIpError.length > 0,"data-test-id":"sia-notification-ip-input"},model:{value:(_vm.siaIp),callback:function ($$v) {_vm.siaIp=$$v},expression:"siaIp"}}),_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Port","outlined":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"error-messages":_vm.siaPortError,"error":_vm.siaPortError.length > 0,"data-test-id":"sia-notification-port-input"},model:{value:(_vm.siaPort),callback:function ($$v) {_vm.siaPort=$$v},expression:"siaPort"}})],1),_c('div',{staticClass:"font-weight-bold text-left notification-title pl-1"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"sia-notification-switch-label"}},[_vm._v(" Turn on/off SIA notifications ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"alarm-sia-notification-switch","text":"","inset":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"sia-notification-switch"},on:{"change":_vm.onClickIsSiaEnabled},model:{value:(_vm.isSiaEnabled),callback:function ($$v) {_vm.isSiaEnabled=$$v},expression:"isSiaEnabled"}})],1)]):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text",attrs:{"text":"","btnText":"Close","data-test-id":"sia-notification-snackbar-close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }