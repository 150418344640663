import { getDefaultState } from './state'

const mutations = {
  setAssessment(state: any, payload: string) {
    state.finalAssessment = payload
  },
  setTodosByCameraId(state: { todosByCameraId }, payload: any): void {
    state.todosByCameraId = payload
  },
  setDownloadUrl(state: { downloadUrl }, payload: any): void {
    state.downloadUrl = payload
  },
  setUploadedTask(state: { uploadedTask }, payload: any): void {
    state.uploadedTask = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  setCurrentTodoCameraRef: (state, payload: any) => {
    state.currentTodoCameraRef = payload
  },
  setLastTodoByCameraRef: (state, payload: any) => {
    state.lastTodoByCameraRef = payload
  },
  setIncidentHcLoadMoreLoading: (state, payload: any) => {
    state.incidentHcLoadMoreLoading = payload
  }
}

export default {
  ...mutations
}
