<template>
  <div
    v-if="isLiveStreamEnabled"
    class="small-card"
    style="height: 30vh; margin-bottom: 80px"
  >
    <nav v-if="!expanded">
      <span>
        <v-icon
          class="pr-lg-2 mx-2"
          :color="online ? 'rgb(255,82,82)' : 'rgb(128, 128, 128)'"
        >
          {{ online ? 'mdi-circle' : 'mdi-circle-outline' }}
        </v-icon>
        <span>Live Preview</span>
      </span>
      <v-icon v-if="expanded" class="pr-lg-2 close-button" @click="close"
        >mdi-arrow-collapse</v-icon
      >
      <v-icon v-else class="pr-lg-2">mdi-arrow-expand</v-icon>
    </nav>
    <div class="video-container">
      <LiveStreamPlayer
        :cameraId="cameraDocId"
        :cameraConfig="getCameraConfig"
        :isSiteHardwareDevice="isSiteHardwareDevice"
        @online="onOnline"
      />
      <template v-if="expanded">
        <div class="top-right-controls">
          <button
            v-if="isPtzCamera"
            class="control-btn toggle-btn"
            :class="{ 'light-mode': !isDarkMode }"
            @click="toggleControls"
          >
            <v-icon>mdi-pan</v-icon>
          </button>
          <button
            class="control-btn fullscreen-btn"
            :class="{ 'light-mode': !isDarkMode }"
            @click="toggleFullscreen"
          >
            <v-icon>{{
              expanded ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
            }}</v-icon>
          </button>
        </div>
        <div v-if="showControls" class="bottom-right-controls">
          <div class="control-row">
            <button
              class="control-btn zoom-out-btn"
              :class="{ 'light-mode': !isDarkMode }"
              @click="ptzControl('zoom_out')"
            >
              <v-icon>mdi-magnify-minus</v-icon>
            </button>
            <button
              class="control-btn zoom-in-btn"
              :class="{ 'light-mode': !isDarkMode }"
              @click="ptzControl('zoom_in')"
            >
              <v-icon>mdi-magnify-plus</v-icon>
            </button>
            <button
              class="control-arrow-btn left-btn"
              :class="{ 'light-mode': !isDarkMode }"
              @click="ptzControl('left')"
            >
              <v-icon>mdi-arrow-left-bold</v-icon>
            </button>
            <div class="vertical-controls">
              <button
                class="control-arrow-btn up-btn"
                :class="{ 'light-mode': !isDarkMode }"
                @click="ptzControl('up')"
              >
                <v-icon>mdi-arrow-up-bold</v-icon>
              </button>
              <button
                class="control-arrow-btn down-btn"
                :class="{ 'light-mode': !isDarkMode }"
                @click="ptzControl('down')"
              >
                <v-icon>mdi-arrow-down-bold</v-icon>
              </button>
            </div>
            <button
              class="control-arrow-btn right-btn"
              :class="{ 'light-mode': !isDarkMode }"
              @click="ptzControl('right')"
            >
              <v-icon>mdi-arrow-right-bold</v-icon>
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div v-else class="small-card" style="margin-bottom: 30px">
    <nav>
      <span>
        <span>There is No Live Preview Available</span>
      </span>
    </nav>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import LiveStreamPlayer from '../camera/player/LiveStreamPlayer.vue'

@Component({
  components: {
    LiveStreamPlayer
  }
})
export default class LiveView extends Vue {
  @Prop({ required: true }) isLiveStreamEnabled?: boolean
  @Prop({ required: false, default: false }) isSiteHardwareDevice?: boolean
  @Prop({ required: true }) liveStream?: any
  @Prop({ required: true }) cameraId: string
  @Prop({ required: true }) expanded!: boolean
  @Prop({ required: true }) cameraDocId: string
  @Prop({ required: true }) isDarkMode: boolean
  @Prop({ required: true }) isPtzCamera: boolean

  public online: boolean = true
  public showControls: boolean = false

  public get getCameraConfig() {
    return {
      liveStream: this.liveStream,
      cameraId: this.cameraId,
      id: this.cameraDocId
    }
  }

  // listen for event from child component : online
  public onOnline(online: boolean) {
    this.online = online
  }

  public close() {
    this.$emit('close')
  }

  public ptzControl(action: string) {
    this.$emit('onPtzClick', action)
  }

  public toggleFullscreen() {
    this.close()
  }

  public toggleControls() {
    this.showControls = !this.showControls
  }
}
</script>

<style scoped>
.small-card {
  display: flex;
  flex-direction: column;
}

.small-card nav {
  display: flex;
  justify-content: space-between;
  color: black;
  font-weight: bold;
  background-color: rgb(171, 171, 171);
  font-size: 14px;
  padding: 8px;
  top: 0;
  width: 100%;
}

.close-button {
  cursor: pointer;
}
/* PTZ Controls */
.video-container {
  flex-grow: 1;
}

.top-right-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.bottom-right-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.control-row {
  display: flex;
  gap: 5px;
  align-items: end;
}

.vertical-controls {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.control-btn {
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.control-arrow-btn {
  width: 50px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #333333;
  border-color: #6f6c61;
}

.control-btn .v-icon,
.control-arrow-btn .v-icon {
  color: #ffffff;
}

.zoom-out-btn,
.zoom-in-btn,
.toggle-btn,
.fullscreen-btn {
  background-color: #333333;
  border-color: #6f6c61;
}

.zoom-in-btn {
  margin-right: 30px;
}

.control-btn.light-mode,
.control-arrow-btn.light-mode {
  background-color: #ffffff;
  border-color: #e5e5e5;
}

.control-btn.light-mode .v-icon,
.control-arrow-btn.light-mode .v-icon {
  color: #333333;
}

/* Active state */
.control-btn:active:not(.light-mode),
.control-arrow-btn:active:not(.light-mode) {
  background-color: #514f4b !important;
  border-color: #ffd42a !important;
}

.control-btn.light-mode:active,
.control-arrow-btn.light-mode:active {
  background-color: #fff9e3 !important;
  border-color: #ffd42a !important;
}
</style>
