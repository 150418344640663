<template>
  <div
    :class="[
      isUserAdmin && isDarkModeToggleEnabled ? 'admin-scroll-bar' : '',
      showHamBurgerMenu ? 'mobile-z-index' : ''
    ]"
  >
    <div
      ref="resizeTooltip"
      class="resize-tooltip"
      v-show="isTooltipVisible && !isMobile"
    >
      Drag to resize
    </div>
    <!-- navigation backdrop -->
    <v-overlay
      class="navigation-backdrop"
      v-if="!isToggleMenuEnabled"
      :absolute="true"
      v-on:click.stop
      @click="onClickBackDrop"
    >
    </v-overlay>
    <v-navigation-drawer
      v-if="(isUserAdmin || isNavigationVisible()) && !isToggleMenuEnabled"
      app
      :class="drawerStyle"
      :permanent="true"
      fixed
      :mini-variant.sync="isToggleMenuEnabled"
      :style="{
        backgroundColor: getBackgroundColor()
      }"
      v-bind:width="!isMobile ? this.navigation.width : undefined"
      data-test-id="navigation-drawer"
      ref="drawer"
    >
      <div dense class="flex-full-width">
        <v-list class="topAlign--nav--tree">
          <div class="navigation-header">
            <Header
              :route="headerRoute"
              :minified="true"
              :showHamBurgerMenu="false"
              v-if="showHamBurgerMenu"
            />
          </div>
          <div class="tree-container">
            <AccountAvatar v-if="!showHamBurgerMenu" />
            <v-text-field
              :disabled="hasTemporaryDisabled"
              append-icon="mdi-magnify"
              append-outer-icon="mdi-refresh"
              @click:append-outer="refreshTreeView"
              hide-details
              label="Search"
              dense
              outlined
              v-model="search"
              class="search-input"
              :style="{
                'margin-top': '18px'
              }"
              :loading="isTreeLoading ? '#ffd42a' : false"
              :background-color="
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.darkSectionColor
                  : getColors.lightPrimaryColor
              "
              :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              data-test-id="treeview-search-text-field"
            >
            </v-text-field>
          </div>
          <v-treeview
            ref="treeview"
            :class="isUserAdmin ? 'admin-node' : ''"
            :items.sync="nodes"
            @update:active="openTreeNode"
            :active.sync="selectedTreeNode"
            :open.sync="openedTreeNode"
            item-key="key"
            children-key="key"
            :activatable="isNodeActive"
            dense
            return-object
            hoverable
            :color="
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkStrokeColor
                : ''
            "
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.lightTreeItemsColor
            }"
            data-test-id="treeview"
          >
            <template v-slot:prepend="{ item }">
              <div>
                <Avatar
                  v-if="item.type === 'customer' && item.nodeType !== 'load'"
                  :id="item.id"
                  style="padding-top: 6px; padding-left: 24px"
                  :size="20"
                ></Avatar>
                <span
                  class="custom-icon"
                  v-if="
                    !['add', 'load', 'customer'].includes(item.nodeType) &&
                    isEdgeAndInactiveCamera(item)
                  "
                  :title="getNodeStatusTooltip(item)"
                  :style="{
                    marginRight: item.type === 'site' ? '-10px' : 'auto' // Temporary fix for the icon alignment MR: 2158
                  }"
                >
                  <NodeInactiveSVG
                    :armedStatus="getNodeStatus(item)"
                    nodeType="camera"
                    :isDarkModeEnabled="isDarkModeToggleEnabled"
                  />
                </span>
                <span
                  class="custom-icon"
                  v-if="
                    !['add', 'load', 'customer'].includes(item.nodeType) &&
                    isInactiveSite(item)
                  "
                  :title="getNodeStatusTooltip(item)"
                  :style="{
                    marginRight: item.type === 'site' ? '-10px' : 'auto' // Temporary fix for the icon alignment MR: 2158
                  }"
                >
                  <NodeInactiveSVG
                    :armedStatus="getNodeStatus(item)"
                    nodeType="site"
                    :isDarkModeEnabled="isDarkModeToggleEnabled"
                  />
                </span>
                <v-badge
                  v-if="
                    !['add', 'load', 'customer'].includes(item.nodeType) &&
                    item.type !== 'customer' &&
                    !isEdgeAndInactiveCamera(item) &&
                    !isInactiveSite(item)
                  "
                  bottom
                  overlap
                  dot
                  :color="getBadgeColor(item)"
                  :title="getNodeStatusTooltip(item)"
                  data-test-id="treeview-badge"
                >
                  <v-icon
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkTreeItemsColor
                    }"
                    v-if="item.type === 'client'"
                    data-test-id="treeview-client-icon"
                  >
                    mdi-human
                  </v-icon>
                  <v-icon
                    :style="siteStyle(item)"
                    v-if="item.type === 'site'"
                    data-test-id="treeview-site-icon"
                  >
                    mdi-map-marker
                  </v-icon>
                  <v-icon
                    :style="cameraStyle(item)"
                    v-if="item.type === 'camera'"
                    data-test-id="treeview-camera-icon"
                  >
                    mdi-camera
                  </v-icon>
                </v-badge>
                <v-icon
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.lightTreeItemsColor
                  }"
                  v-if="item.nodeType === 'add'"
                  data-test-id="treeview-add-icon"
                >
                  mdi-plus
                </v-icon>
              </div>
            </template>
            <template v-slot:label="{ item }">
              <div
                class="treeview-label"
                @mouseenter="hoveredNode = item"
                @mouseleave="hoveredNode = null"
                @click="setActiveNode(item)"
              >
                <v-tooltip right v-if="item.nodeType !== 'load'">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      @click="setActiveNode(item)"
                      class="treeview-label-text"
                    >
                      {{ item.companyName ? item.companyName + ' - ' : '' }}
                      {{ item.name }}
                    </span>
                  </template>
                  <span>
                    {{ item.companyName ? item.companyName + ' - ' : '' }}
                    {{ item.name }}
                  </span>
                </v-tooltip>

                <img
                  v-if="isDeleteIconVisible(item)"
                  :src="getDeleteIcon(item)"
                  @click.stop="onDeleteNode(item)"
                  @mouseover="onHoverIcon(item, true)"
                  @mouseleave="onHoverIcon(item, false)"
                  class="delete-icon"
                  :class="{
                    'icon-active': item === activeTreeNode
                  }"
                />
              </div>
              <div class="tree-leaf">
                <div
                  v-if="isTreeLoading && item.nodeType === 'load'"
                  style="display: flex; justify-content: center"
                >
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                </div>
                <div v-if="!isTreeLoading">
                  <div
                    v-if="item.nodeType === 'load'"
                    @click="
                      $event.stopPropagation()
                      fetchNodes(item)
                    "
                    :title="`See more ${item.type}s`"
                    class="lower-container"
                  >
                    <div
                      v-if="isUserAdmin && item.type === 'customer'"
                      :style="{
                        color:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? getColors.lightPrimaryColor
                            : getColors.lightTreeItemsColor
                      }"
                    >
                      <div class="see-more-btn elevation-0 text-subtitle-2">
                        <v-icon style="color: rgb(0, 0, 0)">
                          mdi-arrow-down
                        </v-icon>
                        <span style="color: rgb(0, 0, 0)">More</span>
                      </div>
                    </div>
                    <div
                      v-else
                      :class="{
                        'see-more-btn-customer ':
                          !isUserAdmin && item.type === 'client'
                      }"
                    >
                      <v-icon
                        :style="{
                          color:
                            !isUserAdmin && item.type === 'client'
                              ? 'rgb(0, 0, 0)'
                              : isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.lightTreeItemsColor
                        }"
                      >
                        mdi-arrow-down
                      </v-icon>

                      <span
                        :style="{
                          color:
                            !isUserAdmin && item.type === 'client'
                              ? 'rgb(0, 0, 0)'
                              : isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.lightTreeItemsColor
                        }"
                      >
                        {{
                          item.type === 'client' ||
                          item.type === 'site' ||
                          item.type === 'camera'
                            ? 'More'
                            : isUserAdmin
                            ? 'More'
                            : 'Load more'
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-treeview>
          <v-dialog v-model="deleteDialog" width="500" class="custom-dialog">
            <v-card
              style="padding: 25px"
              :style="{ backgroundColor: getBackgroundColor() }"
            >
              <v-toolbar class="secondary elevation-0" dark>
                <v-toolbar-title style="color: black; text-align: center"
                  >Confirm Deletion</v-toolbar-title
                >
              </v-toolbar>
              <div v-if="activeTreeNode" class="pt-5">
                <div v-if="activeTreeNode.type === 'customer'">
                  <p
                    class="mb-5"
                    :style="{ color: buttonColor, textAlign: 'center' }"
                  >
                    Are you sure you want to delete the selected user ?
                    <br />
                    This action cannot be undone.
                  </p>
                  <div
                    class="mb-5"
                    :style="{ color: buttonColor, textAlign: 'center' }"
                  >
                    <div>{{ activeTreeNode.email }}</div>
                    <div>{{ activeTreeNode.name }}</div>
                  </div>
                  <label
                    class="mb-5"
                    :style="{ color: buttonColor, textAlign: 'center' }"
                    >Please type "DELETE" to confirm the action.</label
                  >
                </div>
                <div v-else>
                  <p
                    class="mb-5"
                    :style="{ color: buttonColor, textAlign: 'center' }"
                  >
                    Are you sure you want to delete this
                    {{ activeTreeNode.type + ' ' + activeTreeNode.name }} ?
                  </p>
                  <label
                    class="mb-5"
                    :style="{ color: buttonColor, textAlign: 'center' }"
                    >Please type "DELETE" to confirm the action.</label
                  >
                </div>
                <v-text-field
                  type="text"
                  dense
                  outlined
                  v-model="deleteInput"
                  @input="checkDeleteInput"
                  :background-color="
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkSectionColor
                      : getColors.lightPrimaryColor
                  "
                  :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
                  :placeholder="'Type here'"
                  :style="{
                    margin: '20px 0px 0px 0px',
                    width: '100%',
                    color: buttonColor
                  }"
                ></v-text-field>
                <v-card-actions
                  v-if="!isDeleting"
                  class="d-flex justify-space-between mt-5"
                >
                  <Button
                    @onButtonClick="onDeleteClose"
                    text
                    :data-ci-new-user-close-button="true"
                    btnText="Close"
                    btnStyle="outlined"
                    :class="[
                      'text-subtitle-2',
                      'close-btn',
                      {
                        'close-btn-dark': isDarkModeToggleEnabled && isUserAdmin
                      }
                    ]"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : ''
                    }"
                  />
                  <Button
                    @onButtonClick="onDeleteConfirm"
                    :style="{
                      color: deleteInputMatch
                        ? 'white !important'
                        : 'rgba(255, 255, 255, 0.5) !important',
                      backgroundColor: '#FF4444 !important'
                    }"
                    color="error"
                    :data-ci-new-user-save-button="true"
                    class="elevation-0 text-subtitle-2"
                    btnText="Delete"
                    btnStyle="delete"
                    :disabled="!deleteInputMatch"
                  />
                </v-card-actions>

                <v-progress-circular
                  v-if="isDeleting"
                  indeterminate
                  :style="{ color: buttonColor }"
                ></v-progress-circular>
              </div>
            </v-card>
          </v-dialog>
        </v-list>
        <NavigationAccountMenu v-if="showHamBurgerMenu" />
      </div>
    </v-navigation-drawer>
    <AddNewNode
      :nodeType="nodeType"
      :active="addNewNode"
      :userId="activeTreeNode?.userFirestoreId"
      :clientId="activeTreeNode?.clientId"
      :siteId="activeTreeNode?.siteId"
      v-on:closeDialog="addNewNode = false"
    />
  </div>
</template>

<script lang="ts">
const namespaceUser = { namespace: 'user' }
const namespaceWorker = { namespace: 'worker' }
const namespaceConfig = { namespace: 'config' }
const namespaceClient = { namespace: 'client' }
const namespaceNodes = { namespace: 'nodes' }
const namespaceSnapshot = { namespace: 'snapshot' }

import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import debounce from 'lodash.debounce'
import Button from '@/components/app/Button.vue'
import AccountAvatar from '@/components/app/AccountAvatar.vue'
import firebase from 'firebase/compat/app'
import Avatar from '@/components/user/Avatar.vue'
import EventBus from '@/utils/EventBus'
import SnackBarError from '../SnackBarError'
import AddNewNode from '@/components/configuration/AddNewNode.vue'
import Header from '@/components/app/Header.vue'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'
import NavigationMenu from '@/components/app/NavigationMenu.vue'
import NavigationAccountMenu from '@/components/app/NavigationAccountMenu.vue'
import NodeInactiveSVG from '@/components/svg-icons/NodeInactiveSVG.vue'
import {
  MonitoringStatus,
  NodeStatus,
  PromiseQubeNodeStatus
} from '@/utils/NodeStatus/NodeStatus'
import { P2pPlayer, P2pPlayerConfig } from '@/utils/Interfaces'
import {
  CreateNodeMetaData,
  CreateNodeMetaDataStatus,
  ListnerParams,
  PaginatedNodeFetchParams,
  BaseNodeFetchParams
} from '../../store/modules/nodes/definitions'

enum NodeTypes {
  Client = 'client',
  Site = 'site',
  Camera = 'camera'
}

interface BaseItem {
  type: NodeTypes
  isActive: boolean
  isHardwareDevice?: boolean
  isNodeArmed?: boolean
  isEdgeDeviceEnabled?: boolean
}

interface CameraItem extends BaseItem {
  type: NodeTypes.Camera
  isEdgeDeviceEnabled?: boolean
  state?: 'off' | string
  isAuthenticated?: boolean
  isEdgeCameraUnarmed?: boolean
}

interface SiteItem extends BaseItem {
  type: NodeTypes.Site
  isHardwareDevice: boolean
}
interface BoostNodeId {
  boostUserId?: string
  boostClientId?: string
  boostSiteId?: string
  boostCameraId?: string
}

// General interface that can represent any type of item
type Item = CameraItem | SiteItem | (BaseItem & { type: NodeTypes.Client })

@Component({
  components: {
    Avatar,
    Button,
    AccountAvatar,
    AddNewNode,
    Header,
    NavigationMenu,
    NavigationAccountMenu,
    NodeInactiveSVG
  }
})
export default class Navigation extends Vue {
  // @Prop({ required: true }) navWidth!: number
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getRouteSnapshot', namespaceSnapshot) public routeSnapshot
  @Getter('getHasUnsavedChanges', namespaceConfig) public getHasUnsavedChanges
  @Mutation('setHasUnsavedChanges', namespaceConfig)
  public setHasUnsavedChanges: (value: boolean) => void

  @Mutation('setBreadcrumbList', namespaceConfig)
  public setBreadcrumbList: any
  @Mutation('setClientType', namespaceConfig)
  public setClientType: any
  @Mutation('setSelectedTreeNode', namespaceConfig)
  public setSelectedTreeNode: any
  @Mutation('setRoute', namespaceSnapshot) public setRouteSnap: any
  @Mutation('setSelectedUser', namespaceUser) public setSelectedUser!: any
  @Mutation('setIsNotFound', namespaceConfig) public setIsNotFound!: any
  @Mutation('setCreateNodeStatus', namespaceNodes)
  public setCreateNodeStatus: (data?: CreateNodeMetaData) => void
  @Mutation('setIsToggleMenuEnabled', namespaceConfig)
  public setIsToggleMenuEnabled: (value: boolean) => void

  @Getter('clientConfig', namespaceClient) public clientConfig: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getIsToggleMenuEnabled', namespaceConfig)
  public isToggleMenuEnabled: boolean
  @Getter('getIsTreeViewLoading', namespaceConfig)
  public getIsTreeViewLoading: boolean

  @Getter('getCreateNodeStatus', namespaceNodes)
  public createNodeStatus: CreateNodeMetaData

  @Getter('getNodes', namespaceNodes)
  public nodes

  @Action('deleteUser', namespaceUser)
  private deleteUser: (payload: {
    userIds: string[]
    clientId?: string
    siteId?: string
    cameraId?: string
  }) => Promise<any>

  @Action('deleteTreeNode', namespaceConfig)
  private deleteTreeNode: (payload: {
    userIds: string[]
    clientId?: string
    siteId?: string
    cameraId?: string
  }) => Promise<any>

  @Action('loadBreadcrumb', namespaceConfig) public loadBreadcrumb
  @Action('setWorkerReady', namespaceWorker) public setWorkerReady

  @Action('toggleDarkMode', namespaceConfig)
  public toggleDarkMode: any

  @Action('fetchCompleteTreeViewNodes', namespaceNodes)
  private fetchCompleteTreeViewNodes: (payload: BaseNodeFetchParams) => void
  @Action('fetchTreeViewSiteAndCameraNodes', namespaceNodes)
  private fetchTreeViewSiteAndCameraNodes: (
    payload: PaginatedNodeFetchParams
  ) => void
  @Action('listenTofirestoreNodeChanges', namespaceNodes)
  private listenTofirestoreNodeChanges: (params: ListnerParams) => void
  @Action('unsubscribeNodes', namespaceNodes)
  private unsubscribeNodes: () => void

  @Action('getPromiseQubeVersion', namespaceConfig)
  private getPromiseQubeVersion: () => Promise<string>
  @Action('getP2PPlayer', namespaceConfig)
  private getP2PPlayer: () => Promise<P2pPlayerConfig>

  public isMobile = false
  public isTooltipVisible = false
  public navigation: {
    shown: boolean
    width: number
    borderSize: number
  } = {
    shown: false,
    width: 0.2 * window.innerWidth, // initial width for any screen
    borderSize: 3
  }
  public SIZE = 10
  public isTreeLoading: boolean = true
  public hoveredNode = null
  public hoveredItem = null
  public search: string = ''

  public isSandboxEnabled: boolean = true
  public userRef: firebase.firestore.DocumentReference

  public activeTreeNode = null
  public selectedTreeNode = []
  public openedTreeNode = []
  public isNodeActive = true

  public deleteDialog: boolean = false
  public isDeleting: boolean = false
  public isWriteEnabled: boolean = false
  public isLoadingDeleteButton = false
  public deleteInput = ''
  public deleteInputMatch = false

  public lastSelectedNode = null
  public isCameraActive = false

  public nodeType: NodeTypes = NodeTypes.Client
  public addNewNode = false
  private isCustomerChanged = false

  public initialLoad = true
  public boostSecondaryId = false
  public initialLoadUserId
  public initialLoadClientId
  public initialLoadSiteId
  public initialLoadCameraId
  public userId = ''
  public clientId = ''
  public siteId = ''
  public cameraId = ''

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }
  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }
  private isLoadMoreVisible = {
    customers: true
  }
  public get buttonColor() {
    return this.isUserAdmin && this.isDarkModeToggleEnabled
      ? this.getColors.lightPrimaryColor
      : this.getColors.lightTreeItemsColor
  }

  public get headerRoute() {
    return this.$route.path.split('/')[1]
  }

  public get hasTemporaryDisabled() {
    // return true if only in configuration page and there is no clientID
    return this.$route.name === 'Configurations' && !this.$route.params.clientId
  }

  private get combinedRoute() {
    return {
      route: this.$route,
      params: this.$route.params
    }
  }
  public get drawerStyle() {
    return this.isMobile ? 'nav-drawer' : ''
  }

  public async handleBreadcurmbsOnTreeUpdate() {
    if (
      this.createNodeStatus?.status ===
        CreateNodeMetaDataStatus.ADDED_WAITING &&
      this.createNodeStatus?.node
    ) {
      const initialSelection = this.getInitialSelection()
      if (initialSelection) {
        this.openedTreeNode = initialSelection.openedNodes
        this.selectedTreeNode = [this.createNodeStatus?.node]
        this.setCreateNodeStatus(undefined)
      }
    }
  }

  public setBorderWidth() {
    const drawerComponent = this.$refs.drawer as any
    const borderElement = drawerComponent.$el.querySelector(
      '.v-navigation-drawer__border'
    ) as HTMLElement
    const tooltipElement = this.$refs.resizeTooltip as HTMLElement

    // Set initial styles for the border
    borderElement.style.width = `${this.navigation.borderSize}px`
    borderElement.style.backgroundColor = 'grey'
    borderElement.style.cursor = 'initial'
    borderElement.style.transition = 'all 0.3s ease'

    // Add mouseenter event listener to increase the border width on hover
    document.addEventListener('mousemove', (e: MouseEvent) => {
      // Get the bounding rectangle of the navigation drawer border
      const rect = borderElement.getBoundingClientRect()
      const rightEdge = rect.right
      // Check if the mouse is within 7 pixels to the right of the border
      if (e.clientX > rightEdge && e.clientX <= rightEdge + 7) {
        this.isTooltipVisible = true // Show tooltip
        tooltipElement.classList.add('show')

        tooltipElement.style.left = `${e.clientX + 15}px`
        tooltipElement.style.top = `${e.clientY + 10}px`
        borderElement.style.width = `${this.navigation.borderSize + 5}px`
        document.body.style.cursor = 'ew-resize'
      } else {
        //reset
        borderElement.style.width = `${this.navigation.borderSize}px`
        document.body.style.cursor = 'initial'
        this.isTooltipVisible = false
      }
    })
  }

  public setEvents() {
    const drawerComponent = this.$refs.drawer as any
    const el = drawerComponent.$el as HTMLElement
    const drawerBorder = el.querySelector(
      '.v-navigation-drawer__border'
    ) as HTMLElement
    const minSize = this.navigation.borderSize + 7
    // initil width of the navigation-drawer
    const initialWidth = this.navigation.width

    const resize = (e) => {
      document.body.style.cursor = 'ew-resize'
      let newWidth = e.clientX

      // Calculate the width between minWidth and maxWidth
      const minWidth = initialWidth
      const maxWidth = initialWidth * 2
      newWidth = Math.max(minWidth, Math.min(newWidth, maxWidth))
      el.style.width = `${newWidth}px`
      this.isTooltipVisible = false
    }

    document.addEventListener(
      'mousedown',
      (e) => {
        const rect = drawerBorder.getBoundingClientRect()
        const rightEdge = rect.right
        // Check if the mouse is within 7 pixels to the right of the border
        if (e.clientX > rightEdge && e.clientX <= rightEdge + 7) {
          // Allow resizing
          el.style.transition = '' // if this set to initial then it will be more smooth but jitter when shrinking
          document.addEventListener('mousemove', resize, false)
        }
      },
      false
    )

    document.addEventListener(
      'mouseup',
      () => {
        el.style.transition = ''
        this.navigation.width = parseInt(el.style.width, 10)
        document.body.style.cursor = ''
        document.removeEventListener('mousemove', resize, false)
      },
      false
    )
  }

  public mounted() {
    this.setBorderWidth()
    this.setEvents()
    this.isMobile = window.innerWidth < 1366
    this.isTreeLoading = true
    this.validateGlobalNode()
    this.getPromiseQubeVersion()
    this.getP2PPlayer()
    this.watchRouteParams()

    if (this.showHamBurgerMenu) {
      this.setIsToggleMenuEnabled(true)
    }
    // EventBus.$on('tree-update', this.handleBreadcurmbsOnTreeUpdate)
  }
  public beforeDestroy() {
    EventBus.$off('tree-update', this.getBreadcrumb)
    this.unsubscribeNodes()
  }
  public getRouteParam(param?: string) {
    if (param && param !== 'undefined' && param?.trim() !== '') {
      return param
    }
  }
  public checkUrlParamValidity() {
    if (this.cameraId) {
      return this.siteId && this.clientId && this.userId
    }
    if (this.siteId) {
      return this.userId && this.clientId
    }
    if (this.clientId) {
      return this.userId
    }
    if (this.isUserAdmin) {
      return this.userId
    }
    return false
  }
  @Watch('initialLoad')
  public watchInitialLoad() {
    if (!this.initialLoad && this.currentUser?.id && this.isUserCustomer) {
      const initialTimestamp = firebase.firestore.Timestamp.fromDate(new Date())

      this.listenTofirestoreNodeChanges({
        isAdmin: this.isUserAdmin,
        isUserCustomer: this.isUserCustomer,
        userId: this.currentUser?.id,
        initialTimestamp
      })
    }
  }

  @Watch('combinedRoute')
  public async watchRouteParams() {
    if (this.combinedRoute.params?.userId && this.isUserAdmin) {
      this.isCustomerChanged = this.userId !== this.combinedRoute.params?.userId
    }
    this.userId = this.getRouteParam(this.combinedRoute.params?.userId)
    this.clientId = this.getRouteParam(this.combinedRoute.params?.clientId)
    this.siteId = this.getRouteParam(this.combinedRoute.params?.siteId)
    this.cameraId = this.getRouteParam(this.combinedRoute.params?.cameraId)

    if (this.initialLoad && this.isNotNewRoute) {
      if (this.checkUrlParamValidity()) {
        this.setBoostNodesFromRoute()
      } else {
        this.setBoostNodesFromSnapshot()
      }
      await this.loadTreeView()
    }

    // auto-update tree when node is absent
    if (
      this.isNotNewRoute &&
      this.nodes &&
      !this.isNodesIncludesCurrentRoute()
    ) {
      if (this.checkUrlParamValidity()) {
        this.setBoostNodesFromRoute()
      } else {
        this.setBoostNodesFromSnapshot()
      }
      await this.loadTreeView()
    }

    if (this.isNotNewRoute) {
      this.initialLoad = false
      await this.setInitialSelection()
      await this.handleInitialRedirect()

      this.setNodeWritePermission()
    }
  }
  @Watch('createNodeStatus')
  public handleNewNode() {
    if (
      this.createNodeStatus?.status === CreateNodeMetaDataStatus.ADDED_WAITING
    ) {
      this.handleBreadcurmbsOnTreeUpdate()
    }
  }
  private isNotNewRoute() {
    return (
      this.clientId !== 'new' &&
      this.siteId !== 'new' &&
      this.cameraId !== 'new'
    )
  }

  private isNodesIncludesCurrentRoute() {
    const loadedNodes = this.nodes ?? []

    if (
      this.createNodeStatus?.status === CreateNodeMetaDataStatus.ADDED_WAITING
    ) {
      return true
    }
    if (!this.userId) return true
    const userNode = this.isUserAdmin
      ? loadedNodes.find((node) => node.id === this.userId)
      : undefined
    if (!userNode && this.isUserAdmin) return false

    if (!this.clientId) return true
    const clientNodes = this.isUserAdmin ? userNode.children ?? [] : loadedNodes
    const clientNode = clientNodes.find(
      (node) => node.clientId === this.clientId
    )
    if (!clientNode) return false

    if (!this.siteId) return true
    const siteNodes = clientNode.children ?? []
    const siteNode = siteNodes.find((node) => node.siteId === this.siteId)
    if (!siteNode) return false

    if (!this.cameraId) return true
    const cameraNodes = siteNode.children ?? []
    return cameraNodes.some((node) => node.cameraId === this.cameraId)
  }

  private setBoostNodesFromRoute() {
    this.initialLoadClientId = this.clientId
    this.initialLoadSiteId = this.siteId
    this.initialLoadCameraId = this.cameraId
    this.initialLoadUserId = this.userId
    this.boostSecondaryId = true
  }
  private reSetBoostNodes() {
    this.initialLoadUserId = undefined
    this.initialLoadClientId = undefined
    this.initialLoadSiteId = undefined
    this.initialLoadCameraId = undefined
  }

  private setBoostNodesFromSnapshot() {
    if (
      this.routeSnapshot &&
      this.routeSnapshot.route !== '' &&
      this.routeSnapshot.user === this.currentUser?.id
    ) {
      const savedRouteSnapshotNode = this.routeSnapshot?.nodeRef
      if (savedRouteSnapshotNode?.type === NodeTypes.Client) {
        this.initialLoadClientId = savedRouteSnapshotNode?.id
      } else if (savedRouteSnapshotNode?.type === NodeTypes.Site) {
        this.initialLoadClientId = savedRouteSnapshotNode?.parentId
        this.initialLoadSiteId = savedRouteSnapshotNode?.id
      } else if (savedRouteSnapshotNode?.type === NodeTypes.Camera) {
        this.initialLoadClientId = savedRouteSnapshotNode?.grandParentId
        this.initialLoadSiteId = savedRouteSnapshotNode?.parentId
        this.initialLoadCameraId = savedRouteSnapshotNode?.id
      }
      if (
        savedRouteSnapshotNode?.userId &&
        (this.initialLoadClientId ||
          (savedRouteSnapshotNode?.userId && this.isUserAdmin))
      ) {
        this.initialLoadUserId = savedRouteSnapshotNode?.userId
        this.boostSecondaryId = false
      }
    }
  }

  @Watch('search')
  public async searchTreeNodes() {
    this.reSetBoostNodes()
    this.debouncedSearchTreeNodes()
  }
  //TODO(nodes): recheck logic
  @Watch('selectedTreeNode')
  public watchSelectedTreeNode() {
    if (this.selectedTreeNode && this.selectedTreeNode[0]?.id) {
      const selectedNode = this.selectedTreeNode[0]
      if (
        !selectedNode?.id.includes('load') ||
        !selectedNode?.id.includes('add')
      ) {
        let parentId
        let grandParentId

        if (selectedNode.type === NodeTypes.Site) {
          parentId = selectedNode?.clientFirestoreId
        }
        if (selectedNode.type === NodeTypes.Camera) {
          parentId = selectedNode?.siteFirestoreId
          grandParentId = selectedNode?.clientFirestoreId
        }
        if (!this.$route.path?.includes('undefined')) {
          this.setRouteSnap({
            user: this.currentUser?.id,
            route: this.$route.path,
            nodeRef: {
              id: selectedNode.id,
              type: selectedNode.type,
              parentId,
              grandParentId,
              userId: selectedNode.userFirestoreId
            }
          })
        }
      }

      if (selectedNode?.type === 'customer') {
        const userId = this.selectedTreeNode[0].id
        this.setSelectedUser(userId)
      } else {
        this.setNodeWritePermission()
      }
    } else {
      this.setSelectedUser(null)
      this.setRouteSnap(null)
      return
    }
  }
  public async openTreeNode(node: any) {
    const unsavedConfigs = this.getHasUnsavedChanges
    if (unsavedConfigs) {
      const treeviewElement = this.$refs.treeview as any
      const currActiveNode = treeviewElement.$el.querySelector(
        '.v-treeview-node--active'
      )
      const answer = window.confirm('Do you want to exit without saving?')
      if (!answer) {
        const allNodes = treeviewElement.$el.querySelectorAll(
          '.v-treeview-node__root'
        )
        allNodes.forEach((node) => {
          // set a time out to prevent the override from vue
          setTimeout(() => {
            node.classList.remove('v-treeview-node--active')
          }, 0)
        })
        setTimeout(() => {
          currActiveNode.classList.add('v-treeview-node--active')
        }, 0)
        return
      } else {
        setTimeout(() => {
          currActiveNode.classList.remove('v-treeview-node--active')
        }, 0)
      }

      this.setHasUnsavedChanges(false)
    }
    if (node[0]?.nodeType === 'load') {
      return
    }
    this.activeTreeNode = node[0]
    this.lastSelectedNode = this.activeTreeNode
      ? this.activeTreeNode
      : this.lastSelectedNode
    this.selectedTreeNode = node
    let pathName: string = `/${window.location.pathname.split('/')[1]}`
    //allowed paths
    const allowedPaths: string[] = [
      '/sandbox',
      '/alarms',
      '/live',
      '/maps',
      '/statistics',
      '/configurations',
      '/user-management',
      '/workerspace'
    ]

    //if pathname is not in the list of allowed paths, redirect to alarms
    if (!allowedPaths.includes(pathName)) {
      pathName = '/alarms'
    }
    if (this.activeTreeNode) {
      if (this.activeTreeNode.nodeType === 'add') {
        switch (this.activeTreeNode.name) {
          case 'Add Client':
            this.nodeType = NodeTypes.Client
            break
          case 'Add Site':
            this.nodeType = NodeTypes.Site
            break
          case 'Add Camera':
            this.nodeType = NodeTypes.Camera
            break
        }
        this.addNewNode = true
      } else {
        let routerPath: string
        const activeNodeUserId = this.activeTreeNode.userFirestoreId
        const basicPath = `${pathName}/${activeNodeUserId}`
        if (this.activeTreeNode.type === 'customer') {
          if (pathName === '/user-management') {
            const defaultClient = this.getDefaultClient(activeNodeUserId)
            routerPath = `${basicPath}/${defaultClient?.clientId}`
            this.setClientType('client')
          } else {
            routerPath = `${basicPath}`
            this.setClientType('customer')
          }
        }
        if (this.activeTreeNode.type === 'client') {
          routerPath = `${basicPath}/${this.activeTreeNode.clientId}`
          this.setClientType('client')
        }
        if (this.activeTreeNode.type === 'site') {
          routerPath = `${basicPath}/${this.activeTreeNode.clientId}/${this.activeTreeNode.siteId}`
          this.setClientType('site')
        }
        if (this.activeTreeNode.type === 'camera') {
          routerPath = `${basicPath}/${this.activeTreeNode.clientId}/${this.activeTreeNode.siteId}/${this.activeTreeNode.cameraId}`
          this.setClientType('camera')
        }

        if (this.$route.path !== routerPath) {
          this.$router.push(routerPath)
        }
        this.getBreadcrumb()
      }
    } else if (
      !this.activeTreeNode &&
      !this.isUserCustomer &&
      this.lastSelectedNode?.nodeType !== 'load'
    ) {
      this.setClientType('global')
      this.setBreadcrumbList([
        {
          text: 'Global',
          disabled: false
        }
      ])
      if (this.$route.path !== pathName) {
        this.$router.push(pathName)
      }
    } else if (
      !this.activeTreeNode &&
      this.isUserCustomer &&
      this.lastSelectedNode?.nodeType !== 'load' &&
      this.lastSelectedNode?.nodeType !== 'add'
    ) {
      const defaultClient = this.getDefaultClient(this.currentUser.id)
      if (defaultClient.isDefault) {
        this.setClientType('client')
        this.setBreadcrumbList([
          {
            text: 'Default',
            disabled: false
          }
        ])
        const defaultClientPath = `${pathName}/${this.currentUser.id}/${defaultClient?.clientId}`
        if (this.$route.path !== defaultClientPath) {
          this.$router.push(defaultClientPath)
        }
      }
    }
  }
  public async refreshTreeView() {
    this.setBoostNodesFromSnapshot()
    await this.loadTreeView()
    await this.setInitialSelection()
  }

  /**
   * @description
   * Calculate the color for the badge based on the status of the node
   *
   * @param {Item} item - The item to get the badge color for
   * @returns {string} - The color for the badge
   */
  public getBadgeColor(item: Item): string {
    const nodeStatus = this.getNodeStatus(item)

    // Using a mapped object for status-to-color mapping
    const statusColorMap: Record<NodeStatus, string> = {
      [PromiseQubeNodeStatus.Off]: '#000000', // Black
      [PromiseQubeNodeStatus.Unauthenticated]: '#9E9E9E', // Grey
      [PromiseQubeNodeStatus.Armed_Inactive]: '#FF5252', // Red
      [PromiseQubeNodeStatus.Disarmed_Inactive]: '#4CAF50', // Green
      [PromiseQubeNodeStatus.Armed_Active]: '#FF5252', // Red for Armed and Active
      [PromiseQubeNodeStatus.Disarmed_Active]: '#4CAF50', // Green for Disarmed and Active
      [MonitoringStatus.Armed]: '#FF5252', // Red
      [MonitoringStatus.Disarmed]: '#4CAF50' // Green
    }

    return statusColorMap[nodeStatus] || '#000000'
  }

  /**
   * @description
   * Calculate the tooltip for the badge based on the status of the node
   *
   * @param {Item} item - The item to get the badge color for
   * @returns {string} - The tooltip for the badge
   */
  public getNodeStatusTooltip(item: Item): string {
    const nodeStatus = this.getNodeStatus(item)

    // Using a mapped object for status-to-color mapping
    const statusColorMap: Record<NodeStatus, string> = {
      [PromiseQubeNodeStatus.Off]: `${item.type} off: Not monitoring or recording`,
      [PromiseQubeNodeStatus.Unauthenticated]: 'Unauthenticated',
      [PromiseQubeNodeStatus.Armed_Inactive]: `Armed and Inactive: ${item.type} is ready but currently not working`,
      [PromiseQubeNodeStatus.Disarmed_Inactive]: `Disarmed and Inactive: ${item.type} is disarmed and currently not working`,
      [PromiseQubeNodeStatus.Armed_Active]: `Armed: ${item.type} is active and monitoring`,
      [PromiseQubeNodeStatus.Disarmed_Active]: `Disarmed: ${item.type} is active but not monitoring.`,
      [MonitoringStatus.Armed]: `Armed: ${item.type} is active and monitoring`,
      [MonitoringStatus.Disarmed]: `Disarmed: ${item.type} is active but not monitoring.`
    }

    return statusColorMap[nodeStatus] || `${item.type} status unknown`
  }

  /**
   * @description
   * Calculate the status of the node
   *
   * @param {Item} item - The item to get the status for
   * @returns {NodeStatus} - The status of the node
   */
  public getNodeStatus(item: Item): NodeStatus {
    if (!item.isHardwareDevice) {
      return item.isNodeArmed
        ? MonitoringStatus.Armed
        : MonitoringStatus.Disarmed
    }

    const isInactive = !item.isActive
    const isDisarmed = !item.isNodeArmed

    switch (item.type) {
      case NodeTypes.Camera: {
        const isCameraOff = item.state === 'off' || !item.isEdgeDeviceEnabled

        if (isCameraOff) {
          return PromiseQubeNodeStatus.Off
        }

        if (item.isAuthenticated !== true) {
          return PromiseQubeNodeStatus.Unauthenticated
        }

        if (isInactive) {
          return isDisarmed
            ? PromiseQubeNodeStatus.Disarmed_Inactive
            : PromiseQubeNodeStatus.Armed_Inactive
        }

        return isDisarmed
          ? PromiseQubeNodeStatus.Disarmed_Active
          : PromiseQubeNodeStatus.Armed_Active
      }

      case NodeTypes.Site: {
        if (isInactive) {
          return isDisarmed
            ? PromiseQubeNodeStatus.Disarmed_Inactive
            : PromiseQubeNodeStatus.Armed_Inactive
        }

        return isDisarmed
          ? PromiseQubeNodeStatus.Disarmed_Active
          : PromiseQubeNodeStatus.Armed_Active
      }

      default:
        return item.isNodeArmed
          ? MonitoringStatus.Armed
          : MonitoringStatus.Disarmed
    }
  }
  public setActiveNode(node) {
    this.activeTreeNode = node
  }
  public onClickBackDrop() {
    this.setIsToggleMenuEnabled(true)
  }
  private validateGlobalNode() {
    const routerPath: string = window.location.pathname.split('/')[2]
    if (!routerPath && !this.isUserCustomer && this.isUserAdmin) {
      this.setClientType('global')
      this.setBreadcrumbList([
        {
          text: 'Global',
          disabled: false
        }
      ])
    }
  }
  public isNavigationVisible(): boolean {
    return (
      this.$route.name !== 'WorkerSpaceVehicle' &&
      this.$route.name !== 'ServiceStatus'
    )
  }
  public getBackgroundColor(): string {
    return this.isUserAdmin && this.isDarkModeToggleEnabled
      ? this.getColors.darkPrimaryColor
      : this.getColors.lightPrimaryColor
  }
  private async waitForCurrentUser() {
    return new Promise((resolve) => {
      const checkCurrentUser = () => {
        if (this.currentUser) {
          resolve(true)
        } else {
          // Check again after a delay
          setTimeout(checkCurrentUser, 100) // Check every 100ms
        }
      }

      checkCurrentUser()
    })
  }

  public isDeleteIconVisible(item) {
    return (
      (!['add', 'load'].includes(item?.nodeType) &&
        this.hoveredNode &&
        item.id === this.hoveredNode.id) ||
      (this.activeTreeNode &&
        item.id === this.activeTreeNode?.id &&
        !item.id.includes('add'))
    )
  }
  get isSeeMoreButtonEnable() {
    if (this.isUserAdmin) {
      return this.isLoadMoreVisible.customers
    }

    return false
  }

  private getDefaultClient(userFirestoreId: string) {
    let filteredNode = null
    if (this.isUserAdmin) {
      this.nodes.forEach((item) => {
        item.children.map((childItem) => {
          if (
            childItem.userFirestoreId === userFirestoreId &&
            childItem.type === 'client' &&
            childItem.name.includes('Default')
          ) {
            filteredNode = childItem
          }
        })
      })
    } else {
      filteredNode = this.nodes.find(
        (item) =>
          item.userFirestoreId === userFirestoreId &&
          item.type === 'client' &&
          item.isDefault === true
      )
      if (!filteredNode) {
        filteredNode = this.nodes.find((item) => {
          return (
            item.userFirestoreId === userFirestoreId &&
            item.type === 'client' &&
            item.name.includes('Default')
          )
        })
        if (!filteredNode) {
          filteredNode = this.nodes.find((item) => {
            return (
              item.userFirestoreId === userFirestoreId && item.type === 'client'
            )
          })
        }
      }
    }

    return filteredNode
  }
  private getBreadcrumb() {
    const payload = {
      tree: this.nodes,
      childId: this.activeTreeNode?.id,
      userId: this.activeTreeNode?.userFirestoreId,
      isAdmin: this.isUserAdmin
    }
    this.loadBreadcrumb(payload)
  }

  private getBoostNodeParameters() {
    let boostIds: BoostNodeId = {}
    if (this.search === '') {
      if (this.initialLoadClientId) {
        boostIds.boostClientId = this.initialLoadClientId
      }
      if (this.initialLoadSiteId) {
        boostIds.boostSiteId = this.initialLoadSiteId
      }
      if (this.initialLoadCameraId) {
        boostIds.boostCameraId = this.initialLoadCameraId
      }
      if (this.initialLoadUserId && this.isUserAdmin) {
        boostIds.boostUserId = this.initialLoadUserId
      }
    }
    return boostIds
  }
  public async fetchData(item?: any, refresh?: boolean) {
    this.isTreeLoading = true
    const boostIds = this.getBoostNodeParameters()
    if (this.isUserAdmin) {
      await this.fetchCompleteTreeViewNodes({
        loadMoreMeta: item,
        userId:
          item?.type === NodeTypes.Client ? item.userFirestoreId : undefined,
        size: this.SIZE,
        isAdmin: true,
        isUserCustomer: this.isUserCustomer,
        isWriteEnabled: this.isWriteEnabled,
        search: this.search,
        refresh,
        boostIds,
        boostSecondaryId: this.boostSecondaryId
      })
    }
    if (this.isUserCustomer) {
      await this.fetchCompleteTreeViewNodes({
        userId: this.currentUser?.id,
        size: this.SIZE,
        isUserCustomer: this.isUserCustomer,
        isAdmin: this.isUserAdmin,
        isWriteEnabled: this.isWriteEnabled,
        search: this.search,
        refresh,
        boostIds,
        loadMoreMeta: item,
        boostSecondaryId: this.boostSecondaryId
      })
    }
    this.isTreeLoading = false
  }
  public async fetchPaginatedSitesAndCameras(item?: any) {
    this.isTreeLoading = true
    const boostIds = this.getBoostNodeParameters()
    await this.fetchTreeViewSiteAndCameraNodes({
      size: this.SIZE,
      isAdmin: this.isUserAdmin,
      search: this.search,
      boostIds,
      loadMoreMeta: item,
      boostSecondaryId: this.boostSecondaryId
    })
    this.isTreeLoading = false
  }
  public async fetchNodes(item: any) {
    if (item.type === 'site' || item.type === 'camera') {
      await this.fetchPaginatedSitesAndCameras(item)
    } else {
      await this.fetchData(item)
    }
  }
  private debouncedSearchTreeNodes = debounce(async () => {
    if (this.search?.trim() !== '') {
      await this.fetchData(undefined, true)
    } else {
      await this.fetchData()
    }
  }, 300)

  public async loadTreeView() {
    await this.waitForCurrentUser()
    await this.fetchData()
  }
  public handleOpenOrClose(node) {
    this.openedTreeNode = []
    this.openedTreeNode.push(node)
  }

  public getInitialSelection() {
    if (this.nodes && this.nodes.length > 0 && this.userId) {
      let filteredNodes: any[] = this.nodes
      let openedNodes: any[] = []

      // Helper function to find and open parent nodes
      const openParentNodes = (node: any) => {
        if (node && node.children) {
          const parentNode = node.children.find((child) =>
            filteredNodes.includes(child)
          )
          if (parentNode) {
            openedNodes.push(parentNode)
            openParentNodes(parentNode)
          }
        }
      }

      if (this.isUserAdmin) {
        filteredNodes = this.nodes.filter(
          (item) => item.id === this.userId && item.type === 'customer'
        )
        if (filteredNodes.length > 1) {
          filteredNodes = [filteredNodes[0]]
        } else if (filteredNodes.length === 0) {
          // If no customer found, return an empty array
          return { openedNodes: [], filteredNodes: [] }
        }
        openedNodes.push(filteredNodes[0])
        openParentNodes(filteredNodes[0])
      }
      if (this.clientId && filteredNodes[0]) {
        if (this.isUserAdmin) {
          filteredNodes = filteredNodes[0].children.filter(
            (item) => item.type === 'client' && item.clientId === this.clientId
          )
        } else {
          filteredNodes = filteredNodes.filter(
            (item) => item.type === 'client' && item.clientId === this.clientId
          )
        }
        if (filteredNodes.length === 0) {
          // If no client found, return an empty array
          return { openedNodes: [], filteredNodes: [] }
        }
        openedNodes.push(filteredNodes[0])
        openParentNodes(filteredNodes[0])
      }

      if (this.siteId && filteredNodes[0]) {
        filteredNodes = filteredNodes[0].children.filter(
          (item) => item.type === 'site' && item.siteId === this.siteId
        )
        if (filteredNodes.length === 0) {
          // If no site found, return the current state of openedNodes and filteredNodes
          return { openedNodes, filteredNodes: [] }
        }
        openedNodes.push(filteredNodes[0])
        openParentNodes(filteredNodes[0])
      }

      if (this.cameraId && filteredNodes[0]) {
        filteredNodes = filteredNodes[0].children.filter(
          (item) => item.type === 'camera' && item.cameraId === this.cameraId
        )
        if (filteredNodes.length === 0) {
          // If no camera found, return the current state of openedNodes and filteredNodes
          return { openedNodes, filteredNodes: [] }
        }
        openedNodes.push(filteredNodes[0])
        openParentNodes(filteredNodes[0])
      }

      return {
        openedNodes,
        filteredNodes
      }
    }
  }
  public async setInitialSelection() {
    const initialSelection = this.getInitialSelection()
    if (initialSelection) {
      this.openedTreeNode = initialSelection.openedNodes
      this.selectedTreeNode = initialSelection.filteredNodes
      if (this.activeTreeNode) {
        this.getBreadcrumb()
      }
    }
  }
  // this check if there is any additional nodes excluding (default and add)
  private isNodeListEmpty() {
    if (this.nodes.length > 0) {
      const filteredNodes = this.nodes.filter(
        (item) =>
          item?.nodeType !== 'load' ||
          item?.nodeType !== 'add' ||
          item.name !== 'Default'
      )
      if (filteredNodes.length > 0) {
        return false
      }
      return true
    }

    return false
  }
  private isNodeExist(nodeRef) {
    if (this.nodes.length < 1) {
      return false
    }
    if (nodeRef.id.includes('load') || nodeRef.id.includes('add')) {
      return false
    }
    let nodes = this.nodes
    if (this.isUserAdmin) {
      const userFound = this.nodes.findIndex(
        (item) => item.id === nodeRef.userId
      )
      if (userFound === -1) {
        return false
      }
      nodes = this.nodes[userFound]?.children
    }
    if (nodeRef.type === NodeTypes.Camera) {
      const client = nodes.find((item) => item.id === nodeRef?.grandParentId)
      if (!client) {
        return false
      }
      const site = client.children.find((item) => item.id === nodeRef?.parentId)
      if (!site) {
        return false
      }
      const foundIndex = site.children.find((doc) => {
        return doc.id === nodeRef.id
      })
      if (foundIndex !== -1) return true
    }

    if (nodeRef.type === NodeTypes.Site) {
      const client = this.nodes.find(
        (item) => item.id === nodeRef?.grandParentId
      )
      if (!client) {
        return false
      }
      const foundIndex = client.children.findIndex((doc) => {
        return doc.id === nodeRef.id
      })
      if (foundIndex !== -1) return true
    }

    if (nodeRef.type === NodeTypes.Client) {
      const foundIndex = nodes.findIndex((doc) => {
        return doc.id === nodeRef.id
      })
      if (foundIndex !== -1) return true
    }
    return false
  }

  private async handleInitialRedirect() {
    const paths = [
      '/alarms',
      '/statistics',
      '/sandbox',
      '/user-management',
      '/maps',
      '/configurations',
      '/live'
    ]
    const pathName: string = `/${window.location.pathname.split('/')[1]}`
    if (this.currentUser && this.nodes && this.nodes.length > 0) {
      if (
        !this.$route.params.clientId ||
        this.$route.params.clientId === 'undefined'
      ) {
        if (paths.includes(pathName)) {
          let routerPath = ''
          const savedRouteSnapshot = this.routeSnapshot
          let defaultClient
          if (this.isUserCustomer) {
            defaultClient = this.getDefaultClient(this.currentUser.id)
          }
          if (
            savedRouteSnapshot &&
            savedRouteSnapshot.route !== '' &&
            savedRouteSnapshot.user === this.currentUser?.id
          ) {
            if (!this.isNodeListEmpty()) {
              if (this.isNodeExist(savedRouteSnapshot.nodeRef)) {
                routerPath = savedRouteSnapshot.route
              } else if (this.isUserCustomer) {
                routerPath = `${pathName}/${this.currentUser?.id}/${defaultClient?.clientId}`
              }
            }
          } else if (this.isUserCustomer) {
            routerPath = `${pathName}/${this.currentUser?.id}/${defaultClient?.clientId}`
          } else {
            routerPath = `${pathName}`
          }
          if (this.$route.path !== routerPath && routerPath !== '') {
            this.$router
              .push({ path: routerPath, replace: true })
              .catch((err) => {
                // Force redirect on route overide error
                console.error('Navigation Overide Error!')
                this.$router.push(routerPath)
              })
          }
        }
      }
    }
  }

  public setNodeWritePermission() {
    const initialSelection = this.getInitialSelection()
    if (this.currentUser && this.isUserAdmin) {
      this.isWriteEnabled = true
    } else {
      if (initialSelection && initialSelection.filteredNodes[0]) {
        if (initialSelection.filteredNodes[0]?.type !== 'customer') {
          this.isWriteEnabled =
            initialSelection.filteredNodes[0].isWriteEnabled ?? false
          this.isLoadingDeleteButton = false
        }
      }
    }
  }

  public findParentNode(treeItems, childNode) {
    let parentNode = null
    treeItems.forEach((item) => {
      if (item.children && item.children.length > 0) {
        const hasParentNode = item.children.find((childItem) => {
          return (
            childItem.id === childNode.id &&
            childItem.userFirestoreId === childNode.userFirestoreId
          )
        })
        if (hasParentNode) {
          parentNode = item
        } else {
          const parent = this.findParentNode(item.children, childNode)
          if (parent) {
            parentNode = parent
          }
        }
      }
    })
    return parentNode
  }
  public async handleDeleteUser(): Promise<void> {
    const userIds = [this.activeTreeNode?.id]
    const userDeletePayload: any = {
      userIds: userIds
    }
    return this.deleteUser(userDeletePayload)
  }

  public async handleDeleteTreeNode(type: string): Promise<any> {
    const treeNodeDeletePayload: any = {
      type
    }
    if (type === 'Client') {
      treeNodeDeletePayload.clientId = this.activeTreeNode.id
    } else if (type === 'Site') {
      treeNodeDeletePayload.siteId = this.activeTreeNode.id
    } else if (type === 'Camera') {
      treeNodeDeletePayload.cameraId = this.activeTreeNode.id
    }

    return this.deleteTreeNode(treeNodeDeletePayload)
  }
  public checkDeleteInput() {
    // Check if input matches 'DELETE'
    this.deleteInputMatch = this.deleteInput === 'DELETE'
  }
  public async onDeleteConfirm() {
    if (this.deleteInputMatch) {
      this.isDeleting = true
      const parentNode = await this.findParentNode(
        this.nodes,
        this.activeTreeNode
      )

      let result = null
      if (this.activeTreeNode) {
        if (
          !this.activeTreeNode.nodeType &&
          this.activeTreeNode.type === 'customer'
        ) {
          result = await this.handleDeleteUser()
        } else if (
          !this.activeTreeNode.nodeType &&
          this.activeTreeNode.type === 'client'
        ) {
          result = await this.handleDeleteTreeNode('Client')
        } else if (
          !this.activeTreeNode.nodeType &&
          this.activeTreeNode.type === 'site'
        ) {
          result = await this.handleDeleteTreeNode('Site')
        } else if (
          !this.activeTreeNode.nodeType &&
          this.activeTreeNode.type === 'camera'
        ) {
          result = await this.handleDeleteTreeNode('Camera')
        }

        if (result && result.status === 'success') {
          if (this.$router.currentRoute.path !== '/user-management') {
            if (this.activeTreeNode.type === 'customer') {
              this.$router.push('/user-management')
            } else if (parentNode) {
              this.openTreeNode([parentNode])
            } else {
              this.openTreeNode([
                this.getDefaultClient(this.activeTreeNode.userFirestoreId)
              ])
            }
          }
        } else {
          EventBus.$emit(
            'spreadSnack',
            new SnackBarError('error', result.message)
          )
        }
      }

      this.deleteDialog = false
      this.isDeleting = false
      this.deleteInput = ''
    }
  }

  public onDeleteClose() {
    this.deleteDialog = false
    this.deleteInput = ''
    this.deleteInputMatch = false
  }

  public getDeleteIcon(item) {
    const isDarkMode = this.isUserAdmin && this.isDarkModeToggleEnabled
    if (item === this.hoveredItem) {
      return isDarkMode
        ? '/img/bin_dark_active.svg'
        : '/img/bin_light_active.svg'
    }
    return isDarkMode
      ? '/img/bin_dark_default.svg'
      : '/img/bin_light_default.svg'
  }
  public onDeleteNode(item) {
    this.setActiveNode(item)
    this.deleteDialog = true
  }
  public onHoverIcon(item, isHovering) {
    this.hoveredItem = isHovering ? item : null
  }
  public get isEdgeAndInactiveCamera() {
    return (item: {
      isEdgeDeviceEnabled?: boolean
      isActive: boolean
      type: string
    }) => {
      if (item.type !== 'camera') return false

      if (item.isEdgeDeviceEnabled && item.isEdgeDeviceEnabled === true) {
        return !item.isActive
      }
      return false
    }
  }
  public get showHamBurgerMenu() {
    return isCompactView()
  }
  public get siteStyle() {
    return (item: { isHardwareDevice: boolean; isActive: boolean }) => {
      const baseColor =
        this.isUserAdmin && this.isDarkModeToggleEnabled
          ? item.isHardwareDevice
            ? item.isActive
              ? this.getColors.lightPrimaryColor
              : this.getColors.lightTreeItemsColor
            : this.getColors.lightPrimaryColor
          : item.isHardwareDevice
          ? item.isActive
            ? this.getColors.darkTreeItemsColor
            : this.getColors.lightTreeItemsColor
          : this.getColors.darkTreeItemsColor
      return {
        color: baseColor
      }
    }
  }
  public get isInactiveSite() {
    return (item: Item): boolean => {
      if (item.type !== NodeTypes.Site) {
        return false
      }

      if (item.isHardwareDevice && !item.isActive) {
        return true
      }

      return false
    }
  }

  public get cameraStyle() {
    return (item: Item) => {
      let baseColor: string = ''

      if (this.isUserAdmin && this.isDarkModeToggleEnabled) {
        // Dark mode logic
        if (item.isHardwareDevice) {
          // promiseQube camera
          if (item.isEdgeDeviceEnabled === true) {
            baseColor = item.isActive
              ? this.getColors.lightPrimaryColor
              : this.getColors.lightTreeItemsColor
          } else {
            baseColor = this.getColors.lightTreeItemsColor
          }
        } else {
          // Normal camera
          baseColor = this.getColors.lightPrimaryColor
        }
      } else {
        // Light mode logic
        if (item.isHardwareDevice) {
          // promiseQube camera
          if (item.isEdgeDeviceEnabled === true) {
            baseColor = item.isActive
              ? this.getColors.darkTreeItemsColor
              : this.getColors.lightTreeItemsColor
          } else {
            baseColor = this.getColors.lightTreeItemsColor
          }
        } else {
          // Normal camera
          baseColor = this.getColors.darkTreeItemsColor
        }
      }

      return {
        color: baseColor
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../variables.styl';

.flex-full-width {
  display: flex;
  height: 100%;
  width: 100%;
}

.darkmode-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 0px !important;
  padding-left: 15px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.darkmodeToggle {
  float: left;
  padding-left: 25px;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.delBtn {
  align-self: flex-start;
  padding: 0em;
  width: 100%;
  position: fixed;
  bottom: 10px;
}
.seeMoreBtn {
  align-self: flex-start;
  padding: 0em;
  width: 100%;
  position: fixed;
  bottom: 50px;
}
.topAlign {
  align-self: flex-start;
  padding: 0em;
  width: 100%;
  height: 80px;
  position: fixed;
}

.topAlign--nav{
  align-self: flex-start;
  margin-top: 72px;
  width: 100%;
  position: fixed;
}

.topAlign--nav--tree{
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  height: calc(100vh - 105px);
}

.middleAlign {
  align-self: center;
  width: 100%;
}

.list-custom--icon {
  width: 100%;
  text-align: center;
}

.list-custom--title {
  font-size: 0.95em;
  text-align: left;
  width: 100%;
}

.userfirstname {
  font-weight: bold;
}

.v-btn--outlined {
    border: 2px solid #FFD42A;
  }

.v-list-item--avatar{
  height: 80px;
  background-color: #FFD42A !important
}

.v-list-item--clients{
  border: 2px solid #FFD42A;
  display: flex;
  align-items: center;
}

.lower-case{
  text-transform: unset !important;
  font-size: 0.95em;
}
.v-text-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input{
  width:100%;
  margin-top: 18px;
  margin-bottom: 18px;
}

/* /* Dark mode scroll bar */
.admin-scroll-bar ::-webkit-scrollbar {
  background-color: #514f4b;
}

.admin-scroll-bar ::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.admin-scroll-bar * {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #514f4b transparent;
}

.v-list-item--navbtn-active{
  background-color: #FFD42A !important
  border-color: #FFD42A
  border-style: solid
  border-width: 1px
  border-radius: 5px
}
.v-list-item--navbtn-inactive{
  background-color: #FFFFFF !important
  border-color: #FFD42A
  border-style: solid
  border-width: 1px
  border-radius: 5px
}


.custom-dialog {
  z-index: 12000 !important;
}

.treeview-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-icon {
  cursor: pointer;
}

.close-btn-dark:hover {
  background-color: #6b580e !important;
  color: #fff;
}
</style>

<style>
.v-treeview-node__label {
  text-align: left !important;
  font-size: 14px !important;
}
.v-treeview.admin-node .v-treeview-node__content {
  margin-left: -20px;
}

/* Maintain indentation for child nodes in customer accounts */
.v-treeview-node__children .v-treeview-node__content {
  padding-left: 20px; /* Indentation for child nodes */
}

.tree-container {
  margin-left: 15px;
  margin-right: 15px;
}
.lower-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.lower-container > * {
  transform: translateX(-35px);
}

.custom-icon {
  align-items: center;
  display: inline-flex;
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.resize-tooltip {
  position: fixed;
  left: 0; /* Will be dynamically adjusted */
  top: 0; /* Will be dynamically adjusted */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none; /* Allow pointer events to pass through */
  transition: opacity 0.3s ease;
  z-index: 12000 !important;
}

.resize-tooltip.show {
  opacity: 1; /* Show the tooltip */
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'sm-and-up' )}
  .v-list--rounded .v-list-item, .v-list--rounded .v-list-item::before, .v-list--rounded .v-list-item > .v-ripple__container
    border-radius: 5px !important,
    background: rgba(255,255,255,0) !important,
    border: 1px solid #ffd42a,
.theme--light.v-navigation-drawer .v-divider
  border-color: rgba(0, 0, 0, 0.2)
.v-application--is-ltr .v-treeview-node__toggle
  color: #ababab,
.see-more-btn
  justify-content: center
  align-items: center
  display: flex
  cursor: pointer
  padding:0.4rem
  background-color: rgba(255, 212, 42)
  border-radius: 20px
  margin-top:10px
  width: 100px

.see-more-btn-customer
  justify-content: center
  align-items: center
  display: flex
  cursor: pointer
  padding:0.4rem
  background-color: rgba(255, 212, 42)
  border-radius: 20px
  margin-top:10px
  width: 100px



.treeview-label-text
  display: inline-block
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.mobile-z-index
    z-index: 10000
    transition: all 0.3s

@media only screen and (width <= 320px)
  .nav-drawer
    width: 32% !important
    max-height: 100vh !important
    height: 100vh !important
    overflow-y: hidden
  .topAlign--nav--tree
      overflow-x: hidden
      margin-top: 0px !important
      padding-top: 0px !important
  .navigation-header
        position: sticky /* Keep it fixed at the top */
        top: 0 /* Position it at the top */
        z-index: 10 /* Ensure it stays above other content */
        background-color: white !important

@media only screen and (320px < width <= 480px)
  .nav-drawer
    width: calc(100vw - 10%) !important
    max-height: 100vh !important
    height: 100vh !important
    overflow-y: hidden
  .topAlign--nav--tree
    overflow-x: hidden
    margin-top: 0px !important
    padding-top: 0px !important
  .navigation-header

      position: sticky /* Keep it fixed at the top */
      top: 0 /* Position it at the top */
      z-index: 10 /* Ensure it stays above other content */
      background-color: white !important

@media only screen and (480px < width < 768px)
  .navigation-backdrop
    display: none !important
  .nav-drawer
    width: 256px !important
    max-height: 100vh !important
    height: 100vh !important
    overflow-y: hidden
  .topAlign--nav--tree
    margin-top: 0px !important
    padding-top: 0px !important
    overflow-x: hidden
  .navigation-header
    position: sticky /* Keep it fixed at the top */
    top: 0 /* Position it at the top */
    z-index: 10 /* Ensure it stays above other content */
    background-color: white !important

@media only screen and (768px <= width <= 1024px)
  .navigation-backdrop
    display: none !important
  .nav-drawer
    width: 256px !important

@media only screen and (1024px < width)
  .navigation-backdrop
    display: none !important
  .nav-drawer
    width: 256px !important
</style>
