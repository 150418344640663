var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'admin-scroll-bar' : '',
    _vm.showHamBurgerMenu ? 'mobile-z-index' : ''
  ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTooltipVisible && !_vm.isMobile),expression:"isTooltipVisible && !isMobile"}],ref:"resizeTooltip",staticClass:"resize-tooltip"},[_vm._v(" Drag to resize ")]),(!_vm.isToggleMenuEnabled)?_c('v-overlay',{staticClass:"navigation-backdrop",attrs:{"absolute":true},on:{"click":[function($event){$event.stopPropagation();},_vm.onClickBackDrop]}}):_vm._e(),((_vm.isUserAdmin || _vm.isNavigationVisible()) && !_vm.isToggleMenuEnabled)?_c('v-navigation-drawer',{ref:"drawer",class:_vm.drawerStyle,style:({
      backgroundColor: _vm.getBackgroundColor()
    }),attrs:{"app":"","permanent":true,"fixed":"","mini-variant":_vm.isToggleMenuEnabled,"width":!_vm.isMobile ? this.navigation.width : undefined,"data-test-id":"navigation-drawer"},on:{"update:miniVariant":function($event){_vm.isToggleMenuEnabled=$event},"update:mini-variant":function($event){_vm.isToggleMenuEnabled=$event}}},[_c('div',{staticClass:"flex-full-width",attrs:{"dense":""}},[_c('v-list',{staticClass:"topAlign--nav--tree"},[_c('div',{staticClass:"navigation-header"},[(_vm.showHamBurgerMenu)?_c('Header',{attrs:{"route":_vm.headerRoute,"minified":true,"showHamBurgerMenu":false}}):_vm._e()],1),_c('div',{staticClass:"tree-container"},[(!_vm.showHamBurgerMenu)?_c('AccountAvatar'):_vm._e(),_c('v-text-field',{staticClass:"search-input",style:({
              'margin-top': '18px'
            }),attrs:{"disabled":_vm.hasTemporaryDisabled,"append-icon":"mdi-magnify","append-outer-icon":"mdi-refresh","hide-details":"","label":"Search","dense":"","outlined":"","loading":_vm.isTreeLoading ? '#ffd42a' : false,"background-color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.darkSectionColor
                : _vm.getColors.lightPrimaryColor,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"treeview-search-text-field"},on:{"click:append-outer":_vm.refreshTreeView},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-treeview',{ref:"treeview",class:_vm.isUserAdmin ? 'admin-node' : '',style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.lightTreeItemsColor
          }),attrs:{"items":_vm.nodes,"active":_vm.selectedTreeNode,"open":_vm.openedTreeNode,"item-key":"key","children-key":"key","activatable":_vm.isNodeActive,"dense":"","return-object":"","hoverable":"","color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkStrokeColor
              : '',"data-test-id":"treeview"},on:{"update:items":function($event){_vm.nodes=$event},"update:active":[_vm.openTreeNode,function($event){_vm.selectedTreeNode=$event}],"update:open":function($event){_vm.openedTreeNode=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [_c('div',[(item.type === 'customer' && item.nodeType !== 'load')?_c('Avatar',{staticStyle:{"padding-top":"6px","padding-left":"24px"},attrs:{"id":item.id,"size":20}}):_vm._e(),(
                  !['add', 'load', 'customer'].includes(item.nodeType) &&
                  _vm.isEdgeAndInactiveCamera(item)
                )?_c('span',{staticClass:"custom-icon",style:({
                  marginRight: item.type === 'site' ? '-10px' : 'auto' // Temporary fix for the icon alignment MR: 2158
                }),attrs:{"title":_vm.getNodeStatusTooltip(item)}},[_c('NodeInactiveSVG',{attrs:{"armedStatus":_vm.getNodeStatus(item),"nodeType":"camera","isDarkModeEnabled":_vm.isDarkModeToggleEnabled}})],1):_vm._e(),(
                  !['add', 'load', 'customer'].includes(item.nodeType) &&
                  _vm.isInactiveSite(item)
                )?_c('span',{staticClass:"custom-icon",style:({
                  marginRight: item.type === 'site' ? '-10px' : 'auto' // Temporary fix for the icon alignment MR: 2158
                }),attrs:{"title":_vm.getNodeStatusTooltip(item)}},[_c('NodeInactiveSVG',{attrs:{"armedStatus":_vm.getNodeStatus(item),"nodeType":"site","isDarkModeEnabled":_vm.isDarkModeToggleEnabled}})],1):_vm._e(),(
                  !['add', 'load', 'customer'].includes(item.nodeType) &&
                  item.type !== 'customer' &&
                  !_vm.isEdgeAndInactiveCamera(item) &&
                  !_vm.isInactiveSite(item)
                )?_c('v-badge',{attrs:{"bottom":"","overlap":"","dot":"","color":_vm.getBadgeColor(item),"title":_vm.getNodeStatusTooltip(item),"data-test-id":"treeview-badge"}},[(item.type === 'client')?_c('v-icon',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : _vm.getColors.darkTreeItemsColor
                  }),attrs:{"data-test-id":"treeview-client-icon"}},[_vm._v(" mdi-human ")]):_vm._e(),(item.type === 'site')?_c('v-icon',{style:(_vm.siteStyle(item)),attrs:{"data-test-id":"treeview-site-icon"}},[_vm._v(" mdi-map-marker ")]):_vm._e(),(item.type === 'camera')?_c('v-icon',{style:(_vm.cameraStyle(item)),attrs:{"data-test-id":"treeview-camera-icon"}},[_vm._v(" mdi-camera ")]):_vm._e()],1):_vm._e(),(item.nodeType === 'add')?_c('v-icon',{style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : _vm.getColors.lightTreeItemsColor
                }),attrs:{"data-test-id":"treeview-add-icon"}},[_vm._v(" mdi-plus ")]):_vm._e()],1)]}},{key:"label",fn:function({ item }){return [_c('div',{staticClass:"treeview-label",on:{"mouseenter":function($event){_vm.hoveredNode = item},"mouseleave":function($event){_vm.hoveredNode = null},"click":function($event){return _vm.setActiveNode(item)}}},[(item.nodeType !== 'load')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"treeview-label-text",on:{"click":function($event){return _vm.setActiveNode(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.companyName ? item.companyName + ' - ' : '')+" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.companyName ? item.companyName + ' - ' : '')+" "+_vm._s(item.name)+" ")])]):_vm._e(),(_vm.isDeleteIconVisible(item))?_c('img',{staticClass:"delete-icon",class:{
                  'icon-active': item === _vm.activeTreeNode
                },attrs:{"src":_vm.getDeleteIcon(item)},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteNode(item)},"mouseover":function($event){return _vm.onHoverIcon(item, true)},"mouseleave":function($event){return _vm.onHoverIcon(item, false)}}}):_vm._e()],1),_c('div',{staticClass:"tree-leaf"},[(_vm.isTreeLoading && item.nodeType === 'load')?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_vm._e(),(!_vm.isTreeLoading)?_c('div',[(item.nodeType === 'load')?_c('div',{staticClass:"lower-container",attrs:{"title":`See more ${item.type}s`},on:{"click":function($event){$event.stopPropagation()
                    _vm.fetchNodes(item)}}},[(_vm.isUserAdmin && item.type === 'customer')?_c('div',{style:({
                      color:
                        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                          ? _vm.getColors.lightPrimaryColor
                          : _vm.getColors.lightTreeItemsColor
                    })},[_c('div',{staticClass:"see-more-btn elevation-0 text-subtitle-2"},[_c('v-icon',{staticStyle:{"color":"rgb(0, 0, 0)"}},[_vm._v(" mdi-arrow-down ")]),_c('span',{staticStyle:{"color":"rgb(0, 0, 0)"}},[_vm._v("More")])],1)]):_c('div',{class:{
                      'see-more-btn-customer ':
                        !_vm.isUserAdmin && item.type === 'client'
                    }},[_c('v-icon',{style:({
                        color:
                          !_vm.isUserAdmin && item.type === 'client'
                            ? 'rgb(0, 0, 0)'
                            : _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.lightTreeItemsColor
                      })},[_vm._v(" mdi-arrow-down ")]),_c('span',{style:({
                        color:
                          !_vm.isUserAdmin && item.type === 'client'
                            ? 'rgb(0, 0, 0)'
                            : _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.lightTreeItemsColor
                      })},[_vm._v(" "+_vm._s(item.type === 'client' || item.type === 'site' || item.type === 'camera' ? 'More' : _vm.isUserAdmin ? 'More' : 'Load more')+" ")])],1)]):_vm._e()]):_vm._e()])]}}],null,false,1908540511)}),_c('v-dialog',{staticClass:"custom-dialog",attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{staticStyle:{"padding":"25px"},style:({ backgroundColor: _vm.getBackgroundColor() })},[_c('v-toolbar',{staticClass:"secondary elevation-0",attrs:{"dark":""}},[_c('v-toolbar-title',{staticStyle:{"color":"black","text-align":"center"}},[_vm._v("Confirm Deletion")])],1),(_vm.activeTreeNode)?_c('div',{staticClass:"pt-5"},[(_vm.activeTreeNode.type === 'customer')?_c('div',[_c('p',{staticClass:"mb-5",style:({ color: _vm.buttonColor, textAlign: 'center' })},[_vm._v(" Are you sure you want to delete the selected user ? "),_c('br'),_vm._v(" This action cannot be undone. ")]),_c('div',{staticClass:"mb-5",style:({ color: _vm.buttonColor, textAlign: 'center' })},[_c('div',[_vm._v(_vm._s(_vm.activeTreeNode.email))]),_c('div',[_vm._v(_vm._s(_vm.activeTreeNode.name))])]),_c('label',{staticClass:"mb-5",style:({ color: _vm.buttonColor, textAlign: 'center' })},[_vm._v("Please type \"DELETE\" to confirm the action.")])]):_c('div',[_c('p',{staticClass:"mb-5",style:({ color: _vm.buttonColor, textAlign: 'center' })},[_vm._v(" Are you sure you want to delete this "+_vm._s(_vm.activeTreeNode.type + ' ' + _vm.activeTreeNode.name)+" ? ")]),_c('label',{staticClass:"mb-5",style:({ color: _vm.buttonColor, textAlign: 'center' })},[_vm._v("Please type \"DELETE\" to confirm the action.")])]),_c('v-text-field',{style:({
                  margin: '20px 0px 0px 0px',
                  width: '100%',
                  color: _vm.buttonColor
                }),attrs:{"type":"text","dense":"","outlined":"","background-color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkSectionColor
                    : _vm.getColors.lightPrimaryColor,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"placeholder":'Type here'},on:{"input":_vm.checkDeleteInput},model:{value:(_vm.deleteInput),callback:function ($$v) {_vm.deleteInput=$$v},expression:"deleteInput"}}),(!_vm.isDeleting)?_c('v-card-actions',{staticClass:"d-flex justify-space-between mt-5"},[_c('Button',{class:[
                    'text-subtitle-2',
                    'close-btn',
                    {
                      'close-btn-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
                    }
                  ],style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : ''
                  }),attrs:{"text":"","data-ci-new-user-close-button":true,"btnText":"Close","btnStyle":"outlined"},on:{"onButtonClick":_vm.onDeleteClose}}),_c('Button',{staticClass:"elevation-0 text-subtitle-2",style:({
                    color: _vm.deleteInputMatch
                      ? 'white !important'
                      : 'rgba(255, 255, 255, 0.5) !important',
                    backgroundColor: '#FF4444 !important'
                  }),attrs:{"color":"error","data-ci-new-user-save-button":true,"btnText":"Delete","btnStyle":"delete","disabled":!_vm.deleteInputMatch},on:{"onButtonClick":_vm.onDeleteConfirm}})],1):_vm._e(),(_vm.isDeleting)?_c('v-progress-circular',{style:({ color: _vm.buttonColor }),attrs:{"indeterminate":""}}):_vm._e()],1):_vm._e()],1)],1)],1),(_vm.showHamBurgerMenu)?_c('NavigationAccountMenu'):_vm._e()],1)]):_vm._e(),_c('AddNewNode',{attrs:{"nodeType":_vm.nodeType,"active":_vm.addNewNode,"userId":_vm.activeTreeNode?.userFirestoreId,"clientId":_vm.activeTreeNode?.clientId,"siteId":_vm.activeTreeNode?.siteId},on:{"closeDialog":function($event){_vm.addNewNode = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }