export const getDefaultState = () => {
  return {
    isNotificationEnabled: false
  }
}

const state = getDefaultState()
export default {
  ...state
}
