import {
  todosCollection,
  todosProvider,
  functions,
  storage,
  workerQueueCollection,
  firestore
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import firebase from 'firebase/compat/app'
import moment from 'moment'
import { createIncidentObservation } from '@/services/api-admin/incident-observation/incident-observation'

const RECENT_LIMIT: number = 10
const PAGE_SIZE_HC: number = 12

const actions = {
  async getFinalResult({ commit }: any, id: string): Promise<any> {
    try {
      const response = await todosProvider.getFinalResult(id)
      return response
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  },
  setWorkerAssessment: firestoreAction(
    (
      context,
      payload: {
        workerId: any
        vehicleMovement: 'alarm' | 'no-alarm' | 'unsure'
        personAssessment: 'alarm' | 'no-alarm' | 'unsure'
        docId: string
      }
    ) => {
      const { workerId, vehicleMovement, personAssessment, docId } = payload

      const timestamp = firebase.firestore.Timestamp.now()
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

      let finalAssessment: 'alarm' | 'no-alarm' | 'unsure' = 'no-alarm'
      switch (true) {
        case personAssessment === 'alarm' || vehicleMovement === 'alarm':
          finalAssessment = 'alarm'
          break
        case personAssessment === 'unsure' || vehicleMovement === 'unsure':
          finalAssessment = 'unsure'
          break
        default:
          finalAssessment = 'no-alarm'
      }

      const worker = {
        updatedAt: serverTimestamp,
        workerId,
        isDisplayed: true,
        personAssessment,
        vehicleMovement,
        displayedAt: serverTimestamp
      }

      const finalResult = {
        personAssessment,
        vehicleMovement,
        finalAssessment,
        updatedAt: serverTimestamp
      }

      const value = {
        completed: true,
        flag: 'completed',
        completedAt: serverTimestamp,
        completedDate: moment(timestamp.toDate()).format('YYYY-MM-DD'),
        worker,
        finalResult,
        isAIOnly: false
      }
      return todosCollection.doc(docId).set(value, { merge: true })
    }
  ),
  removeTodoFromQueue: firestoreAction(
    (context, payload: { workerId: string; docId: string }) => {
      return workerQueueCollection.doc(payload.docId).update({
        todos: firebase.firestore.FieldValue.arrayRemove(payload.docId)
      })
    }
  ),
  setWorker: firestoreAction(
    (context, payload: { workerId: string; docId: string }) => {
      const worker = {
        isDisplayed: true,
        workerId: payload.workerId,
        displayedAt: firebase.firestore.FieldValue.serverTimestamp()
      }
      return todosCollection.doc(payload.docId).set({ worker }, { merge: true })
    }
  ),
  reportTodo: firestoreAction(
    (
      context,
      payload: {
        todoId: string
        person: boolean
        vehicle: boolean
        approved: boolean
        unsure: boolean
        lastUpdated: Date
        user: any
      }
    ) => {
      const { todoId, ...reportDetails } = payload
      const isLabeled =
        reportDetails.approved ||
        reportDetails.person ||
        reportDetails.vehicle ||
        reportDetails.unsure
      if (isLabeled) {
        return todosCollection.doc(payload.todoId).update({
          report: reportDetails,
          isLabeled: isLabeled
        })
      } else {
        return todosCollection.doc(payload.todoId).update({
          report: {},
          isLabeled: isLabeled
        })
      }
    }
  ),
  setCompleted: firestoreAction((context, payload: string) => {
    return todosCollection.doc(payload).update({
      flag: 'completed',
      completed: true
    })
  }),
  setCompletedAt: firestoreAction((context, payload: string) => {
    try {
      return todosCollection.doc(payload).update({
        completed: true,
        completedAt: firebase.firestore.Timestamp.now()
      })
    } catch (error) {
      console.error('Error in setCompletedAt', error)
      throw error
    }
  }),
  setDisagreed({ commit }: any, todo: any): void {
    todosProvider
      .setDisagreed(todo)
      .then()
      .catch((error) => {
        console.error(error)
      })
  },
  setFlag({ commit }: any, todo: any): void {
    todosProvider
      .setFlag(todo)
      .then()
      .catch((error) => {
        console.error(error)
      })
  },
  bindTodos: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef(
      'todos',
      todosCollection
        .where('completed', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(RECENT_LIMIT),
      {
        maxRefDepth: 0,
        wait: true
      }
    )
  }),
  bindTodosSelective: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef(
      'todosWorkerSpace',
      todosCollection
        .where('completed', '==', false)
        .where('aiResult.finalAiResult', '==', 'unsure')
        .where('deadline', '>', new Date())
        .orderBy('deadline', 'asc')
        .orderBy('createdAt', 'asc')
        .limit(RECENT_LIMIT),
      {
        maxRefDepth: 0,
        wait: true
      }
    )
  }),
  unbindTodos: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('todos')
  }),
  async getTodoById({ commit }: any, id: string): Promise<any> {
    try {
      const response = await todosProvider.getTodoById(id)
      return response
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  },
  async getTodosByCameraRef({ commit }: any, todo: any): Promise<any> {
    try {
      const { cameraId, fileMetadata } = todo || {}
      if (!cameraId || fileMetadata?.mediaType === 'video') {
        commit('setTodosByCameraId', [])
      } else {
        const todosByCameraId =
          await todosProvider.getLastThreeTodosByCameraRef(todo)
        commit('setTodosByCameraId', todosByCameraId.reverse())
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  },
  async fetchLastTodoByCameraRef(
    { commit }: any,
    cameraDocId: string
  ): Promise<any> {
    const todo = await todosProvider.fetchLastTodoByCameraRef(cameraDocId)
    commit('setLastTodoByCameraRef', todo)
  },
  async getDownloadUrl(
    { commit }: any,
    payload: string | { url: string; fallback?: string }
  ): Promise<string | null> {
    commit('setDownloadUrl', null)
    let ref: string | null = null
    let fallbackURL: string | undefined

    // Determine ref and fallbackURL based on payload type
    if (typeof payload === 'string') {
      ref = payload
      if (!ref) {
        console.error('No reference provided for download URL')
        commit('setDownloadUrl', null)
        return null
      }
    } else {
      ref = payload.url
      fallbackURL = payload.fallback
    }

    try {
      const url = await todosProvider.fetchDownloadUrl(ref, 5 * 1000, 2)
      commit('setDownloadUrl', url)
      return url
    } catch (error) {
      console.error('Failed to fetch download URL:', error)
      if (fallbackURL) {
        const url = await todosProvider
          .fetchDownloadUrl(fallbackURL, 5 * 1000, 2)
          .catch(() => {})
        commit('setDownloadUrl', url)
        return fallbackURL
      } else {
        commit('setDownloadUrl', null)
        return null
      }
    }
  },
  async uploadFromSandbox({ commit }: any, data: any): Promise<any> {
    const uploadFromSandbox = functions.httpsCallable('uploadFromSandbox')
    return await uploadFromSandbox(data)
  },
  async uploadTask({ commit }: any, payload: any): Promise<any> {
    commit('setUploadedTask', null)
    let uploadedTask = storage.ref().child(payload.path).put(payload.file, {
      contentType: payload.file.type
    })
    commit('setUploadedTask', uploadedTask)
  },
  async getLocalMediaUrl({ commit }: any, filePath: any) {
    commit('setDownloadUrl', null)
    const response = await todosProvider.fetchLocalMediaUrl(filePath)
    commit('setDownloadUrl', response.data.media)
  },
  async fetchZoneByIncident({ commit }: any, incidentId: string) {
    return await todosProvider.getZonesForIncident(incidentId)
  },
  async createIncidentObservationLink({ commit }: any, incidentId: string) {
    return await createIncidentObservation({ incidentId })
  }
}

export default {
  ...actions
}
