export const getDefaultState = () => {
  return {
    todos: [], // Will be bound as an array
    todosWorkerSpace: [], // Will be bound as an array
    todosByCameraId: [],
    downloadUrl: null,
    uploadedTask: null,
    currentTodoCameraRef: '',
    lastTodoByCameraRef: null,
    incidentHcLoadMoreLoading: false
  }
}

const state = getDefaultState()

export default {
  ...state
}
