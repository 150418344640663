import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import todos from './modules/todos'
import config from './modules/config'
import status from './modules/status'
import worker from './modules/worker'
import notifications from './modules/notifications'
import camera from './modules/camera'
import client from './modules/client'
import site from './modules/site'
import statistics from './modules/statistics'
import snapshot from './modules/snapshot'
import nodes from './modules/nodes'
import { vuexfireMutations } from 'vuexfire'
import get from 'lodash/get'

Vue.use(Vuex)

export const getDefaultState = () => {
  return {
    appTitle: 'promiseQ',
    error: null,
    loading: false,
    drawing_mode: '',
    snack: false,
    navigationConfig: { miniVariant: false, drawer: null },
    paginateToNext: false,
    routerHistory: []
  }
}

const state = getDefaultState()

const storeConfig = {
  plugins: [
    createPersistedState({
      paths: ['config'], // reducers to be excluded from persisting
      reducer: (state, paths) => {
        // function has overridden to filter out the reducers
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !paths.includes(key))
        )
      }
    })
  ],
  modules: {
    user,
    todos,
    config,
    status,
    worker,
    notifications,
    camera,
    statistics,
    client,
    site,
    snapshot,
    nodes
  },
  state,
  mutations: {
    setError(currentState, payload) {
      currentState.error = payload
    },
    clearError(currentState) {
      currentState.error = null
    },
    setSnack(currentState, payload) {
      currentState.snack = { ...payload }
    },
    clearSnack(currentState) {
      currentState.snack = false
    },
    setLoading(currentState, payload) {
      currentState.loading = payload
    },
    resetState(currentState) {
      Object.assign(currentState, getDefaultState())
    },
    setRouteHistory(currentState, payload) {
      if (payload.fromHistory && currentState.routerHistory.length > 0) {
        currentState.routerHistory.splice(-1, 1)
      } else {
        currentState.routerHistory.push(payload.from)

        // Keep only the latest 10 route histories
        if (currentState.routerHistory.length > 10) {
          currentState.routerHistory.splice(
            0,
            currentState.routerHistory.length - 10
          )
        }
      }
    },
    ...vuexfireMutations
  },
  actions: {
    clearError({ commit }) {
      commit('clearError')
    },
    setRouteHistory({ commit }, payload) {
      commit('setRouteHistory', payload)
    },
    clearAll({ commit }) {
      commit('user/resetState')
      commit('statistics/resetState')
      commit('client/resetState')
      commit('site/resetState')
      commit('camera/resetState')
      commit('worker/resetState')
      commit('config/resetState')
      commit('nodes/resetState')
      commit('notifications/resetState')
      commit('todos/resetState')
      commit('status/resetState')
      commit('resetState')
    }
  },
  getters: {
    error(currentState) {
      return get(currentState, 'error', null)
    },
    snack(currentState) {
      return currentState.snack
    },
    loading(currentState) {
      return currentState.loading
    },
    navigationConfig(currentState) {
      return currentState.navigationConfig
    },
    previousRoute: (state) => {
      const historyLen = state.routerHistory.length
      if (historyLen == 0) return null
      return state.routerHistory[historyLen - 1]
    }
  }
}

export const getDefaultStoreConfig = () => {
  return storeConfig
}

export const store = new Vuex.Store(storeConfig)
