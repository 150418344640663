<template>
  <v-col
    :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
      isUserAdmin ? 'col-lg-12' : 'col-lg-3'
    } col-xl-2`"
  >
    <p class="text-md-body-1 text-body-2" v-if="isLevel === 'incident'">
      Incident Received
    </p>
    <p class="text-md-body-1 text-body-2" v-if="isLevel === 'active'">
      {{ `Active ${type.charAt(0).toUpperCase() + type.slice(1)}s` }}
    </p>
    <p class="text-md-body-1 text-body-2" v-else-if="!(isLevel === 'incident')">
      {{ `${type.charAt(0).toUpperCase() + type.slice(1)}s` }}
    </p>
    <div v-if="isLoading" class="loader-wrapper">
      <v-progress-circular
        indeterminate
        color="secondary"
        size="15"
      ></v-progress-circular>
    </div>
    <p
      v-if="isLevel === 'incident' && !isLoading"
      class="text-md-h5 text-xl-h4 text-h6"
    >
      {{ this.promiseQubeData[0].count }}
    </p>
    <p
      v-else-if="isLevel === 'active' && !isLoading"
      class="bold-text text-md-h5 text-xl-h4 text-h6"
    >
      {{ this.promiseQubeData[2].count }}
    </p>
    <p
      v-else-if="!(isLevel === 'incident') && !isLoading"
      class="bold-text text-md-h5 text-xl-h4 text-h6"
    >
      {{ this.promiseQubeData[1].count }}
    </p>
  </v-col>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { Getter } from 'vuex-class'
import { debounce } from 'lodash'
import { UserLevel } from '@/utils/Constants'

const namespaceStatistics = { namespace: 'statistics' }
const namespaceSite = { namespace: 'site' }

@Component({
  components: {}
})
export default class PromiseQubeSummaryBlock extends Vue {
  @Prop() public isUserAdmin!: boolean
  @Prop() public type!: UserLevel
  @Prop() public userId?: string
  @Prop() public clientId?: string
  @Prop() public siteId?: string
  @Prop() public cameraId?: string
  @Prop() public isPromiseQube?: boolean
  @Prop() public startTime: string | Date
  @Prop() public endTime: string | Date
  @Prop() public level!: string

  @Action('fetchPromiseQubeUsageData', namespaceStatistics)
  fetchPromiseQubeUsageData

  public promiseQubeData: Array<{ count: number }> = []
  public isLoading: boolean = false

  async mounted() {
    await this.fetchPromiseQubeData()
  }

  private get combinedWatchers() {
    return {
      userId: this.userId,
      clientId: this.clientId,
      siteId: this.siteId,
      cameraId: this.cameraId
    }
  }

  @Watch('startTime')
  @Watch('endTime')
  @Watch('type')
  @Watch('combinedWatchers')
  public async fetchPromiseQubeData() {
    const { userId, clientId, siteId, cameraId } = this.$route.params
    if (userId && typeof userId !== 'string') {
      console.error('Invalid or missing userId')
      this.isLoading = false
      return
    }

    if (clientId && typeof clientId !== 'string') {
      console.error('Invalid or missing clientId')
      this.isLoading = false
      return
    }

    if (siteId && typeof siteId !== 'string') {
      console.error('Invalid siteId')
      this.isLoading = false
      return
    }

    if (cameraId && typeof cameraId !== 'string') {
      console.error('Invalid cameraId')
      this.isLoading = false
      return
    }

    this.isLoading = true
    try {
      this.promiseQubeData = await this.fetchPromiseQubeUsageData({
        startTime: this.startTime,
        endTime: this.endTime,
        userId,
        clientId,
        siteId,
        cameraId,
        userLevel: this.type
      })
    } catch (error) {
      console.error('Error fetching PromiseQube data', error)
    } finally {
      this.isLoading = false
    }
  }

  @Watch('level')
  public get isLevel() {
    return this.level
  }
}
</script>

<style>
.bold-text {
  font-size: 2.5rem;
  font-weight: bolder;
}

.incident-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 230px;
}

.installation-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 140px;
}

.summary-title {
  align-items: center;
  font-weight: bold;
}
</style>
