export enum Route {
  Alarms = 'alarms',
  Sandbox = 'sandbox',
  UserManagement = 'userManagement',
  Statistics = 'statistics',
  Live = 'live',
  Maps = 'maps',
  Configurations = 'configurations'
}

export interface InfoButtonRow {
  icon: string
  text: string
  link: string
  class: string
}

export type InfoButtonData = {
  [K in Route]: InfoButtonRow[]
}

export const infoButtonData: InfoButtonData = {
  [Route.Alarms]: [
    {
      icon: 'mdi-monitor',
      text: 'Learn more about alarms',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/incidents-dashboard',
      class: 'pr-3 black--text'
    }
  ],
  [Route.Sandbox]: [
    {
      icon: 'mdi-image-search',
      text: 'Learn more about the sandbox',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/sandbox',
      class: 'pr-3 black--text'
    }
  ],
  [Route.UserManagement]: [
    {
      icon: 'mdi-account-cog',
      text: 'Learn more about user management',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/user-management',
      class: 'pr-3 black--text'
    }
  ],
  [Route.Statistics]: [
    {
      icon: 'mdi-account-multiple',
      text: 'Learn more about statistics',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/statistics',
      class: 'pr-3 black--text'
    }
  ],
  [Route.Live]: [
    {
      icon: 'mdi-account-multiple',
      text: 'Learn more about Live',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/live',
      class: 'pr-3 black--text'
    }
  ],
  [Route.Maps]: [
    {
      icon: 'mdi-account-multiple',
      text: 'Learn more about maps',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/map',
      class: 'pr-3 black--text'
    }
  ],
  [Route.Configurations]: [
    {
      icon: 'mdi-file-upload',
      text: 'Learn more about settings',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/configurations',
      class: 'pr-3 black--text'
    }
  ]
}
