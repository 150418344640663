<template>
  <div class="card-style">
    <div class="pr-3 pl-3 py-4 form">
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            Manufacturer
          </p>
        </div>
        <div
          class="text-field"
          v-if="!isDetectedCamerasLoading"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
            border:
              isUserAdmin && isDarkModeToggleEnabled
                ? '1px solid #c4c4c4'
                : 'none',
            padding: '10px',
            borderRadius: '4px',
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          {{ manufacturer }}
        </div>
      </div>
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            Model
          </p>
        </div>
        <div
          class="text-field"
          v-if="!isDetectedCamerasLoading"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
            border:
              isUserAdmin && isDarkModeToggleEnabled
                ? '1px solid #c4c4c4'
                : 'none',
            padding: '10px',
            borderRadius: '4px',
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          {{ model }}
        </div>
      </div>
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            Firmware Version
          </p>
        </div>
        <div
          class="text-field"
          v-if="!isDetectedCamerasLoading"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
            border:
              isUserAdmin && isDarkModeToggleEnabled
                ? '1px solid #c4c4c4'
                : 'none',
            padding: '10px',
            borderRadius: '4px',
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          {{ firmwareVersion }}
        </div>
      </div>
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            IP
          </p>
        </div>
        <div
          class="text-field"
          v-if="!isDetectedCamerasLoading"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
            border:
              isUserAdmin && isDarkModeToggleEnabled
                ? '1px solid #c4c4c4'
                : 'none',
            padding: '10px',
            borderRadius: '4px',
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          {{ displayIp }}
        </div>
      </div>
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            MAC Address
          </p>
        </div>
        <div
          class="text-field"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
            border:
              isUserAdmin && isDarkModeToggleEnabled
                ? '1px solid #c4c4c4'
                : 'none',
            padding: '10px',
            borderRadius: '4px',
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          {{ macAddress }}
        </div>
      </div>

      <v-row no-gutters class="mb-4">
        <v-col cols="5">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="font-weight-bold text-left"
          >
            Configuration Type
          </p>
        </v-col>
        <v-col cols="7">
          <v-select
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="edConfigType"
            label="Select Config Type"
            :items="['Auto', 'Manual']"
            outlined
            hide-details="true"
            :dense="true"
            :disabled="!isWriteEnabled"
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <template v-if="edConfigType === 'Auto'">
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="font-weight-bold text-left"
            >
              Username
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :class="{
                'authentication-credential-error':
                  isAuthenticationCredentialError
              }"
              :loading="isAuthenticationRequestComplete"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="edgeDevice.username"
              label="Username"
              outlined
              :color="credentialErrorColor"
              hide-details="true"
              autocomplete="off"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="usernameError"
              :error="
                usernameError.length > 0 || isAuthenticationCredentialError
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="font-weight-bold text-left"
            >
              Password
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :class="{
                'authentication-credential-error':
                  isAuthenticationCredentialError
              }"
              :loading="isAuthenticationRequestComplete"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="edgeDevice.password"
              label="Password"
              outlined
              type="password"
              hide-details="true"
              autocomplete="new-password"
              :color="credentialErrorColor"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="passwordError"
              :error="
                passwordError.length > 0 || isAuthenticationCredentialError
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="6">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              class="font-weight-bold text-left"
            >
              promiseQube
              <span
                class="text-subtitle-1-disabled font-weight-bold"
                :style="{ color: '#c4c4c4' }"
              >
                {{ edgeDeviceStatus }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <v-row class="d-flex justify-end align-center">
              <v-switch
                v-model="isEdgeDeviceEnabled"
                color="secondary"
                @change="onToggleEdgeDeviceStatus"
                inset
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                :small="true"
                class="pl-2"
              ></v-switch>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="isAuthenticationCredentialError" no-gutters class="mb-4">
          <v-col cols="5"></v-col>
          <v-col cols="7" class="d-flex align-center">
            <p
              class="text-left d-flex align-center ma-0"
              :style="{ color: '#ffd42a' }"
            >
              <v-icon
                v-if="passwordError !== ''"
                :color="credentialErrorColor"
                class="mr-2"
                >mdi-alert-circle</v-icon
              >
              <span>
                {{ passwordError }}
              </span>
            </p>
          </v-col>
        </v-row>
      </template>

      <template v-if="edConfigType === 'Manual'">
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              RTSP URL
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="rtspUrl"
              label="RTSP URL"
              outlined
              color="secondary"
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="pathError"
              :error="pathError.length > 0"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <v-row
        no-gutters
        class="d-flex flex-row justify-space-between"
        v-if="isEdgeDeviceEnabled"
      >
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import { ICameraInformation } from '@/store/modules/camera/state'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceClient = { namespace: 'client' }
const namespaceCamera = { namespace: 'camera' }

enum EdgeDeviceConfigTypes {
  AUTO = 'Auto',
  MANUAL = 'Manual'
}

@Component({
  components: { Button }
})
export default class EdgeDeviceConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() onChange: (data: any) => void
  @Prop() onNavigateToLive: () => void
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() siteId!: string
  @Prop() clientId!: string
  @Prop() userId!: string
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getCameraInformation', namespaceCamera)
  public cameraInformation!: ICameraInformation | null

  @Action('getClientDefaultKey', namespaceClient)
  public getClientDefaultKey: (payload: {
    userId: string
    clientId: string
  }) => Promise<string>
  @Action('bindCameraInformation', namespaceCamera)
  private bindCameraInformation!: (arg0: { cameraId: string }) => Promise<void>
  @Action('unbindCameraInformation', namespaceCamera)
  private unbindCameraInformation!: () => Promise<void>
  public edConfigType: EdgeDeviceConfigTypes = EdgeDeviceConfigTypes.AUTO
  public isExpanded: boolean = false

  public isEdgeDeviceSaving = false
  public edgeDeviceStatus: string = ''

  public macAddress: string = 'Not Available'
  public firmwareVersion: string = 'Not Available'
  public model: string = 'Not Available'
  public manufacturer: string = 'Not Available'
  public isEdgeDeviceEnabled: boolean = false
  public rtspUrl = ''
  public edgeDevice = {
    ip: 'No IP detected',
    path: '',
    username: '',
    password: ''
  }

  public pathError: string = ''
  public nameError: string = ''
  public usernameError: string = ''
  public passwordError: string = ''
  public credentialErrorColor: string = '#ffd42a'

  public detectedCameraIp: string = ''
  public detectedCameras: any = []
  public isDetectedCamerasLoading: boolean = false
  public isAuthenticationCredentialError: boolean = false
  public isAuthenticationRequestComplete: boolean = false

  public originalEdgeDeviceData: any = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public mounted() {
    this.watchConfiguration()
  }

  public beforeDestroy() {
    this.unbindCameraInformation()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  @Watch('cameraInformation')
  public watchCameraInformation() {
    this.macAddress = this.cameraInformation?.macAddress ?? 'Not available'
    this.firmwareVersion =
      this.cameraInformation?.firmwareVersion ?? 'Not available'
    this.model = this.cameraInformation?.model ?? 'Not available'
    this.manufacturer = this.cameraInformation?.manufacturer ?? 'Not available'
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.isEdgeDeviceEnabled =
        this.configuration?.isEdgeDeviceEnabled ?? false
      this.isExpanded = this.isEdgeDeviceEnabled
      this.rtspUrl = this.configuration?.edgeDevice?.path ?? ''
      if (this.rtspUrl) {
        this.edConfigType = EdgeDeviceConfigTypes.MANUAL
      }
      this.edgeDeviceStatus = this.configuration?.isEdgeDeviceEnabled
        ? 'on'
        : 'off'
      this.bindCameraInformation({ cameraId: this.configuration.id })

      this.edgeDevice = {
        password: this.configuration?.edgeDevice?.password ?? '',
        username: this.configuration?.edgeDevice?.username ?? '',
        ip: this.configuration?.edgeDevice?.ip ?? '',
        path: this.configuration?.edgeDevice?.path ?? ''
      }
    }

    this.originalEdgeDeviceData = {
      isEdgeDeviceEnabled: this.isEdgeDeviceEnabled,
      rtspUrl: this.configuration.edgeDevice?.path ?? '',
      password: this.configuration.edgeDevice?.password ?? '',
      username: this.configuration.edgeDevice?.username ?? ''
    }
    this.handleAuthenticationResponse()
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isEdgeDeviceEnabled !==
      this.originalEdgeDeviceData.isEdgeDeviceEnabled
    )
  }

  public get displayIp(): string {
    return this.edgeDevice.ip.trim() || 'No IP detected'
  }

  public onToggleEdgeDeviceStatus(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public hasDataChanged(): boolean {
    return this.rtspUrl?.trim() !== this.originalEdgeDeviceData?.rtspUrl
  }

  public haAutoDataChanged(): boolean {
    return (
      this.edgeDevice?.username?.trim() !==
        this.originalEdgeDeviceData?.username ||
      this.edgeDevice?.password?.trim() !==
        this.originalEdgeDeviceData?.password
    )
  }

  @Watch('rtspUrl')
  @Watch('isEdgeDeviceEnabled')
  @Watch('edgeDevice.password')
  @Watch('edgeDevice.username')
  public async onClickEdgeDeviceConfig() {
    if (this.edConfigType === EdgeDeviceConfigTypes.MANUAL) {
      let isError: boolean = false
      this.pathError = ''
      this.nameError = ''

      let edgeDeviceData = null

      if (!this.rtspUrl?.trim()) {
        isError = true
        this.pathError = 'Please add RTSP URL'
      }

      edgeDeviceData = {
        path: this.rtspUrl.trim(),
        password: '',
        username: '',
        ip: '',
        type: 'WEB',
        isError,
        isChanged: isError ? false : this.hasDataChanged()
      }

      await this.onChange(edgeDeviceData)
    } else {
      let isError: boolean = false
      this.usernameError = ''
      this.passwordError = ''

      let edgeDeviceData = null

      if (!this.edgeDevice?.username?.trim()) {
        isError = true
        this.usernameError = 'Please add username'
      }

      if (!this.edgeDevice?.password?.trim()) {
        isError = true
        this.passwordError = 'Please add password'
      }

      if (this.isAuthenticationCredentialError) {
        isError = true
        this.passwordError = 'Incorrect credentials. Please try again.'
      }

      edgeDeviceData = {
        path: '',
        password: this.edgeDevice.password?.trim(),
        username: this.edgeDevice.username?.trim(),
        ip: this.edgeDevice.ip?.trim(),
        type: 'WEB',
        isError,
        isChanged: this.haAutoDataChanged()
      }

      await this.onChange(edgeDeviceData)
    }
  }

  public onClickRouteToLiveCamera() {
    this.onNavigateToLive()
  }

  @Watch('isAuthenticationCredentialError')
  public watchIsAuthenticationCredentialError() {
    if (this.isAuthenticationCredentialError) {
      this.passwordError = 'Incorrect credentials. Please try again.'
    } else {
      this.passwordError = ''
    }
  }

  private handleAuthenticationResponse() {
    if (this.configuration?.isAuthenticationRequestComplete !== undefined) {
      this.isAuthenticationRequestComplete =
        !this.configuration?.isAuthenticationRequestComplete
    }
    if (
      !this.configuration?.isAuthenticated &&
      this.configuration?.isAuthenticationRequestComplete
    ) {
      this.isAuthenticationCredentialError = true
    } else {
      this.isAuthenticationCredentialError = false
    }
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.v-text-field.error--text.authentication-credential-error /deep/ fieldset {
  border-color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error /deep/ .v-label {
  color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error /deep/ input {
  caret-color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error
  /deep/
  .v-messages__message {
  color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error
  /deep/
  input::-webkit-textfield-decoration-container {
  caret-color: #ffd42b !important;
}

.form-item {
  margin-bottom: 20px;
}

.text-field {
  text-align: left;
  font-size: 15px;
  margin-top: -10px;
}
</style>
