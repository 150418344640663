import { DocumentReference } from '@firebase/firestore-types'
import { HttpsCallableResult } from '@firebase/functions-types'
import firebase from 'firebase/compat/app'
import {
  cameraCollection,
  clientCollection,
  clientProvider,
  siteCollection,
  userCollection
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import { store } from '@/store'
import { ClientModifiedConfiguration } from 'configuration'
import { DISARMED_TIME_RANGE } from '@/utils/Constants'

const actions = {
  bindClientUsers: async (
    { commit, state }: any,
    payload: { userId: string; userIds: string[] }
  ) => {
    commit('user/setIsLoadingUsers', true, { root: true })
    const readUsers = state.clientConfig.readUsers ?? []
    const writeUsers = state.clientConfig.writeUsers ?? []
    const filteredUserIds = payload.userIds.filter(
      (userId) => userId !== payload.userId && !readUsers.includes(userId)
    )
    const users = await Promise.all(
      filteredUserIds?.map(async (userId) => {
        const userSnap = await userCollection.doc(userId).get()
        return {
          ...userSnap.data(),
          isWriteEnabled: writeUsers.includes(userId),
          isOverrideEnabled: true
        }
      })
    )
    commit('setClientUsers', users)
    commit('user/setIsLoadingUsers', false, { root: true })
  },
  bindClient: firestoreAction(
    async (
      { state, bindFirestoreRef, commit }: any,
      payload: { clientId: string; userId: string }
    ) => {
      store.commit('config/setIsNotFound', false)
      const clientSnap = await clientCollection
        .where('clientId', '==', payload.clientId)
        .get()

      if (clientSnap.empty) {
        store.commit('config/setIsNotFound', true)
        return
      }

      return bindFirestoreRef('clientConfig', clientSnap.docs.at(0).ref, {
        wait: true,
        serialize: (doc: { data: () => any; id: any }) => {
          const users = doc.data().users ?? []
          const writeUsers = doc.data().writeUsers ?? []
          const readUsers = doc.data().readUsers ?? []
          return {
            ...doc.data(),
            id: doc.id,
            isDefault: doc.data()?.type === 'Default',
            isWriteEnabled: writeUsers.includes(payload.userId),
            isAccessEnabled:
              !readUsers.includes(payload.userId) &&
              users.includes(payload.userId),
            notificationData: {}
          }
        }
      })
    }
  ),
  unbindClient: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('clientConfig')
  }),
  bindClientCameras: async (
    { commit }: any,
    payload: {
      userId: string
      clientId: string
    }
  ) => {
    commit('setClientLiveIsLoading', true)
    commit('setClientCamera', [])
    let cameraArray = []

    const clientSnapshot = await clientCollection
      .where('clientId', '==', payload.clientId)
      .where('users', 'array-contains', payload.userId)
      .get()

    await Promise.all(
      clientSnapshot.docs.map(async (client) => {
        const clientRef = client.ref
        const clientData = client.data()

        const siteSnapshot = await siteCollection
          .where('client', '==', clientRef)
          .where('users', 'array-contains', payload.userId)
          .get()

        await Promise.all(
          siteSnapshot.docs.map(async (site) => {
            const siteRef = site.ref
            const siteData = site.data()
            const cameraSnapshot = await cameraCollection
              .where('site', '==', siteRef)
              .where('users', 'array-contains', payload.userId)
              .get()

            cameraSnapshot.docs.forEach((camera) => {
              const cameraData = camera.data()
              const cameraItem = {
                key: camera.id,
                id: camera.id,
                itemId: cameraData.cameraId,
                siteId: siteData.siteId,
                clientId: clientData.clientId,
                cameraId: cameraData.cameraId,
                name: cameraData.name,
                type: 'camera',
                userFirestoreId: payload.userId,
                clientFirestoreId: client.id,
                siteFirestoreId: site.id,
                cameraFirestoreId: camera.id,
                liveStream: cameraData.liveStream,
                siteName: siteData.name,
                clientName: clientData.name,
                edgeDevice: cameraData?.edgeDevice,
                edgeLiveStreamLimit: siteData?.edgeLimits?.liveStreamingLimit,
                isEdgeCameraUnarmed: cameraData?.isEdgeCameraUnarmed ?? false,
                isAuthenticated:
                  cameraData.isAuthenticated !== null
                    ? cameraData.isAuthenticated
                    : true,
                location: cameraData.location,
                isLiveStreamEnabled: cameraData.isLiveStreamEnabled ?? false,
                isEdgeDeviceEnabled:
                  cameraData.isEdgeDeviceEnabled !== null
                    ? cameraData.isEdgeDeviceEnabled
                    : false,
                isSiteHardwareDevice: siteData.isHardwareDevice,
                referenceImage: cameraData.referenceImage
              }

              cameraArray.push(cameraItem)
            })
          })
        )
      })
    )

    commit('setClientCamera', cameraArray)
    commit('setClientLiveIsLoading', false)
  },
  async updateClientConfig(
    { commit, dispatch }: any,
    payload: {
      docId: string
      config: string
      updatedClientData: ClientModifiedConfiguration
      value: string
      updatedFields: string[]
    }
  ) {
    if (payload?.updatedFields.includes('Client Information')) {
      if (payload.updatedClientData.clientInformation) {
        const clientData = {
          name: payload.updatedClientData.clientInformation.clientName,
          isQuotaEnabled:
            payload.updatedClientData.clientInformation.isQuotaEnabled,
          alarmQuota: payload.updatedClientData.clientInformation.alarmQuota,
          quotaAlertPercentage:
            payload.updatedClientData.clientInformation.quotaAlertPercentage
        }
        await clientCollection.doc(payload.docId).update(clientData)
      }
    }
    if (payload?.updatedFields.includes('Alarm Definition')) {
      await dispatch('updateAlarmDefinitionConfig', {
        clientId: payload.docId,
        alarmDefinitionConfig:
          payload.updatedClientData.alarmSettings.alarmDefinition
      })
    }
    if (payload?.updatedFields.includes('Contact')) {
      const contact = {
        contactName: payload.updatedClientData.contact.contactPerson,
        countryCallingCode:
          payload.updatedClientData.contact.contactNumber.countryCallingCode,
        countryCode:
          payload.updatedClientData.contact.contactNumber.countryCode,
        isCallEnabled:
          payload.updatedClientData.contact.receiveCallNotification,
        isSmsEnabled: payload.updatedClientData.contact.receiveSmsNotification,
        message: payload.updatedClientData.contact.contactMessage,
        phoneNumber: payload.updatedClientData.contact.contactNumber.phoneNumber
      }

      await dispatch('updateContact', {
        clientId: payload.docId,
        contact
      })
    }
    if (payload?.updatedFields.includes('Hikvision Integration')) {
      const hikCentralData = {
        appKey: payload.updatedClientData.hikVisionHCP.partnerAppKey,
        appSecret: payload.updatedClientData.hikVisionHCP.partnerAppSecret,
        hikCentralVersion:
          payload.updatedClientData.hikVisionHCP.hikVisionVersion,

        serviceUrl: payload.updatedClientData.hikVisionHCP.serviceUrl,
        isAcknowledgementEnabled:
          payload.updatedClientData.hikVisionHCP.automaticEventAcknowledgement
      }

      await dispatch('updateHikCentralConfig', {
        clientId: payload.docId,
        hikCentralData
      })

      if (payload.updatedClientData.hikVisionHCP.onHikvision != null) {
        await dispatch('updateHikCentralNotificationEnabledStatus', {
          clientId: payload.docId,
          isHikCentralEnabled:
            payload.updatedClientData.hikVisionHCP.onHikvision
        })
      }
    }
    if (payload?.updatedFields.includes('Human Review')) {
      await dispatch('updateHumanReviewStatus', {
        clientId: payload.docId,
        isHumanReviewEnabled:
          payload.updatedClientData.alarmSettings.humanReview
      })
    }
    if (payload?.updatedFields.includes('Genetec Integration')) {
      const genetecData = {
        applicationId: payload.updatedClientData.genetec.applicationId,
        password: payload.updatedClientData.genetec.password,
        isAcknowledgementEnabled:
          payload.updatedClientData.genetec.automaticEventAcknowledgement,
        serviceUrl: payload.updatedClientData.genetec.serviceUrl,
        username: payload.updatedClientData.genetec.username
      }
      await dispatch('updateGenetecConfig', {
        clientId: payload.docId,
        genetecData
      })

      if (payload.updatedClientData.genetec.onGenetec != null) {
        await dispatch('updateGenetecEnabledStatus', {
          clientId: payload.docId,
          isGenetecEnabled: payload.updatedClientData.genetec.onGenetec
        })
      }
      if (
        payload.updatedClientData.genetec.automaticEventAcknowledgement != null
      ) {
        await dispatch('updateGenetecAcknowledgementEnabledStatus', {
          clientId: payload.docId,
          isAcknowledgementEnabled:
            payload.updatedClientData.genetec.automaticEventAcknowledgement
        })
      }
    }

    if (
      payload?.updatedFields.includes('Email Notifications') ||
      payload?.updatedFields.includes('Email Notifications Toggle')
    ) {
      if (payload.updatedClientData.notifications.alarm.email.receivingEmails) {
        await dispatch('updateNotificationEmails', {
          clientId: payload.docId,
          notificationEmails:
            payload.updatedClientData.notifications.alarm.email.receivingEmails
        })
      }
      if (
        typeof payload.updatedClientData.notifications.alarm.email.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEmailNotificationEnabledStatus', {
          clientId: payload.docId,
          isEmailNotificationEnabled:
            payload.updatedClientData.notifications.alarm.email.isOn
        })
      }
    }
    if (
      payload?.updatedFields.includes('Sia Notifications') ||
      payload?.updatedFields.includes('Sia Notifications Toggle')
    ) {
      if (
        typeof payload.updatedClientData.notifications.alarm.siaDc09.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsSiaNotificationEnabledStatus', {
          clientId: payload.docId,
          isSiaEnabled:
            payload.updatedClientData.notifications.alarm.siaDc09.isOn
        })
      }
      if (
        payload.updatedClientData.notifications.alarm.siaDc09
          .notifySubFolderAccountNumber != null
      ) {
        await dispatch('updateIsRelatedSiaAccountNotifiedStatus', {
          clientId: payload.docId,
          isRelatedSiaAccountNotified:
            payload.updatedClientData.notifications.alarm.siaDc09
              .notifySubFolderAccountNumber
        })
      }
      if (
        payload.updatedClientData.notifications.alarm.siaDc09
          .sendSiaHeartbeatMessage != null
      ) {
        await dispatch('updateIsSiaHeartbeatEnabledStatus', {
          clientId: payload.docId,
          isSiaHeartbeatEnabled:
            payload.updatedClientData.notifications.alarm.siaDc09
              .sendSiaHeartbeatMessage
        })
      }
      if (
        payload.updatedClientData.notifications.alarm.siaDc09
          .sendSiaRoutineMessage != null
      ) {
        await dispatch('updateIsSiaRoutineMessageEnabledStatus', {
          clientId: payload.docId,
          isSiaRoutineMessageEnabled:
            payload.updatedClientData.notifications.alarm.siaDc09
              .sendSiaRoutineMessage
        })
      }
      const siaData = {
        siaIp: payload.updatedClientData.notifications.alarm.siaDc09.ipAddress,
        siaPort: payload.updatedClientData.notifications.alarm.siaDc09.port
      }
      await dispatch('updateSiaConfig', {
        clientId: payload.docId,
        siaData
      })
    }
    if (payload?.updatedFields.includes('Webhook Notifications')) {
      const webhookTriggerData = {
        method:
          payload.updatedClientData.notifications.alarm.webhook.webhookArray
            .method,
        name: payload.updatedClientData.notifications.alarm.webhook.webhookArray
          .name,
        url: payload.updatedClientData.notifications.alarm.webhook.webhookArray
          .url
      }
      if (
        payload.updatedClientData.notifications.alarm.webhook.webhookArray
          .urlParams
      ) {
        webhookTriggerData['urlParams'] =
          payload.updatedClientData.notifications.alarm.webhook.webhookArray.urlParams
      }

      if (
        payload.updatedClientData.notifications.alarm.webhook.webhookArray
          .headerParams
      ) {
        webhookTriggerData['headerParams'] =
          payload.updatedClientData.notifications.alarm.webhook.webhookArray.headerParams
      }

      // Add content to webhookTriggerData if it exists
      if (
        payload.updatedClientData.notifications.alarm.webhook.webhookArray
          .content
      ) {
        webhookTriggerData['content'] =
          payload.updatedClientData.notifications.alarm.webhook.webhookArray.content
      }
      await dispatch('addWebhookTriggerConfig', {
        clientId: payload.docId,
        webhookTriggerData
      })

      const docRef = clientCollection.doc(payload.docId)
      const doc = await docRef.get()

      if (doc.exists) {
        const data = doc.data()

        // Check if webhookTriggerData and urlParams exist
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.urlParams &&
          webhookTriggerData['urlParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            clientId: payload.docId,
            webhookTriggerData: { urlParams: webhookTriggerData['urlParams'] }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            clientId: payload.docId,
            webhookTriggerData: { urlParams: {} }
          })
        }
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.headerParams &&
          webhookTriggerData['headerParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            clientId: payload.docId,
            webhookTriggerData: {
              headerParams: webhookTriggerData['headerParams']
            }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            clientId: payload.docId,
            webhookTriggerData: { headerParams: {} }
          })
        }
      }

      if (
        typeof payload.updatedClientData.notifications.alarm.webhook.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsWebhookTriggerEnabledStatus', {
          clientId: payload.docId,
          isWebhookTriggerEnabled:
            payload.updatedClientData.notifications.alarm.webhook.isOn
        })
      }
    }
    if (payload?.updatedFields.includes('FTP Notifications')) {
      if (
        typeof payload.updatedClientData.notifications.alarm.ftp.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsFTPNotificationEnabledStatus', {
          clientId: payload.docId,
          isFTPEnabled: payload.updatedClientData.notifications.alarm.ftp.isOn
        })
      }
      const ftpData = {
        filePath: payload.updatedClientData.notifications.alarm.ftp.filePath,
        host: payload.updatedClientData.notifications.alarm.ftp.host,
        password: payload.updatedClientData.notifications.alarm.ftp.password,
        port: payload.updatedClientData.notifications.alarm.ftp.port,
        user: payload.updatedClientData.notifications.alarm.ftp.username,
        isUploadImageSequence:
          payload.updatedClientData.notifications.alarm.ftp
            .isUploadImageSequence,
        isFtpPathEnabled:
          payload.updatedClientData.notifications.alarm.ftp.isFtpPathEnabled
      }
      await dispatch('updateFTPConfig', {
        clientId: payload.docId,
        ftpData
      })
    }
    if (payload?.updatedFields.includes('SMTP Notifications')) {
      if (
        typeof payload.updatedClientData.notifications.alarm.smtp.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsSMTPNotificationEnabledStatus', {
          clientId: payload.docId,
          isSMTPNotificationEnabled:
            payload.updatedClientData.notifications.alarm.smtp.isOn
        })
      }
      const smtpNotificationData = {
        receiverAddress:
          payload.updatedClientData.notifications.alarm.smtp
            .receiverEmailAddress,
        host: payload.updatedClientData.notifications.alarm.smtp.host,
        password: payload.updatedClientData.notifications.alarm.smtp.password,
        port: payload.updatedClientData.notifications.alarm.smtp.port,
        user: payload.updatedClientData.notifications.alarm.smtp.username
      }
      await dispatch('updateSMTPConfig', {
        clientId: payload.docId,
        smtpNotificationData
      })
    }
    if (
      payload?.updatedFields.includes('Evalink Notifications') ||
      payload?.updatedFields.includes('Evalink Notifications Toggle')
    ) {
      if (
        typeof payload.updatedClientData.notifications.alarm.evalink.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEvalinkNotificationEnabledStatus', {
          clientId: payload.docId,
          isEvalinkEnabled:
            payload.updatedClientData.notifications.alarm.evalink.isOn
        })
      }
      const evalinkData = {
        alarmZoneName:
          payload.updatedClientData.notifications.alarm.evalink.alarmZoneName,
        Authorization:
          payload.updatedClientData.notifications.alarm.evalink
            .authorizationKey,
        companyId:
          payload.updatedClientData.notifications.alarm.evalink.companyId,
        deviceId:
          payload.updatedClientData.notifications.alarm.evalink.deviceId,
        isDeviceOverrideEnabled:
          payload.updatedClientData.notifications.alarm.evalink
            .deviceOverride || false,
        partition:
          payload.updatedClientData.notifications.alarm.evalink.partition,
        partitionName:
          payload.updatedClientData.notifications.alarm.evalink.partitionName
      }
      await dispatch('updateEvalinkConfig', {
        clientId: payload.docId,
        evalinkData
      })
    }

    if (payload?.updatedFields.includes(DISARMED_TIME_RANGE)) {
      const unarmedTimeRange = {
        applicableDays:
          payload.updatedClientData.unarmedTimeRange.applicationDays,
        endTime: payload.updatedClientData.unarmedTimeRange.endTime,
        isAlwaysUnarmed:
          payload.updatedClientData.unarmedTimeRange.alwaysUnarmed || false,
        selectedTimeZone: payload.updatedClientData.unarmedTimeRange.timeZone,
        startTime: payload.updatedClientData.unarmedTimeRange.startTime
      }
      await dispatch('updateUnarmedTimeRange', {
        clientId: payload.docId,
        unarmedTimeRange
      })
    }

    if (payload?.updatedFields.includes('Smtp Integration')) {
      const smtpIntegrationData = payload.updatedClientData.smtpIntegrationData
      if (typeof smtpIntegrationData.isOn === 'boolean') {
        await dispatch('updateSmtpEnabledStatus', {
          clientId: payload.docId,
          isSmtpEnabled: smtpIntegrationData.isOn
        })
      }
      const smtpData = {
        subjectRegexPattern: smtpIntegrationData.subjectRegexPattern,
        bodyRegexPattern: smtpIntegrationData.bodyRegexPattern,
        exampleEmailSubject: smtpIntegrationData.exampleEmailSubject,
        exampleEmailBody: smtpIntegrationData.exampleEmailBody,
        selectedSiteRegexPart: smtpIntegrationData.selectedSiteRegexPart,
        selectedCameraRegexPart: smtpIntegrationData.selectedCameraRegexPart
      }
      await dispatch('updateSmtpIntegrationData', {
        clientId: payload.docId,
        smtpData: smtpData
      })
    }

    if (!payload.docId || !payload.config || !payload.value) {
      throw new Error('Missing payload')
    }
  },
  updateSmtpIntegrationData: firestoreAction(
    (
      { state }: any,
      payload: {
        clientId: string
        smtpData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        smtpData: payload.smtpData
      })
    }
  ),
  updateSmtpEnabledStatus: firestoreAction(
    (
      { state }: any,
      payload: {
        clientId: string
        isSmtpEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        isSmtpEnabled: payload.isSmtpEnabled
      })
    }
  ),
  updateHumanReviewStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isHumanReviewEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        isAskCrowdWorkers: payload.isHumanReviewEnabled
      })
    }
  ),
  updateHikCentralNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isHikCentralEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        isHikCentralEnabled: payload.isHikCentralEnabled
      })
    }
  ),
  updateHikCentralConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        hikCentralData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        hikCentralData: payload.hikCentralData
      })
    }
  ),
  updateGenetecEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isGenetecEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        isGenetecEnabled: payload.isGenetecEnabled
      })
    }
  ),
  updateGenetecConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        genetecData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        genetecData: payload.genetecData
      })
    }
  ),
  updateGenetecAcknowledgementEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isAcknowledgementEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        'genetecData.isAcknowledgementEnabled': payload.isAcknowledgementEnabled
      })
    }
  ),
  updateIsEmailNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isEmailNotificationEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isEmailNotificationEnabled: payload.isEmailNotificationEnabled
        },
        { merge: true }
      )
    }
  ),
  updateNotificationEmails: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        notificationEmails: { email: string }[]
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          notificationEmails: payload.notificationEmails
        },
        { merge: true }
      )
    }
  ),
  updateKeys: firestoreAction(
    async (
      context,
      payload: {
        clientId: string
        key: string
      }
    ) => {
      if (
        (await clientCollection.doc(payload.clientId).get()).data().defaultKey
      ) {
        return clientCollection.doc(payload.clientId).update({
          keys: firebase.firestore.FieldValue.arrayUnion(payload.key)
        })
      } else {
        return clientCollection.doc(payload.clientId).update({
          defaultKey: payload.key,
          keys: firebase.firestore.FieldValue.arrayUnion(payload.key)
        })
      }
    }
  ),
  removeKey: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        key: string
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        keys: firebase.firestore.FieldValue.arrayRemove(payload.key)
      })
    }
  ),
  updateIsWebhookTriggerEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isWebhookTriggerEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isWebhookTriggerEnabled: payload.isWebhookTriggerEnabled
        },
        { merge: true }
      )
    }
  ),
  addWebhookTriggerConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        webhookTriggerData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          webhookTriggerData: payload.webhookTriggerData
        },
        { merge: true }
      )
    }
  ),
  updateWebhookTriggerConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        webhookTriggerData: any
      }
    ) => {
      // Update only urlParams if it exists in the payload
      const updateData = {}
      if (payload.webhookTriggerData.urlParams) {
        updateData['webhookTriggerData.urlParams'] =
          payload.webhookTriggerData.urlParams
      }
      if (payload.webhookTriggerData.headerParams) {
        updateData['webhookTriggerData.headerParams'] =
          payload.webhookTriggerData.headerParams
      }

      return clientCollection.doc(payload.clientId).update(updateData)
    }
  ),
  bindClientOwner: firestoreAction(
    async ({ state, bindFirestoreRef, commit }: any, payload: any) => {
      let userDocRef: DocumentReference
      const user = await userCollection.doc(payload).get()

      if (user.exists) {
        userDocRef = userCollection.doc(payload)
      } else {
        console.log('User does not exists')
      }
      return bindFirestoreRef('clientOwner', userDocRef, {
        maxRefDepth: 0,
        wait: true
      })
    }
  ),
  updateIsFTPNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isFTPEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isFTPNotificationsEnabled: payload.isFTPEnabled
        },
        { merge: true }
      )
    }
  ),
  updateFTPConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        ftpData: any
      }
    ) => {
      // Remove undefined fields from ftpData object
      const filteredFTPData = Object.fromEntries(
        Object.entries(payload.ftpData).map(([key, value]) => [
          key,
          value !== undefined ? value : ''
        ])
      )
      return clientCollection.doc(payload.clientId).set(
        {
          ftpData: filteredFTPData
        },
        { merge: true }
      )
    }
  ),
  updateIsEvalinkNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isEvalinkEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isEvalinkEnabled: payload.isEvalinkEnabled
        },
        { merge: true }
      )
    }
  ),
  updateEvalinkConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        evalinkData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          evalinkData: payload.evalinkData
        },
        { merge: true }
      )
    }
  ),
  updateSiaIntegration(
    context,
    payload: {
      clientId: string
      data: any
    }
  ): Promise<HttpsCallableResult> {
    return clientProvider.generateSiaReceiver(payload.clientId, payload.data)
  },
  updateSiaConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        siaData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          siaData: payload.siaData
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isSiaEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isSiaEnabled: payload.isSiaEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaHeartbeatEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isSiaHeartbeatEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isSiaHeartbeatEnabled: payload.isSiaHeartbeatEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsRelatedSiaAccountNotifiedStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isRelatedSiaAccountNotified: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isRelatedSiaAccountNotified: payload.isRelatedSiaAccountNotified
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaRoutineMessageEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isSiaRoutineMessageEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isSiaRoutineMessageEnabled: payload.isSiaRoutineMessageEnabled
        },
        { merge: true }
      )
    }
  ),
  updateAlarmDefinitionConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        alarmDefinitionConfig: string
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        alarmDefinitionConfig: payload.alarmDefinitionConfig
      })
    }
  ),
  updateUnarmedTimeRange: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        unarmedTimeRange: object
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        unarmedTimeRange: payload.unarmedTimeRange
      })
    }
  ),
  updateContact: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        contact: object
      }
    ) => {
      return clientCollection.doc(payload.clientId).update({
        contact: payload.contact
      })
    }
  ),
  deleteClientUser: firestoreAction(
    async (
      context,
      payload: {
        clientId: string
        checkedUser: any[]
      }
    ) => {
      const { clientId, checkedUser } = payload

      const clientRef = await clientCollection
        .where('clientId', '==', clientId)
        .get()
      const clientDoc = clientRef.docs[0].id

      const userIdsToRemove = checkedUser.map((user) => user.id)

      return clientCollection.doc(clientDoc).update({
        clientPermissionUserIds: firebase.firestore.FieldValue.arrayRemove(
          ...userIdsToRemove
        )
      })
    }
  ),
  updateIsSMTPNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        isSMTPNotificationEnabled: boolean
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          isSMTPNotificationEnabled: payload.isSMTPNotificationEnabled
        },
        { merge: true }
      )
    }
  ),
  updateSMTPConfig: firestoreAction(
    (
      context,
      payload: {
        clientId: string
        smtpNotificationData: any
      }
    ) => {
      return clientCollection.doc(payload.clientId).set(
        {
          smtpNotificationData: payload.smtpNotificationData
        },
        { merge: true }
      )
    }
  ),
  async getClientDefaultKey(
    context,
    payload: {
      clientId: string
      userId: string
    }
  ): Promise<string> {
    const clientSnap = await clientCollection
      .where('clientId', '==', payload.clientId)
      .where('users', 'array-contains', payload.userId)
      .get()

    if (!clientSnap.empty) {
      return clientSnap.docs.at(0).data().defaultKey
    } else {
      const defaultClientSnap = await clientCollection
        .where('users', 'array-contains', payload.userId)
        .where('type', '==', 'Default')
        .get()

      return defaultClientSnap.docs.at(0)?.data()?.defaultKey
    }
  },
  async getClientUserId(
    context,
    payload: {
      subscriptionKey: string
    }
  ): Promise<string | null> {
    const clientSnap = await clientCollection
      .where('keys', 'array-contains', payload.subscriptionKey)
      .get()
    if (!clientSnap.empty) {
      const users = clientSnap.docs[0].data().users
      if (users) {
        return users[0]
      }
    }
    return null
  }
}

export default {
  ...actions
}
