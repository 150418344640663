<template>
  <div class="pt-10 pl-10"></div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ProgressBar from '../ProgressBar.vue'
import Button from '@/components/app/Button.vue'
import moment from 'moment'
import WebhookParams from '@/components/events/WebhookParams.vue'
import FtpNotificationConfiguration from './FtpNotificationConfiguration.vue'
import AlarmDefinitionConfiguration from './AlarmDefinitionConfiguration.vue'
import UnarmedTimeRangeConfiguration from './UnarmedTimeRangeConfiguration.vue'
import HumanReviewConfiguration from './HumanReviewConfiguration.vue'
import EmailNotificationConfiguration from './EmailNotificationConfiguration.vue'
import SiaNotificationConfiguration from './SiaNotificationConfiguration.vue'
import WebhookNotificationConfiguration from './WebhookNotificationConfiguration.vue'
import EvalinkNotificationConfiguration from './EvalinkNotificationConfiguration.vue'
import GenetecConfiguration from './GenetecConfiguration.vue'
import EagleEyeConfiguration from './EagleEyeConfiguration.vue'
import HikVisionConfiguration from './HikVisionConfiguration.vue'
import BlockHeader from './BlockHeader.vue'
import InfoButton from '@/views/InfoButton.vue'
import Contact from './Contact.vue'
import { userCollection } from '@/provider/firebase'
import SmtpNotificationConfiguration from './SmtpNotificationConfiguration.vue'

const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    ProgressBar,
    Button,
    WebhookParams,
    AlarmDefinitionConfiguration,
    UnarmedTimeRangeConfiguration,
    FtpNotificationConfiguration,
    HumanReviewConfiguration,
    EmailNotificationConfiguration,
    SiaNotificationConfiguration,
    WebhookNotificationConfiguration,
    EvalinkNotificationConfiguration,
    HikVisionConfiguration,
    GenetecConfiguration,
    EagleEyeConfiguration,
    Contact,
    InfoButton,
    BlockHeader,
    SmtpNotificationConfiguration
  }
})
export default class AllClientConfiguration extends Vue {}
</script>

<style scoped>
.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-component-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid #dadada 1.5px;
  border-right: solid #dadada 1.5px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

/* To have the same border color of v-text-field in focus and unfocused state */
.form .v-text-field--outlined >>> fieldset {
  border-color: #ffd42a;
}

::v-deep .checkbox .v-label,
.v-subheader {
  font-size: 1.1rem !important;
  font-weight: 500;
  color: #000;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}
.receiving-email-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu-icon {
  float: right;
  padding-left: 10px;
  color: #000;
  bottom: -3px;
}
.ai-human-ind {
  text-align: left;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 12px;
}

@media only screen and (min-width: 1900px) {
  .container-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>
