var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      { active: _vm.isExpanded },
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],attrs:{"data-test-id":"webhook-expand-bar"},on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"webhook-expand-title"}},[_vm._v(" Webhook ")]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"form pr-3 py-4 pl-3"},[_c('v-text-field',{attrs:{"label":"Name","outlined":"","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"error-messages":_vm.wtNameError,"error":_vm.wtNameError.length > 0,"data-test-id":"webhook-config-name-input"},model:{value:(_vm.wtName),callback:function ($$v) {_vm.wtName=$$v},expression:"wtName"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"URL","outlined":"","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"error-messages":_vm.wtUrlError,"error":_vm.wtUrlError.length > 0,"data-test-id":"webhook-config-url-input"},model:{value:(_vm.wtUrl),callback:function ($$v) {_vm.wtUrl=$$v},expression:"wtUrl"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.methodList,"outlined":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"webhook-config-method-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1732385462),model:{value:(_vm.selectedWtMethod),callback:function ($$v) {_vm.selectedWtMethod=$$v},expression:"selectedWtMethod"}})],1)],1),_c('v-tabs',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"background-color":"transparent"},model:{value:(_vm.wtTabs),callback:function ($$v) {_vm.wtTabs=$$v},expression:"wtTabs"}},[_c('v-tab',{attrs:{"data-test-id":"webhook-config-url-params-tab"}},[_vm._v("URL PARAMETERS")]),_c('v-tab',{attrs:{"data-test-id":"webhook-config-headers-tab"}},[_vm._v("HEADERS")]),(!(_vm.selectedWtMethod === 'GET' || _vm.selectedWtMethod === 'DELETE'))?_c('v-tab',{attrs:{"data-test-id":"webhook-config-content-tab"}},[_vm._v("Content")]):_vm._e()],1),_c('v-tabs-items',{staticClass:"mt-5",style:({
        backgroundColor: _vm.isToggleDisabled
          ? 'transparent'
          : _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.darkPrimaryColor
          : 'transparent'
      }),attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"id":"custom-tab-items","data-test-id":"webhook-config-tabs-items"},model:{value:(_vm.wtTabs),callback:function ($$v) {_vm.wtTabs=$$v},expression:"wtTabs"}},[_c('v-tab-item',[_c('WebhookParams',{attrs:{"currParams":_vm.wtUrlParams,"setParams":_vm.setWebhookParams,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"type":"urlParams","isToggleDisabled":_vm.isToggleDisabled,"isWriteEnabled":_vm.isWriteEnabled}})],1),_c('v-tab-item',[_c('WebhookParams',{attrs:{"currParams":_vm.wtHeaderParams,"setParams":_vm.setWebhookParams,"type":"headerParams","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"isToggleDisabled":_vm.isToggleDisabled,"isWriteEnabled":_vm.isWriteEnabled}})],1),(!(_vm.selectedWtMethod === 'GET' || _vm.selectedWtMethod === 'DELETE'))?_c('v-tab-item',[_c('v-textarea',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"outlined":"","name":"input-7-4","label":"JSON Content","disabled":!_vm.isWriteEnabled || _vm.isToggleDisabled,"error-messages":_vm.wtContentError,"error":_vm.wtContentError.length > 0,"data-test-id":"webhook-config-content-input"},model:{value:(_vm.wtContent),callback:function ($$v) {_vm.wtContent=$$v},expression:"wtContent"}})],1):_vm._e()],1),_c('div',{staticClass:"font-weight-bold text-left notification-title pl-1"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"webhook-config-notification-title"}},[_vm._v(" Turn on/off webhook notifications ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"alarm-webhook-notification-switch","text":"","inset":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"alarm-webhook-notification-switch"},on:{"change":_vm.onClickIsWebgookTriggerEnabled},model:{value:(_vm.isWebhookTriggerEnabled),callback:function ($$v) {_vm.isWebhookTriggerEnabled=$$v},expression:"isWebhookTriggerEnabled"}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }