const getters = {
  getSummary: (state) => {
    return state.summary
  },
  getConfusionStats: (state) => {
    return state.confusionStats
  },
  getIncidentSummaryStats: (state) => {
    return state.incidentSummaryStats
  },
  getLabeledStats: (state) => {
    return state.labeledStats
  },
  getIncidentStats: (state) => {
    return state.incidentStats
  },
  getIncidentCameraStats: (state) => {
    return state.incidentCameraStats
  },
  getIncidentWeeklyStats: (state) => {
    return state.incidentWeeklyStats
  },
  getIncidentCameraIdStats: (state) => {
    return state.incidentCameraIdStats
  },
  getIncidentsPerCustomerStats: (state) => {
    return state.incidentsPerCustomerStats
  },
  getRouteParams: (state) => {
    return state.routeParams
  },
  getInputTypeStats: (state) => {
    return state.inputTypeStats
  },
  getFiLabel: (state) => {
    return state.fixedIntervalLabel
  },
  getSankeyChartStats: (state) => {
    return state.sankeyChartStats
  },
  getTotalAlarmCountPerMonth: (state) => {
    return state.totalAlarmCountPerMonth
  },
  getTotalAlarmQuotaPerMonth: (state) => {
    return state.totalAlarmQuotaPerMonth
  },
  getUsedAlarmQuotaPerMonth: (state) => {
    return state.usedAlarmQuotaPerMonth
  },

  getIncidentsPerCustomerData: (state) => {
    return state.incidentsPerCustomerData
  },
  getIncidentsPerCustomerStatus: (state) => {
    return state.incidentsPerCustomerStatus
  },

  getIncidentsOverTimeData: (state) => {
    return state.incidentsOverTimeData
  },
  getIncidentsOverTimeStatus: (state) => {
    return state.incidentsOverTimeStatus
  },

  getIncidentsResultDistributionData: (state) => {
    return state.incidentsResultDistributionData
  },
  getIncidentsResultDistributionStatus: (state) => {
    return state.incidentsResultDistributionStatus
  },

  getResultDistributionData: (state) => {
    return state.resultDistributionData
  },
  getResultDistributionStatus: (state) => {
    return state.resultDistributionStatus
  },

  getFilteredAlarmsAccumulatedOverTimeData: (state) => {
    return state.filteredAlarmsAccumulatedOverTimeData
  },
  getFilteredAlarmsAccumulatedOverTimeStatus: (state) => {
    return state.filteredAlarmsAccumulatedOverTimeStatus
  },

  getUniqueCameraIncidentsOverTimeData: (state) => {
    return state.uniqueCameraIncidentsOverTimeData
  },
  getUniqueCameraIncidentsOverTimeStatus: (state) => {
    return state.uniqueCameraIncidentsOverTimeStatus
  },

  getIncidentsByInputTypeData: (state) => {
    return state.incidentsByInputTypeData
  },
  getIncidentsByInputTypeStatus: (state) => {
    return state.incidentsByInputTypeStatus
  },

  getCamerasThrowsIncidentsData: (state) => {
    return state.camerasThrowsIncidentsData
  },
  getCamerasThrowsIncidentsStatus: (state) => {
    return state.camerasThrowsIncidentsStatus
  },

  getSummaryData: (state) => {
    return state.summaryData
  },
  getSummaryStatus: (state) => {
    return state.summaryStatus
  },

  getUsageMetricsData: (state) => {
    return state.usageMetricsData
  },
  getUsageMetricsStatus: (state) => {
    return state.usageMetricsStatus
  },

  getPromiseQubeStatus: (state) => {
    return state.promiseQubeUsageStatus
  },
  getPromiseQubeData: (state) => {
    return state.promiseQubeUsageData
  },

  getIncidentWeeklyData: (state) => {
    return state.incidentWeeklyData
  },
  getIncidentWeeklyStatus: (state) => {
    return state.incidentWeeklyStatus
  }
}

export default {
  ...getters
}
