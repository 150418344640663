<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
      data-test-id="sia-notification-exapand-bar"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="sia-notification-exapand-title"
      >
        SIA-DC09
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>
    <div class="pl-4 pb-2" v-if="isExpanded">
      <div style="display: flex; flex-direction: row; font-size: 13px">
        <div style="flex: 1">
          <h3
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            class="text-left pt-5 pb-3 my-2 mt-2"
            data-test-id="sia-notification-acc-no-label"
          >
            Notify sub folder account number
          </h3>
        </div>
        <div class="mt-2 mr-1">
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isRelatedSiaAccountNotified"
            text
            class="notification-switch"
            color="secondary"
            inset
            @change="onClickIsRelatedAccountNotified"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="sia-notification-acc-no-switch"
          ></v-switch>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; font-size: 13px">
        <div style="flex: 1">
          <h3
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            class="text-left pt-5 pb-3 my-2 mt-2"
            data-test-id="sia-notification-heartbeat-label"
          >
            Send SIA heartbeat message
          </h3>
        </div>
        <div class="mt-2 mr-1">
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isSiaHeartbeatEnabled"
            text
            class="notification-switch"
            color="secondary"
            inset
            :disabled="
              !canHeartbeatEnable || isToggleDisabled || !isWriteEnabled
            "
            @change="onClickIsHeartbeatEnabled"
            data-test-id="sia-notification-heartbeat-switch"
          ></v-switch>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; font-size: 13px">
        <div style="flex: 1">
          <h3
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            class="text-left pt-5 pb-3 my-2 mt-2"
            data-test-id="sia-notification-routine-label"
          >
            Send SIA routine message
          </h3>
        </div>
        <div class="mt-2 mr-1">
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isSiaRoutineMessageEnabled"
            text
            class="notification-switch"
            color="secondary"
            inset
            :disabled="!canHeartbeatEnable || isToggleDisabled"
            @change="onClickIsRoutineMessageEnabled"
            data-test-id="sia-notification-routine-switch"
          ></v-switch>
        </div>
      </div>
      <!-- SIA config data -->
      <div class="pr-3 form">
        <v-text-field
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          v-model="siaIp"
          label="IP Address"
          outlined
          color="secondary"
          :disabled="isToggleDisabled || !isWriteEnabled"
          :error-messages="siaIpError"
          :error="siaIpError.length > 0"
          data-test-id="sia-notification-ip-input"
        ></v-text-field>
        <v-text-field
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          v-model="siaPort"
          label="Port"
          outlined
          color="secondary"
          :disabled="isToggleDisabled || !isWriteEnabled"
          :error-messages="siaPortError"
          :error="siaPortError.length > 0"
          data-test-id="sia-notification-port-input"
        ></v-text-field>
      </div>
      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="sia-notification-switch-label"
        >
          Turn on/off SIA notifications
        </div>
        <v-switch
          id="alarm-sia-notification-switch"
          v-model="isSiaEnabled"
          text
          inset
          class="notification-switch"
          color="secondary"
          @change="onClickIsSiaEnabled"
          :disabled="isToggleDisabled || !isWriteEnabled"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          data-test-id="sia-notification-switch"
        ></v-switch>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
          data-test-id="sia-notification-snackbar-close"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class SiaNotificationConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChangeAccountNotified: (status: boolean, isChanged: boolean) => void
  @Prop() onChangeHeartbeatEnabled: (
    status: boolean,
    isChanged: boolean
  ) => void
  @Prop() onChangeRoutineMessageEnabled: (
    status: boolean,
    isChanged: boolean
  ) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string
  @Prop() isNotificationFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public isExpanded: boolean = false

  public isSiaEnabled: boolean = false
  public isSiaHeartbeatEnabled: boolean = false
  public isSiaRoutineMessageEnabled: boolean = false
  public isRelatedSiaAccountNotified: boolean = false
  public siaIp = ''
  public siaPort = ''
  public canHeartbeatEnable: boolean = false

  public siaIpError: string = ''
  public siaPortError: string = ''

  public originalSiaData: any = null

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isNotificationFieldEnabled === null
      ? this.configuration?.blockToggles?.notifications !== true
      : this.isNotificationFieldEnabled !== true && this.level !== 'client'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('notificationType')
  @Watch('isNotificationFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      let siaData = {}
      siaData = showingConfig.siaData
      this.isSiaEnabled = showingConfig.isSiaEnabled

      this.canHeartbeatEnable = this.siaIp !== '' && this.siaPort !== ''

      this.isSiaHeartbeatEnabled = showingConfig.isSiaHeartbeatEnabled ?? false
      this.isRelatedSiaAccountNotified =
        showingConfig.isRelatedSiaAccountNotified ?? false

      this.isSiaRoutineMessageEnabled =
        showingConfig.isSiaRoutineMessageEnabled ?? false

      this.isExpanded = this.isSiaEnabled
      this.siaIp = siaData ? siaData['siaIp'] : ''
      this.siaPort = siaData ? siaData['siaPort'] : ''

      this.originalSiaData = {
        isSiaEnabled: this.isSiaEnabled,
        siaIp: this.siaIp,
        siaPort: this.siaPort,
        isSiaHeartbeatEnabled: this.isSiaHeartbeatEnabled,
        isRelatedSiaAccountNotified: this.isRelatedSiaAccountNotified,
        isSiaRoutineMessageEnabled: this.isSiaRoutineMessageEnabled
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return this.isSiaEnabled !== (this.originalSiaData.isSiaEnabled || false)
  }

  public hasHeartbeatSwitchChanged(): boolean {
    return (
      this.isSiaHeartbeatEnabled !== this.originalSiaData.isSiaHeartbeatEnabled
    )
  }

  public hasAccountSwitchChanged(): boolean {
    return (
      this.isRelatedSiaAccountNotified !==
      this.originalSiaData.isRelatedSiaAccountNotified
    )
  }

  public hasRoutineSwitchChanged(): boolean {
    return (
      this.isSiaRoutineMessageEnabled !==
      this.originalSiaData.isSiaRoutineMessageEnabled
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.siaIp?.trim() !== this.originalSiaData.siaIp ||
      this.siaPort?.trim() !== this.originalSiaData.siaPort
    )
  }

  public onClickIsSiaEnabled(value: boolean) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public onClickIsHeartbeatEnabled(value: boolean) {
    if (this.siaIp == '' || this.siaPort == '') {
      this.isSiaHeartbeatEnabled = false
      this.popSnackbarMessage(
        'SIA IP, port is required to enable heartbeat functionality!'
      )
      return
    }
    this.onChangeHeartbeatEnabled(value, this.hasHeartbeatSwitchChanged())
  }

  public onClickIsRoutineMessageEnabled(value: boolean) {
    if (this.siaIp == '' || this.siaPort == '') {
      this.isSiaRoutineMessageEnabled = false
      this.popSnackbarMessage(
        'SIA IP, port and account is required to enable routine message functionality!'
      )
      return
    }
    this.onChangeRoutineMessageEnabled(value, this.hasRoutineSwitchChanged())
  }

  public onClickIsRelatedAccountNotified(value: boolean) {
    this.onChangeAccountNotified(value, this.hasAccountSwitchChanged())
  }

  @Watch('siaIp')
  @Watch('siaPort')
  public onClickSaveSiaConfig() {
    let isError: boolean = false
    this.siaIpError = ''
    this.siaPortError = ''

    if (this.siaIp?.trim() == '') {
      isError = true
      this.siaIpError = 'SIA IP address is required'
    }

    if (this.siaPort?.trim() == '') {
      isError = true
      this.siaPortError = 'SIA port is required'
    }

    // Check if IP address and port are not empty
    const isIpAndPortNotEmpty = this.siaIp !== '' && this.siaPort !== ''

    // Enable/disable SIA Heartbeat Message toggle based on IP address and port
    if (isIpAndPortNotEmpty) {
      this.canHeartbeatEnable = true
    } else {
      this.canHeartbeatEnable = false
    }

    this.onChange({
      siaIp: this.siaIp?.trim(),
      siaPort: this.siaPort?.trim(),
      isChanged: isError ? false : this.hasDataChanged()
    })
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}
.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
