<template>
  <div>
    <div
      v-if="modifiedSections.length > 0"
      class="sticky-bar"
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled ? '#FFFFFF' : '#333333'
      }"
      data-test-id="client-config-sticky-bar"
    >
      <p
        :style="{
          color: isUserAdmin && isDarkModeToggleEnabled ? '#333333' : '#FFFFFF',
          marginTop: '10px'
        }"
        data-test-id="client-config-sticky-bar-message"
      >
        {{
          `Do you want save the changes to ${modifiedSections.join(
            ', '
          )} config(s)? `
        }}
      </p>
      <div class="sticky-button-group">
        <Button
          btnStyle="outlined"
          btnText="Discard"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled ? '#333333' : '#FFFFFF'
          }"
          :class="{
            'discard-btn-dark': !isDarkModeToggleEnabled
          }"
          @onButtonClick="onDiscardSaveConfigs"
          data-test-id="client-config-sticky-bar-discard-button"
        />
        <Button
          btnText="Save"
          @onButtonClick="onSaveConfigs"
          data-test-id="client-config-sticky-bar-save-button"
        />
      </div>
    </div>
    <v-row>
      <v-col
        md="6"
        sm="12"
        style="padding-right: 50px"
        class="black--text pt-11 pl-10"
      >
        <v-row class="pb-4 pr-6">
          <div
            class="card-style black--text d-flex flex-column pl-3 py-5"
            :style="
              isUserAdmin && isDarkModeToggleEnabled
                ? 'background-color: #938d76'
                : 'background-color: #e5e5e5'
            "
            data-test-id="client-config-div"
          >
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              style="text-align: left; margin: 0px"
              data-test-id="client-config-title"
            >
              Client Information
            </p>
          </div>
          <div class="cart-style black--text d-flex flex-column">
            <v-col class="d-flex flex-column pt-5 pb-1 pl-5">
              <p
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlackColor
                }"
                class="font-weight-bold text-left"
                data-test-id="client-config-client-id-label"
              >
                Client Id :
                <code
                  class="pointer"
                  v-text="clientId"
                  @click="onClickCopy(clientId)"
                  style="margin-top: -20px; font-size: 15px"
                  data-test-id="client-config-client-id-copy-value"
                >
                </code>
              </p>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
                data-test-id="client-config-client-created-label"
              >
                Created
                <code
                  class="pointer"
                  v-text="`on ${clientCreatedDate}, at ${clientCreatedTime}`"
                  data-test-id="client-config-client-created-value"
                >
                </code>
              </p>
            </v-col>
            <v-col class="pl-5">
              <p
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlackColor
                }"
                class="font-weight-bold text-left"
                data-test-id="client-config-client-name-label"
              >
                Client Name
              </p>
              <div style="display: flex; align-items: bottom">
                <v-text-field
                  :readonly="!isWriteEnabled"
                  :dark="isUserAdmin && isDarkModeToggleEnabled"
                  v-model="clientName"
                  placeholder="Client Name"
                  hide-details="true"
                  style="margin-top: -20px; font-size: 15px"
                  :disabled="!isWriteEnabled"
                  @input="onClientNameChange"
                  data-test-id="client-config-client-name-input"
                >
                  <template v-if="isWriteEnabled" v-slot:append-outer>
                    <v-btn
                      icon
                      v-if="clientName"
                      @click="clientName = ''"
                      title="Clear"
                      data-test-id="client-config-client-name-clear-button"
                    >
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </v-col>

            <v-col
              v-if="isUserAdmin && tmpQuotaStatus"
              class="card-style black--text d-flex flex-column pb-1 pl-4 pr-3"
            >
              <v-row
                class="d-flex align-center justify-space-between pb-6 pl-3 mt-2"
              >
                <div
                  class="font-weight-bold text-left mb-0 mr-2 pb-0"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="client-config-quota-activation-label"
                >
                  Quota Limit Activation
                </div>
                <v-switch
                  id="alarm-quota-limit"
                  :dark="isUserAdmin && isDarkModeToggleEnabled"
                  v-model="isQuotaEnabled"
                  :disabled="!isWriteEnabled"
                  color="secondary"
                  class="mt-0"
                  hide-details
                  inset
                  data-test-id="client-config-quota-limit-switch"
                />
              </v-row>
              <v-row v-if="isQuotaEnabled" class="mr-0" no-gutters>
                <v-col>
                  <v-subheader
                    class="text-subtitle-2 text-left mb-0 mr-2 pb-0 pt-1"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlack
                    }"
                    data-test-id="client-config-quota-limit-label"
                  >
                    Quota Limit
                  </v-subheader>
                </v-col>
                <v-col>
                  <v-text-field
                    class="pl-3 pt-0"
                    v-model="alarmQuota"
                    type="number"
                    outlined
                    dense
                    placeholder="Quota Limit"
                    style="font-size: 15px; max-width: 240px"
                    :dark="isUserAdmin && isDarkModeToggleEnabled"
                    :disabled="!isWriteEnabled"
                    :rules="[positiveNumberRule]"
                    data-test-id="client-config-quota-limit-input"
                  />
                </v-col>
              </v-row>
              <v-row v-if="isQuotaEnabled" class="mr-0" no-gutters>
                <v-col>
                  <v-subheader
                    class="text-subtitle-2 text-left mb-0 mr-2 pb-0 pt-1"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlack
                    }"
                    data-test-id="client-config-quota-alert-percentage-label"
                  >
                    Quota Alert Percentage
                  </v-subheader>
                </v-col>
                <v-col>
                  <v-text-field
                    class="pl-3 pt-0"
                    v-model="quotaAlertPercentage"
                    type="number"
                    placeholder="Quota Alert Percentage"
                    outlined
                    dense
                    style="font-size: 15px; max-width: 240px"
                    :dark="isUserAdmin && isDarkModeToggleEnabled"
                    :disabled="!isWriteEnabled"
                    :rules="[percentageNumberRule]"
                    data-test-id="client-config-quota-alert-percentage-input"
                  />
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-row>

        <v-row class="pb-12 pr-6" v-if="tmpQuotaStatus"
          ><QuotaNotification :changeQuota="finalChangeQuota"
        /></v-row>

        <v-row class="pb-12 pr-6">
          <div
            class="card-style black--text d-flex flex-column pl-3 py-5"
            :style="
              isUserAdmin && isDarkModeToggleEnabled
                ? 'background-color: #938d76'
                : 'background-color: #f2f2f2'
            "
            data-test-id="client-config-keys-div"
          >
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              style="text-align: left; margin: 0px"
              data-test-id="client-config-keys-label"
            >
              Keys
            </p>
          </div>
          <div class="cart-style black--text d-flex flex-column">
            <ClientSubscriptionKeys :isWriteEnabled="isWriteEnabled" />
          </div>
        </v-row>

        <v-row class="pl-3 pt-5 pb-4 pr-10">
          <BlockHeader :title="'Contact'" :titleOnly="true" />
          <v-row class="card-component-style">
            <Contact
              :configuration="configuration"
              :onChange="onContactChange"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
        </v-row>

        <v-row class="pl-3 py-12 pr-10">
          <BlockHeader :title="'Integrations'" :titleOnly="true" />
          <v-row class="card-component-style">
            <HikVisionConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickHikCentralEnabled"
              :onChange="onClickSaveHikCentralConfig"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
          <v-row class="card-component-style">
            <GenetecConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickGenetecEnabled"
              :onChange="onClickSaveGenetecConfig"
              :onChangeAcknowledgementStatus="
                onClickGenetecAcknowledgeEnabledStatus
              "
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
          <v-row class="card-component-style">
            <EagleEyeConfiguration
              :configuration="configuration"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
          <v-row class="card-component-style" v-if="isUserAdmin">
            <SmtpConfiguration
              :configuration="configuration"
              :subscriptionKeys="subscriptionKeys"
              :onSwitchChange="onToggleSmtpStatus"
              :isWriteEnabled="isWriteEnabled"
              :onChange="onClickSaveSmtpIntegrationConfig"
            />
          </v-row>
        </v-row>
      </v-col>
      <v-col md="6" sm="12" class="black--text pb-2 pt-14">
        <v-row class="pl-3 pb-4 pr-12">
          <BlockHeader :title="'Alarm Settings'" :titleOnly="true" />
          <v-row class="card-component-style">
            <HumanReviewConfiguration
              :configuration="configuration"
              :onSwitchChange="onIsAskCrowdWorkersChange"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
          <v-row class="card-component-style">
            <AlarmDefinitionConfiguration
              :configuration="configuration"
              :onChange="onAlarmDefinitionChange"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
        </v-row>

        <v-row class="pl-3 pt-12 pb-4 pr-12">
          <BlockHeader :title="'Disarmed Time Range'" :titleOnly="true" />
          <v-row class="card-component-style">
            <UnarmedTimeRangeConfiguration
              :configuration="configuration"
              :onChange="onChangeUnarmedTimeRange"
              :onSwitchChange="onClickIsUnarmedTimeRangeEnabled"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
        </v-row>

        <v-row class="pl-3 pt-12 pb-8 pr-12 mb-16">
          <BlockHeader :title="'Notifications'" :titleOnly="true" />
          <v-row class="card-component-style">
            <EmailNotificationConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickIsEmailNotificationEnabled"
              :onChange="onClickSaveNotificationEmail"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
              :notificationType="parentNotificationType"
            />
            <SiaNotificationConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickIsSiaEnabled"
              :onChange="onClickSaveSiaConfig"
              :onChangeAccountNotified="updateIsRelatedSiaAccountNotified"
              :onChangeHeartbeatEnabled="updateIsSiaHeartbeatEnabled"
              :onChangeRoutineMessageEnabled="updateIsSiaRoutineMessageEnabled"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
              :notificationType="parentNotificationType"
            />
            <WebhookNotificationConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickIsWebhookEnabled"
              :onChange="onClickSaveWebhookConfig"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
              :notificationType="parentNotificationType"
            />
            <FtpNotificationConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickIsFtpNotificationEnabled"
              :onChange="onClickSaveFtpConfig"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
              :notificationType="parentNotificationType"
            />
            <SmtpNotificationConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickIsSmtpNotificationEnabled"
              :onChange="onClickSaveSmtpConfig"
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
              :notificationType="parentNotificationType"
            />
            <EvalinkNotificationConfiguration
              :configuration="configuration"
              :onSwitchChange="onClickIsEvalinkEnabled"
              :onChange="onClickSaveEvalinkConfig"
              :onChangeDeviceOverrideEnabled="
                onClickIsEvalinkDeviceOverrideEnabled
              "
              :level="'client'"
              :isWriteEnabled="isWriteEnabled"
              :notificationType="parentNotificationType"
            />
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :class="modifiedSections.length > 0 ? 'snack-bar-margin' : ''"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
    <InfoButton v-if="!smallScreen" :rows="rows" />
    <v-overlay
      :value="isSavingClientConfigs"
      :class="isSavingClientConfigs ? 'blur-background' : ''"
    >
      <div>
        <v-progress-circular
          indeterminate
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor,
            marginLeft: '1rem'
          }"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ProgressBar from '../ProgressBar.vue'
import Button from '@/components/app/Button.vue'
import { DocumentReference } from '@firebase/firestore-types'
import WebhookParams from '@/components/events/WebhookParams.vue'
import FtpNotificationConfiguration from './FtpNotificationConfiguration.vue'
import AlarmDefinitionConfiguration from './AlarmDefinitionConfiguration.vue'
import UnarmedTimeRangeConfiguration from './UnarmedTimeRangeConfiguration.vue'
import HumanReviewConfiguration from './HumanReviewConfiguration.vue'
import EmailNotificationConfiguration from './EmailNotificationConfiguration.vue'
import SiaNotificationConfiguration from './SiaNotificationConfiguration.vue'
import WebhookNotificationConfiguration from './WebhookNotificationConfiguration.vue'
import EvalinkNotificationConfiguration from './EvalinkNotificationConfiguration.vue'
import ClientSubscriptionKeys from '@/components/ClientSubscriptionKeys.vue'
import InfoButton from '@/views/InfoButton.vue'
import BlockHeader from './BlockHeader.vue'
import NotificationInheritHeader from './NotificationInheritHeader.vue'
import Contact from './Contact.vue'
import SmtpNotificationConfiguration from './SmtpNotificationConfiguration.vue'
import GenetecConfiguration from './GenetecConfiguration.vue'
import EagleEyeConfiguration from './EagleEyeConfiguration.vue'
import HikVisionConfiguration from './HikVisionConfiguration.vue'
import { ClientModifiedConfiguration } from 'configuration'
import SmtpConfiguration from './SmtpConfiguration.vue'
import { getDateAndTime } from '@/utils/Utils'
import QuotaNotification from '@/components/quota/QuotaNotification.vue'
import { isNodeNameValid } from '../../utils/Validator'
import {
  DEFAULT_QUOTA,
  DEFAULT_QUOTA_ALERT_PERCENTAGE,
  DISARMED_TIME_RANGE,
  QUOTA_ENABLED
} from '@/utils/Constants'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceClient = { namespace: 'client' }
const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }
const namespaceStatistics = { namespace: 'statistics' }

@Component({
  components: {
    ProgressBar,
    Button,
    WebhookParams,
    FtpNotificationConfiguration,
    AlarmDefinitionConfiguration,
    UnarmedTimeRangeConfiguration,
    HumanReviewConfiguration,
    EmailNotificationConfiguration,
    SiaNotificationConfiguration,
    WebhookNotificationConfiguration,
    EvalinkNotificationConfiguration,
    Contact,
    InfoButton,
    ClientSubscriptionKeys,
    BlockHeader,
    SmtpNotificationConfiguration,
    GenetecConfiguration,
    EagleEyeConfiguration,
    HikVisionConfiguration,
    NotificationInheritHeader,
    SmtpConfiguration,
    QuotaNotification
  }
})
export default class ClientConfiguration extends Vue {
  @Prop({ type: Object, required: true }) configuration!: any
  @Prop({ required: true }) userOrOrgRef!: DocumentReference
  @Prop() changeClientConfigKey: () => void
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getUsedAlarmQuotaPerMonth', namespaceStatistics)
  public totalAlarmCountPerMonth: number
  @Getter('getTotalAlarmQuotaPerMonth', namespaceStatistics)
  public totalAlarmQuotaPerMonth: {
    isQuotaEnabled: boolean
    alarmQuota: number
    quotaAlertPercentage: number
  }

  @Action('updateClientConfig', namespaceClient) updateClientConfig!: any
  @Action('fetchQuotaData', namespaceStatistics) private fetchQuotaData!: any
  @Action('getClientDefaultKey', namespaceClient)
  public getClientDefaultKey: (payload: {
    clientId?: string
    userId?: string
  }) => Promise<any>
  @Action('setAllocatedQuota', namespaceStatistics) setAllocatedQuota!: any

  public get themeColor(): string {
    if (this.currentUser.role === 'Administrator') {
      return this.getColors.lightPrimaryColor
    } else {
      return this.getColors.darkBlackColor
    }
  }

  // Quota is temporarily disabled until the security issues are fixed.
  public tmpQuotaStatus: boolean = QUOTA_ENABLED

  public clientOriginalData: any = null
  public clientName: string = ''
  public clientId: string = ''
  public clientCreatedDate: string = ''
  public clientCreatedTime: string = ''

  public isSaved: boolean = true
  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public rows: any[] = [
    {
      icon: 'mdi-account-multiple',
      text: 'Learn more about client configurations',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/configurations/client-configuration',
      class: 'pr-3 black--text'
    }
  ]

  public get smallScreen() {
    return isCompactView()
  }
  public isWriteEnabled: boolean = false

  public modifiedSections: string[] = []

  public contactData: any = undefined

  public isAskCrowdWorkers: any = undefined
  public alarmDefinition: any = undefined

  public isUnarmedTimeRangeEnabled: any = null
  public unarmedTimeRangeData: any = null

  public isEmailNotificationEnabled: any = undefined
  public emailData: any = undefined
  public isEmailNotificationEnabledHc: any = undefined
  public emailDataHc: any = undefined

  public siaData: any = undefined
  public isSiaEnabled: any = undefined
  public isSiaHeartbeatEnabled: any = undefined
  public isSiaRoutineMessageEnabled: any = undefined
  public isRelatedSiaAccountNotified: any = undefined
  public siaDataHc: any = undefined
  public isSiaEnabledHc: any = undefined
  public isSiaHeartbeatEnabledHc: any = undefined
  public isSiaRoutineMessageEnabledHc: any = undefined
  public isRelatedSiaAccountNotifiedHc: any = undefined

  public isWebhookTriggerEnabled: any = undefined
  public webhookData: any = undefined
  public isWebhookTriggerEnabledHc: any = undefined
  public webhookDataHc: any = undefined

  public isFTPNotificationsEnabled: any = undefined
  public ftpData: any = undefined
  public isFTPNotificationsEnabledHc: any = undefined
  public ftpDataHc: any = undefined

  public isSMTPNotificationEnabled: any = undefined
  public smtpData: any = undefined
  public isSMTPNotificationEnabledHc: any = undefined
  public smtpDataHc: any = undefined

  public isEvalinkEnabled: any = undefined
  public isEvalinkDeviceOverrideEnabled: any = undefined
  public evalinkData: any = undefined
  public isEvalinkEnabledHc: any = undefined
  public isEvalinkDeviceOverrideEnabledHc: any = undefined
  public evalinkDataHc: any = undefined

  public isHikvisionIntegrationEnabled: any = undefined
  public isHikvisionAcknowledgementEnabled: any = undefined
  public hikvisionIntegrationData: any = undefined

  public isGenetecIntegrationEnabled: any = undefined
  public isGenetecAcknowledgementEnabled: any = undefined
  public genetecIntegrationData: any = undefined

  public isSavingClientConfigs: boolean = false

  // Tab View
  public tab = null
  public parentNotificationType = 'Alarm'

  public smtpIntegrationData: any = undefined
  public isSmtpIntegrationEnabled: any = undefined
  public subscriptionKeys = []

  public alarmQuota: number = DEFAULT_QUOTA
  public quotaAlertPercentage: number = DEFAULT_QUOTA_ALERT_PERCENTAGE
  public isQuotaEnabled: boolean = false
  public changeQuota: boolean = false
  public finalChangeQuota: boolean = false

  get isDefaultNode() {
    return this.configuration?.isDefault === true
  }

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isNotificationTypeHc() {
    return this.parentNotificationType === 'HC'
  }

  public onClientNameChange(inputText: string) {
    this.validateClientName()
    this.clientName = inputText
  }

  @Watch('configuration')
  public onConfigurationChanged() {
    if (this.configuration) {
      this.isWriteEnabled = this.isUserAdmin
        ? true
        : this.configuration.isWriteEnabled ?? false
      this.clientName = this.configuration.name
      this.clientId = this.configuration.clientId
      this.isQuotaEnabled = this.configuration.isQuotaEnabled ?? false
      this.alarmQuota = this.configuration.alarmQuota ?? DEFAULT_QUOTA
      this.quotaAlertPercentage =
        this.configuration.quotaAlertPercentage ??
        DEFAULT_QUOTA_ALERT_PERCENTAGE

      this.subscriptionKeys = this.configuration.keys ?? []

      this.clientOriginalData = {
        clientName: this.clientName,
        isQuotaEnabled: this.isQuotaEnabled,
        alarmQuota: this.alarmQuota,
        quotaAlertPercentage: this.quotaAlertPercentage
      }
      const dateAndTime = getDateAndTime(this.configuration?.createdAt)
      this.clientCreatedDate = dateAndTime.date
      this.clientCreatedTime = dateAndTime.time
    }
  }

  public hasClientInfoChanged(): boolean {
    return (
      (this.clientName?.trim() !== this.clientOriginalData.clientName ||
        this.isQuotaEnabled !== this.clientOriginalData.isQuotaEnabled ||
        this.alarmQuota !== this.clientOriginalData.alarmQuota ||
        this.quotaAlertPercentage !==
          this.clientOriginalData.quotaAlertPercentage) &&
      this.positiveNumberRule(this.alarmQuota) === true &&
      this.percentageNumberRule(this.quotaAlertPercentage) === true
    )
  }

  public validateClientName() {
    // Remove leading and trailing spaces from the client name
    this.clientName = this.clientName?.trim()
    // Validate the client name here
    const isValid = this.clientName === ''
    return isValid
  }

  public beforeUnload(e) {
    if (this.modifiedSections.length > 0) {
      const message =
        'You have unsaved changes. Are you sure you want to leave?'
      e.returnValue = message
      return message
    }
  }

  public created() {
    window.addEventListener('beforeunload', this.beforeUnload)
  }

  public destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload)
  }

  public async mounted() {
    this.onConfigurationChanged()
  }

  private async popSnackbarMessage(message: string) {
    this.snackbar = true
    this.snackbarMessage = message
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied to clipboard`)
  }

  public resetSaveState() {
    this.modifiedSections = []

    this.contactData = undefined

    this.isAskCrowdWorkers = undefined
    this.alarmDefinition = undefined

    this.isUnarmedTimeRangeEnabled = null
    this.unarmedTimeRangeData = null

    this.isEmailNotificationEnabled = undefined
    this.emailData = undefined
    this.isEmailNotificationEnabledHc = undefined
    this.emailDataHc = undefined

    this.siaData = undefined
    this.isSiaEnabled = undefined
    this.isSiaHeartbeatEnabled = undefined
    this.isSiaRoutineMessageEnabled = undefined
    this.isRelatedSiaAccountNotified = undefined
    this.siaDataHc = undefined
    this.isSiaEnabledHc = undefined
    this.isSiaHeartbeatEnabledHc = undefined
    this.isSiaRoutineMessageEnabledHc = undefined
    this.isRelatedSiaAccountNotifiedHc = undefined

    this.isWebhookTriggerEnabled = undefined
    this.webhookData = undefined
    this.isWebhookTriggerEnabledHc = undefined
    this.webhookDataHc = undefined

    this.isFTPNotificationsEnabled = undefined
    this.ftpData = undefined
    this.isFTPNotificationsEnabledHc = undefined
    this.ftpDataHc = undefined

    this.isSMTPNotificationEnabled = undefined
    this.smtpData = undefined
    this.isSMTPNotificationEnabledHc = undefined
    this.smtpDataHc = undefined

    this.isEvalinkEnabled = undefined
    this.isEvalinkDeviceOverrideEnabled = undefined
    this.evalinkData = undefined
    this.isEvalinkEnabledHc = undefined
    this.isEvalinkDeviceOverrideEnabledHc = undefined
    this.evalinkDataHc = undefined

    this.isSmtpIntegrationEnabled = undefined
    this.smtpIntegrationData = undefined
  }

  public async onDiscardSaveConfigs() {
    try {
      this.changeClientConfigKey()
      this.resetSaveState()
      this.popSnackbarMessage('Client configs discarded!')
    } catch (error) {
      console.log(error)
      this.popSnackbarMessage('Error discarding client configs!')
    }
  }

  public async onSaveConfigs() {
    this.isSavingClientConfigs = true

    if (this.changeQuota) {
      this.finalChangeQuota = !this.finalChangeQuota
    }

    const clientInformation = await this.processClientDetails()
    if (this.hasClientInfoChanged() && !clientInformation) {
      this.isSavingClientConfigs = false
      return
    }
    // Modified Configuration Object
    const modifiedConfiguration: ClientModifiedConfiguration = {
      clientInformation: {
        clientName: clientInformation?.clientName,
        isQuotaEnabled: this.isQuotaEnabled,
        alarmQuota: this.alarmQuota,
        quotaAlertPercentage: this.quotaAlertPercentage
      },
      contact: {
        contactPerson: this.contactData?.contactName,
        contactNumber: {
          countryCode: this.contactData?.countryCode,
          countryCallingCode: this.contactData?.countryCallingCode,
          phoneNumber: this.contactData?.phoneNumber
        },
        contactMessage: this.contactData?.message,
        receiveCallNotification: this.contactData?.isCallEnabled,
        receiveSmsNotification: this.contactData?.isSmsEnabled
      },
      hikVisionHCP: {
        serviceUrl: this.hikvisionIntegrationData?.serviceUrl,
        partnerAppKey: this.hikvisionIntegrationData?.appKey,
        partnerAppSecret: this.hikvisionIntegrationData?.appSecret,
        hikVisionVersion: this.hikvisionIntegrationData?.hikCentralVersion,
        automaticEventAcknowledgement:
          this.hikvisionIntegrationData?.isAcknowledgementEnabled,
        onHikvision:
          this.isHikvisionIntegrationEnabled?.isHikCentralEnabled || false
      },
      genetec: {
        serviceUrl: this.genetecIntegrationData?.serviceUrl,
        applicationId: this.genetecIntegrationData?.applicationId,
        username: this.genetecIntegrationData?.username,
        password: this.genetecIntegrationData?.password,
        automaticEventAcknowledgement:
          this.genetecIntegrationData?.isAcknowledgementEnabled,
        onGenetec: this.isGenetecIntegrationEnabled?.isGenetecEnabled
      },
      smtpIntegrationData: {
        isOn: this.isSmtpIntegrationEnabled?.isSmtpIntegrationEnabled,
        subjectRegexPattern: this.smtpIntegrationData?.subjectRegexPattern,
        bodyRegexPattern: this.smtpIntegrationData?.bodyRegexPattern,
        exampleEmailSubject: this.smtpIntegrationData?.exampleEmailSubject,
        exampleEmailBody: this.smtpIntegrationData?.exampleEmailBody,
        selectedSiteRegexPart: this.smtpIntegrationData?.selectedSiteRegexPart,
        selectedCameraRegexPart:
          this.smtpIntegrationData?.selectedCameraRegexPart
      },
      alarmSettings: {
        humanReview: this.isAskCrowdWorkers?.isAskCrowdWorkers,
        alarmDefinition: this.alarmDefinition?.alarmDefinition
      },
      unarmedTimeRange: {
        alwaysUnarmed: this.unarmedTimeRangeData?.isAlwaysUnarmed,
        timeZone: this.unarmedTimeRangeData?.selectedTimeZone,
        applicationDays: this.unarmedTimeRangeData?.applicableDays,
        startTime: this.unarmedTimeRangeData?.startTime,
        endTime: this.unarmedTimeRangeData?.endTime
      },
      notifications: {
        alarm: {
          email: {
            isOn: this.isEmailNotificationEnabled?.isEmailNotificationEnabled,
            receivingEmails: this.emailData?.notificationEmails
          },
          siaDc09: {
            isOn: this.isSiaEnabled?.isSiaEnabled,
            notifySubFolderAccountNumber:
              this.isRelatedSiaAccountNotified?.isRelatedSiaAccountNotified,
            sendSiaHeartbeatMessage:
              this.isSiaHeartbeatEnabled?.isSiaHeartbeatEnabled,
            sendSiaRoutineMessage:
              this.isSiaRoutineMessageEnabled?.isSiaRoutineMessageEnabled,
            ipAddress: this.siaData?.siaIp,
            port: this.siaData?.siaPort
          },
          webhook: {
            isOn: this.isWebhookTriggerEnabled?.isWebhookTriggerEnabled,
            webhookArray: this.webhookData || []
          },
          ftp: {
            isOn: this.isFTPNotificationsEnabled?.isFTPNotificationsEnabled,
            host: this.ftpData?.host,
            port: this.ftpData?.port,
            username: this.ftpData?.user,
            password: this.ftpData?.password,
            filePath: this.ftpData?.filePath,
            isUploadImageSequence: this.ftpData?.isUploadImageSequence
          },
          smtp: {
            isOn: this.isSMTPNotificationEnabled?.isSMTPNotificationEnabled,
            host: this.smtpData?.host,
            port: this.smtpData?.port,
            username: this.smtpData?.user,
            password: this.smtpData?.password,
            receiverEmailAddress: this.smtpData?.receiverAddress
          },
          evalink: {
            isOn: this.isEvalinkEnabled?.isEvalinkEnabled,
            deviceOverride:
              this.isEvalinkDeviceOverrideEnabled
                ?.isEvalinkDeviceOverrideEnabled,
            authorizationKey: this.evalinkData?.Authorization,
            companyId: this.evalinkData?.companyId,
            deviceId: this.evalinkData?.deviceId,
            partition: this.evalinkData?.partition,
            partitionName: this.evalinkData?.partitionName,
            alarmZoneName: this.evalinkData?.alarmZoneName
          }
        }
      }
    }

    try {
      await this.updateClient(modifiedConfiguration)

      this.resetSaveState()
      this.isSavingClientConfigs = false
      this.popSnackbarMessage('Client configs successfully saved!')
    } catch (error) {
      this.resetSaveState()
      this.isSavingClientConfigs = false
      this.popSnackbarMessage('Error saving client configs!')
    }
  }

  @Watch('modifiedSections')
  private hasChangedValue() {
    // this detect any changes and update the value in the parent config page
    const value = this.modifiedSections.length > 0
    this.$emit('update:hasChangedValue', value)
  }

  // ****************************** INTEGRATIONS ******************************

  // ---------------------- Hik vision integration update ---------------------
  public async onClickHikCentralEnabled(
    value: boolean,
    hikCentralData: any,
    isChanged: boolean
  ) {
    this.isHikvisionIntegrationEnabled = {
      isHikCentralEnabled: value,
      hikCentralData,
      isChanged
    }
    this.addToModified('Hikvision Integration', isChanged)
  }

  // public async onClickHikCentralAcknowledgeEnabledStatus(
  //   value: boolean,
  //   isChanged: boolean
  // ) {
  //   this.isHikvisionAcknowledgementEnabled = {
  //     isAcknowledgementEnabled: value,
  //     isChanged
  //   }
  //   this.modifiedConfiguration.hikVisionHCP.automaticEventAcknowledgement =
  //     value
  //   this.addToModified('Hikvision Integration', isChanged)
  // }

  public async onClickSaveHikCentralConfig(hikCentralData: any) {
    this.hikvisionIntegrationData = hikCentralData

    this.addToModified(
      'Hikvision Integration',
      this.hikvisionIntegrationData?.isChanged
    )
  }

  // ---------------------- Genetec integration update ---------------------
  public async onClickGenetecEnabled(value: boolean, isChanged: boolean) {
    this.isGenetecIntegrationEnabled = { isGenetecEnabled: value, isChanged }
    this.addToModified('Genetec Integration', isChanged)
  }

  public async onClickGenetecAcknowledgeEnabledStatus(
    value: boolean,
    isChanged: boolean
  ) {
    this.isGenetecAcknowledgementEnabled = {
      isAcknowledgementEnabled: value,
      isChanged
    }
    this.addToModified('Genetec Integration', isChanged)
  }

  public async onClickSaveGenetecConfig(genetecData: any) {
    this.genetecIntegrationData = genetecData
    this.addToModified(
      'Genetec Integration',
      this.genetecIntegrationData?.isChanged
    )
  }

  @Watch('tab')
  public watchTab() {
    this.parentNotificationType = this.tab === 1 ? 'HC' : 'Alarm'
  }

  // ****************************** CLIENT DETAILS ******************************

  @Watch('clientName')
  @Watch('isQuotaEnabled')
  @Watch('alarmQuota')
  @Watch('quotaAlertPercentage')
  public onClientInfo() {
    const isValid = this.validateClientName()
    if (!isValid) {
      this.addToModified('Client Information', this.hasClientInfoChanged())
    } else {
      this.addToModified('Client Information', false)
    }
  }

  @Watch('isQuotaEnabled')
  @Watch('alarmQuota')
  @Watch('quotaAlertPercentage')
  public onQuotaChange() {
    this.changeQuota = true
  }

  // public async saveClientName() {
  //   const isValid = this.validateClientName()
  //   if (!isValid) {
  //     if (this.hasClientNameChanged()) {
  //       this.isSaved = false
  //       // validate client name
  //       if (!this.clientName) {
  //         this.popSnackbarMessage('Client name is required')
  //         this.isSaved = true
  //         return
  //       }
  //       if (!isNodeNameValid(this.clientName)) {
  //         this.popSnackbarMessage(
  //           `Client name should not contain any disallowed special characters!`
  //         )
  //         this.isSaved = true
  //         return
  //       }

  //       await this.updateClient()
  //     }
  //   } else {
  //     // Show an error message or handle the case where only white spaces are entered
  //     this.popSnackbarMessage('Please enter a non-empty client name.')
  //     throw new Error('Invalid client name')
  //   }
  // }

  public async processClientDetails(): Promise<{
    clientName: string
  } | null> {
    if (this.hasClientInfoChanged()) {
      this.isSaved = false
      // validate site name
      if (!this.clientName) {
        this.popSnackbarMessage('client name is required')
        this.isSaved = true
        return null
      }

      if (!isNodeNameValid(this.clientName)) {
        this.popSnackbarMessage(
          `Client name should not contain any disallowed special characters!`
        )
        this.isSaved = true
        return null
      }

      return {
        clientName: this.clientName?.trim()
      }
    }
    return null
  }

  private async updateClient(
    modifiedConfiguration: ClientModifiedConfiguration
  ) {
    try {
      const result = await this.updateClientConfig({
        docId: this.configuration?.id,
        updatedClientData: modifiedConfiguration,
        updatedFields: this.modifiedSections
      })
      if (result.status === 'success') {
        this.popSnackbarMessage(result.message)
      } else if (result.status === 'error') {
        this.popSnackbarMessage(result.message)
      }
    } catch (error) {
      this.popSnackbarMessage('Failed to update client configuration')
    } finally {
      this.isSaved = true
    }
  }

  // ****************************** CONTACT ******************************

  public onContactChange(contactData: any) {
    this.contactData = contactData
    this.addToModified('Contact', this.contactData?.isChanged)
  }

  // *************************** ALARM SETTINGS ***************************

  // ---------------------- Human review config update ---------------------
  public async onIsAskCrowdWorkersChange(value: boolean, isChanged: boolean) {
    this.isAskCrowdWorkers = { isAskCrowdWorkers: value, isChanged }

    this.addToModified('Human Review', isChanged)
  }

  // ---------------------- Alarm definition config update ---------------------
  public async onAlarmDefinitionChange(value: string, isChanged: boolean) {
    this.alarmDefinition = { alarmDefinition: value, isChanged }

    this.addToModified('Alarm Definition', isChanged)
  }

  // *************************** UNARMED TIME RANGE ***************************
  public async onClickIsUnarmedTimeRangeEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isUnarmedTimeRangeEnabled = {
      isUnarmedTimeRangeEnabled: value,
      isChanged
    }
    this.addToModified(DISARMED_TIME_RANGE, isChanged)
  }

  public async onChangeUnarmedTimeRange(unarmedTimeRange: any) {
    this.unarmedTimeRangeData = unarmedTimeRange
    this.addToModified(DISARMED_TIME_RANGE, unarmedTimeRange?.isChanged)
  }

  // *************************** NOTIFICATIONS ***************************

  // --------------------------- Email config update ---------------------------

  public async onClickIsEmailNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEmailNotificationEnabled = {
      isEmailNotificationEnabled: value,
      isChanged
    }
    this.addToModified('Email Notifications Toggle', isChanged)
  }

  public async onClickSaveNotificationEmail(emailData: any) {
    this.emailData = emailData
    this.addToModified('Email Notifications', this.emailData?.isChanged)
  }

  // --------------------------- Sia config update ---------------------------

  public async onClickIsSiaEnabled(value: boolean, isChanged: boolean) {
    this.isSiaEnabled = { isSiaEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsSiaHeartbeatEnabled(value: boolean, isChanged: boolean) {
    this.isSiaHeartbeatEnabled = { isSiaHeartbeatEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsRelatedSiaAccountNotified(
    value: boolean,
    isChanged: boolean
  ) {
    this.isRelatedSiaAccountNotified = {
      isRelatedSiaAccountNotified: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsSiaRoutineMessageEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaRoutineMessageEnabled = {
      isSiaRoutineMessageEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async onClickSaveSiaConfig(siaData: any) {
    this.siaData = siaData
    this.addToModified('Sia Notifications', this.siaData?.isChanged)
  }

  // --------------------------- Webhook config update ---------------------------
  public async onClickIsWebhookEnabled(value: boolean, isChanged: boolean) {
    this.isWebhookTriggerEnabled = { isWebhookTriggerEnabled: value, isChanged }
    this.addToModified('Webhook Notifications', isChanged)
  }

  public async onClickSaveWebhookConfig(webhookData: any) {
    this.webhookData = webhookData
    this.addToModified('Webhook Notifications', this.webhookData?.isChanged)
  }

  // --------------------------- FTP config update ---------------------------
  public async onClickIsFtpNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isFTPNotificationsEnabled = {
      isFTPNotificationsEnabled: value,
      isChanged
    }
    this.addToModified('FTP Notifications', isChanged)
  }

  public async onClickSaveFtpConfig(ftpData: any) {
    this.ftpData = ftpData
    this.addToModified('FTP Notifications', this.ftpData?.isChanged)
  }

  // --------------------------- SMTP config update ---------------------------
  public async onClickIsSmtpNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSMTPNotificationEnabled = {
      isSMTPNotificationEnabled: value,
      isChanged
    }
    this.addToModified('SMTP Notifications', isChanged)
  }

  public async onClickSaveSmtpConfig(smtpNotificationData: any) {
    this.smtpData = smtpNotificationData
    this.addToModified('SMTP Notifications', this.smtpData?.isChanged)
  }

  // --------------------------- Evalink config update ---------------------------
  public async onClickIsEvalinkEnabled(value: boolean, isChanged: boolean) {
    this.isEvalinkEnabled = {
      isEvalinkEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle', isChanged)
  }

  public async onClickIsEvalinkDeviceOverrideEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEvalinkDeviceOverrideEnabled = {
      isEvalinkDeviceOverrideEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle', isChanged)
  }

  public async onClickSaveEvalinkConfig(evalinkData: any) {
    this.evalinkData = evalinkData
    this.addToModified('Evalink Notifications', this.evalinkData?.isChanged)
  }

  // --------------------------- SMTP integration update ---------------------------
  public async onToggleSmtpStatus(value: boolean, isChanged: boolean) {
    this.isSmtpIntegrationEnabled = { isSmtpEnabled: value, isChanged }
    this.addToModified('Smtp Integration', isChanged)
  }

  public onClickSaveSmtpIntegrationConfig(smtpData: any) {
    this.smtpIntegrationData = smtpData
    this.addToModified('Smtp Integration', this.smtpIntegrationData?.isChanged)
  }

  public addToModified(name: string, isChanged: boolean) {
    this.modifiedSections = this.modifiedSections.filter(
      (item) => item !== name
    )
    if (isChanged) {
      this.modifiedSections.push(name)
    }
  }
  public changeCounts: { [key: string]: number } = {}
  //when there  are multiple toggles in a section need to use this function for adding to modifiedSections
  public addToModifiedForToggles(name: string, isChanged: boolean) {
    // Increment or decrement the change count for the section
    this.changeCounts[name] =
      (this.changeCounts[name] || 0) + (isChanged ? 1 : -1)
    // Update modifiedSections based on change count
    if (this.changeCounts[name] > 0) {
      if (!this.modifiedSections.includes(name)) {
        this.modifiedSections.push(name)
      }
    } else {
      this.modifiedSections = this.modifiedSections.filter(
        (item) => item !== name
      )
    }
  }

  public positiveNumberRule = (value: number) => {
    const isValid = !!value && value >= 0
    return isValid || 'Please enter a positive number'
  }

  public percentageNumberRule = (value: number) => {
    const isValid = !!value && value >= 0 && value <= 100
    return isValid || 'Please enter a number between 0 and 100'
  }
}
</script>

<style scoped>
code {
  font-size: 0.8rem;
  font-weight: 500;
  color: #3c87c0;
  cursor: pointer;
}

.card-style {
  padding: 5px;
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1.5px;
}

.cart-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1.5px;
}

.card-block-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1.5px;
}

.card-component-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid #dadada 1.5px;
  border-right: solid #dadada 1.5px;
}

.disable-overlay-customer {
  background-color: #f2f2f2 !important;
}

.disable-overlay-admin {
  background-color: rgba(147, 141, 118, 0.3) !important;
}

.account-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.email-row {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

#custom-tab-items {
  background-color: transparent !important;
}

.sticky-bar {
  position: fixed;
  top: 10px;
  right: 10px;
  left: 267px;
  display: flex;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center !important;
  padding: 1em 1em;
  z-index: 10;
}

.discard-btn-dark:hover {
  background-color: #6b580e !important;
  color: #fff;
}

.sticky-button-group {
  display: flex;
  gap: 1em;
}

.blur-background {
  backdrop-filter: blur(3px);
}

.snack-bar-margin {
  margin-bottom: 120px;
}

.notification-tabs {
  width: 100%;
  padding: 0;
  margin: 0;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.notification-tab {
  flex: 1;
  max-width: inherit;
  text-transform: initial;
  width: 100%;
}

.notification-tab-items {
  width: 100%;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
</style>
