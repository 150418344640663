<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
      data-test-id="ftp-configuration-expand-bar"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="ftp-configuration-expand-title"
      >
        FTP
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>

    <!-- FTP config data-->
    <div v-if="isExpanded" class="pr-3 pl-3 py-4 form">
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="ftpHost"
        label="FTP Host"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="ftpHostError"
        :error="ftpHostError.length > 0"
        data-test-id="ftp-host-input"
      ></v-text-field>
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="ftpPort"
        label="FTP Port (Optional - If default port is 21)"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        data-test-id="ftp-port-input"
      ></v-text-field>
      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="ftpUser"
        label="FTP Username"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="ftpUserError"
        :error="ftpUserError.length > 0"
        data-test-id="ftp-user-input"
      ></v-text-field>

      <v-text-field
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        v-model="ftpPassword"
        label="FTP Password"
        outlined
        color="secondary"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="ftpPasswordError"
        :error="ftpPasswordError.length > 0"
        data-test-id="ftp-password-input"
        type="password"
      ></v-text-field>
      <div class="text-left notification-title pl-1">
        <v-text-field
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? ''
                : 'transparent !important',
            height: '56px',
            marginBottom: '20px'
          }"
          v-model="ftpFilePath"
          label="File path (Optional) Ex-> alarms/camera1"
          outlined
          :disabled="FtpPathEnabled()"
          color="secondary"
          data-test-id="ftp-file-path-input"
          :class="contentOverlayClass"
        />

        <v-tooltip
          v-if="this.level === 'site' || this.level === 'camera'"
          left
          :open-on-click="false"
          :open-on-focus="false"
          nudge-left="6"
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              size="15"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor,
                marginLeft: '10px'
              }"
              >mdi-information-variant-circle
            </v-icon>
          </template>

          <span
            class="mx-2 white--text"
            data-test-id="block-header-tooltip-text"
            >ON: activate these configs<br />
            OFF: activate upper level configs</span
          >
        </v-tooltip>
        <v-switch
          v-if="this.level === 'site' || this.level === 'camera'"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          v-model="isFtpPathEnabled"
          text
          class="notification-switch pt-1"
          color="secondary"
          inset
          @change="onClickIsFtpPathEnabled"
          data-test-id="ftp-ftp-image-sequence-switch"
        ></v-switch>
      </div>
      <p
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
        class="text-left red--text"
        data-test-id="ftp-ftp-path-required-warning-msg"
      >
        *Make sure your file path exists on the FTP server
      </p>

      <div
        class="font-weight-bold text-left notification-title pl-1"
        v-if="notificationType === 'Alarm'"
      >
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="ftp-ftp-notification-switch-label"
        >
          Upload videos as an image sequence
        </div>
        <v-switch
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          v-model="isUploadImageSequence"
          text
          class="notification-switch pt-1"
          color="secondary"
          inset
          @change="onClickIsImageSequenceEnabled"
          :disabled="isToggleDisabled || !isWriteEnabled"
          data-test-id="ftp-ftp-image-sequence-switch"
        ></v-switch>
      </div>

      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="ftp-ftp-notification-switch-label"
        >
          Turn on/off FTP notifications
        </div>
        <v-switch
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          id="alarm-ftp-notification-switch"
          v-model="isFTPNotificationsEnabled"
          text
          class="notification-switch pt-1"
          color="secondary"
          inset
          @change="onClickIsFTPEnabled"
          :disabled="isToggleDisabled || !isWriteEnabled"
          data-test-id="ftp-ftp-notification-switch"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

enum nodeLevel {
  Site = 'site',
  Camera = 'camera',
  Client = 'client'
}

@Component({
  components: { Button }
})
export default class FtpNotificationConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string
  @Prop() isNotificationFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public isFTPNotificationsEnabled: boolean = false
  public ftpHost: string = ''
  public ftpPort: string = ''
  public ftpFilePath: string = ''
  public ftpUser: string = ''
  public ftpPassword: string = ''
  public isUploadImageSequence: boolean = false
  public isFtpPathEnabled: boolean = false

  public ftpHostError: string = ''
  public ftpUserError: string = ''
  public ftpPasswordError: string = ''
  public pastFtpFilePath: string = ''

  public originalFtpData: any = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isNotificationFieldEnabled === null
      ? this.configuration?.blockToggles?.notifications !== true
      : this.isNotificationFieldEnabled !== true &&
          this.level !== nodeLevel.Client
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  public showingConfig: {
    ftpData: {
      host: string
      port: string
      filePath: string
      user: string
      password: string
      isUploadImageSequence: boolean
      isFtpPathEnabled: boolean
    }
    isFTPNotificationsEnabled: boolean
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('notificationType')
  @Watch('isNotificationFieldEnabled')
  public watchConfiguration() {
    if (!this.isToggleDisabled) {
      if (this.configuration) {
        this.showingConfig = this.configuration
      }
    } else {
      if (this.level === nodeLevel.Site) {
        this.showingConfig = this.clientConfig
      }
      if (this.level === nodeLevel.Camera) {
        this.showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (this.configuration?.ftpData?.isFtpPathEnabled === true) {
      if (this.configuration) {
        this.showingConfig = {
          ...this.showingConfig,
          ftpData: {
            ...this.showingConfig.ftpData,
            isFtpPathEnabled: this.configuration?.ftpData?.isFtpPathEnabled,
            filePath: this.configuration?.ftpData?.filePath ?? ''
          }
        }
      }
    } else {
      if (this.level === nodeLevel.Site) {
        this.showingConfig = {
          ...this.showingConfig,
          ftpData: {
            ...this.showingConfig?.ftpData,
            isFtpPathEnabled: this.configuration?.ftpData?.isFtpPathEnabled,
            filePath: this.clientConfig?.ftpData?.filePath ?? ''
          }
        }
      }
      if (this.level === nodeLevel.Camera) {
        this.showingConfig = this.siteConfig?.ftpData?.isFtpPathEnabled
          ? {
              ...this.showingConfig,
              ftpData: {
                ...this.showingConfig?.ftpData,
                isFtpPathEnabled: this.configuration?.ftpData?.isFtpPathEnabled,
                filePath: this.siteConfig?.ftpData?.filePath ?? ''
              }
            }
          : {
              ...this.showingConfig,
              ftpData: {
                ...this.showingConfig?.ftpData,
                isFtpPathEnabled: this.configuration?.ftpData?.isFtpPathEnabled,
                filePath: this.clientConfig?.ftpData?.filePath ?? ''
              }
            }
      }
    }

    if (this.showingConfig) {
      this.isFTPNotificationsEnabled =
        this.showingConfig.isFTPNotificationsEnabled
      this.ftpHost = this.showingConfig.ftpData?.host ?? ''
      this.ftpPort = this.showingConfig.ftpData?.port ?? ''
      this.ftpFilePath = this.showingConfig.ftpData?.filePath ?? ''
      this.ftpUser = this.showingConfig.ftpData?.user ?? ''
      this.ftpPassword = this.showingConfig.ftpData?.password ?? ''
      this.isUploadImageSequence =
        this.showingConfig.ftpData?.isUploadImageSequence ?? false
      this.isFtpPathEnabled =
        this.showingConfig.ftpData?.isFtpPathEnabled ?? false

      this.isExpanded = this.isFTPNotificationsEnabled

      this.originalFtpData = {
        isFTPNotificationsEnabled: this.isFTPNotificationsEnabled,
        ftpHost: this.ftpHost,
        ftpPort: this.ftpPort,
        ftpFilePath: this.ftpFilePath,
        ftpUser: this.ftpUser,
        ftpPassword: this.ftpPassword,
        isUploadImageSequence: this.isUploadImageSequence,
        isFtpPathEnabled: this.isFtpPathEnabled
      }
    }
  }

  @Watch('isFtpPathEnabled')
  public onFtpPathEnabledChange() {
    if (this.isFtpPathEnabled === true) {
      if (this.configuration) {
        this.ftpFilePath = this.configuration.ftpData.filePath ?? ''
      }
    } else {
      if (this.level === nodeLevel.Site) {
        this.pastFtpFilePath = this.configuration.ftpData.filePath ?? ''
        this.ftpFilePath = this.clientConfig.ftpData.filePath ?? ''
      }
      if (this.level === nodeLevel.Camera) {
        this.pastFtpFilePath = this.configuration.ftpData.filePath ?? ''
        this.showingConfig = this.siteConfig?.ftpData?.isFtpPathEnabled
          ? (this.ftpFilePath = this.siteConfig.ftpData.filePath ?? '')
          : (this.ftpFilePath = this.clientConfig.ftpData.filePath ?? '')
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isFTPNotificationsEnabled !==
      this.originalFtpData.isFTPNotificationsEnabled
    )
  }

  public hasFtpPathChanged(): boolean {
    return this.isFtpPathEnabled !== this.originalFtpData.isFtpPathEnabled
  }

  public hasDataChanged(): boolean {
    return (
      this.ftpHost?.trim() !== this.originalFtpData.ftpHost ||
      this.ftpPort?.trim() !== this.originalFtpData.ftpPort ||
      this.ftpFilePath?.trim() !== this.originalFtpData.ftpFilePath ||
      this.ftpUser?.trim() !== this.originalFtpData.ftpUser ||
      this.ftpPassword?.trim() !== this.originalFtpData.ftpPassword ||
      this.isUploadImageSequence !==
        this.originalFtpData.isUploadImageSequence ||
      this.isFtpPathEnabled !== this.originalFtpData.isFtpPathEnabled
    )
  }

  //watch for FTP start
  public onClickIsFTPEnabled(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public onClickIsImageSequenceEnabled(value) {
    this.isUploadImageSequence = value
  }

  public onClickIsFtpPathEnabled(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public FtpPathEnabled() {
    if (this.level === nodeLevel.Site || this.level === nodeLevel.Camera) {
      return !this.isFtpPathEnabled
    } else {
      return this.isToggleDisabled || !this.isWriteEnabled
    }
  }

  @Watch('ftpHost')
  @Watch('ftpPort')
  @Watch('ftpFilePath')
  @Watch('ftpUser')
  @Watch('ftpPassword')
  @Watch('isUploadImageSequence')
  @Watch('isFTPNotificationsEnabled')
  @Watch('isFtpPathEnabled')
  public onClickSaveFTPConfig() {
    let isError: boolean = false
    this.ftpHostError = ''
    this.ftpUserError = ''
    this.ftpPasswordError = ''

    if (this.ftpHost?.trim() === '') {
      isError = true
      this.ftpHostError = 'FTP Host is required'
    }

    if (this.ftpUser?.trim() === '') {
      isError = true
      this.ftpUserError = 'FTP username is required'
    }

    if (this.ftpPassword?.trim() === '') {
      isError = true
      this.ftpPasswordError = 'FTP password is required'
    }
    let ftpData = {}
    const commonFtpData = {
      host: this.ftpHost?.trim(),
      port: this.ftpPort?.trim(),
      password: this.ftpPassword?.trim(),
      user: this.ftpUser?.trim(),
      isFtpPathEnabled: this.isFtpPathEnabled,
      isUploadImageSequence: this.isUploadImageSequence,
      isError,
      isChanged:
        !isError &&
        (this.hasDataChanged() ||
          this.hasSwitchChanged() ||
          this.hasFtpPathChanged())
    }

    ftpData = {
      ...commonFtpData,
      filePath:
        !this.isFtpPathEnabled && this.level !== nodeLevel.Client
          ? this.pastFtpFilePath?.trim()
          : this.ftpFilePath?.trim()
    }
    this.onChange(ftpData)
  }

  public get contentOverlayClass() {
    if (!this.isFtpPathEnabled) {
      if (this.isUserAdmin && this.level !== nodeLevel.Client) {
        return 'disable-overlay-admin'
      }
      if (this.level !== nodeLevel.Client) {
        return 'disable-overlay-customer'
      }
    }
    if (this.isUserAdmin && this.level !== nodeLevel.Client) {
      return 'admin-enable-overlay'
    }
    if (this.level !== nodeLevel.Client) {
      return 'customer-enable-overlay'
    }
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.customer-enable-overlay {
  background-color: #fff !important;
}

.admin-enable-overlay {
  background-color: #333333 !important;
}

.disable-overlay-customer {
  background-color: #f2f2f2 !important;
}

.disable-overlay-admin {
  background-color: rgba(147, 141, 118, 0.3) !important;
}
</style>
