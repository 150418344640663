<template>
  <div>
    <div v-if="promiseQubeConnectivityFetchStatus === 'Loading'">
      <v-progress-circular indeterminate color="secondary" />
    </div>
    <div v-else-if="promiseQubeConnectivityFetchStatus === 'Ready'">
      <GChart type="LineChart" :data="data" :options="inputTypeChartOptions" />
    </div>
    <div v-else-if="promiseQubeConnectivityFetchStatus === 'Error'">
      <p>No data avaiable for connectivity graph</p>
    </div>
  </div>
</template>

<script lang="ts">
interface SiteConnectivityParams {
  siteDocId: string
  timestamp: number
}

import { Component, Prop, Vue } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts/legacy'
import { Action, Getter, Mutation } from 'vuex-class'
import { promiseQubeConnectivityChartOptions } from '@/utils/Charts'
import { SiteConnectivityResponse } from '@/utils/Interfaces'
import { ChartDataStatus } from '@/utils/Constants'

const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    GChart
  }
})
export default class PromiseQubeConnectivityChart extends Vue {
  @Prop() public startTime: number
  @Prop() public siteDocId: string
  @Mutation('setPromiseQubeConnectivityFetchStatus', namespaceConfig)
  public setPromiseQubeConnectivityFetchStatus!: (value: string) => void
  @Getter('currentUser', namespaceUser) public currentUser: { role: string }
  @Getter('getColors', namespaceUser) public getColors!: {
    [key: string]: string
  }
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getPromiseQubeConnectivityData', namespaceConfig)
  public promiseQubeConnectivityData: SiteConnectivityResponse
  @Getter('getPromiseQubeConnectivityFetchStatus', namespaceConfig)
  public promiseQubeConnectivityFetchStatus: string
  @Action('getSiteConnectivityData', namespaceConfig)
  private getSiteConnectivityData: (
    requestParams: SiteConnectivityParams
  ) => Promise<SiteConnectivityResponse>

  public data = []
  public options = {}
  public SAMPLING_TIME: number = 300000

  public async mounted() {
    this.computeSiteConnectivityGraph()
  }

  public get textColors(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors?.lightPrimaryColor
      : this.getColors?.darkBlackColor
  }

  public get inputTypeChartOptions(): any {
    return promiseQubeConnectivityChartOptions({
      textColors: this.textColors
    })
  }

  private async computeSiteConnectivityGraph() {
    try {
      // Fetch qube connectivity data for the given site and
      // the given start time. We will get the timestamps of each
      // instance when qube pinged site connectivity backend endpoint.
      await this.getSiteConnectivityData({
        siteDocId: this.siteDocId,
        timestamp: this.startTime
      })
      const fetchedData = await this.promiseQubeConnectivityData
      const pingTime = fetchedData.pingTimes

      this.data = [['Time', 'Connectivity']]

      const now = Date.now()

      // The sampling time of the connectivity graph is 5mins.
      // If the connectivity timestamp lies between any two sampling
      // points in the chart, we would consider it as a 'HIGH'(1) value.
      // Otherwise y-Axis value would be 'LOW'(0).
      for (let time = this.startTime; time <= now; time += this.SAMPLING_TIME) {
        const pingValue = pingTime.some((ping) => {
          const pingDate = new Date(ping)
          return (
            pingDate.getTime() >= time &&
            pingDate.getTime() < time + this.SAMPLING_TIME
          )
        })
          ? 1
          : 0
        this.data.push([new Date(time), pingValue])
      }
    } catch (e) {
      this.setPromiseQubeConnectivityFetchStatus(ChartDataStatus.ERROR)
    }
  }
}
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}
</style>
