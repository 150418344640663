import { alarmCountsForQuota } from '@/utils/ELKQueries'
import { countNodes, formatQuotaSummary } from '@/utils/ChartUtils'
import {
  cameraCollection,
  clientCollection,
  siteCollection,
  statisticsProvider,
  userCollection
} from '@/provider/firebase'
import { configProvider } from '@/provider/firebase'
import moment from 'moment'
import { getMetrics, getStats } from '@/services/api-admin/metrics/metrics'
import { ChartDataStatus } from '@/utils/Constants'
import { user } from 'firebase-functions/v1/auth'
import { UserLevel } from '@/utils/Constants'

const actions = {
  async fetchQuotaData(
    { commit },
    payload: {
      clientId: string
    }
  ) {
    const query = alarmCountsForQuota(
      moment().startOf('month'),
      moment().endOf('month'),
      [],
      null,
      payload.clientId,
      null,
      null,
      null,
      false
    )
    const quotaData = await statisticsProvider.fetchDataFromElastic(query)
    const alarmCountForMonth = formatQuotaSummary(quotaData?.data)
    commit(
      'setUsedAlarmQuotaPerMonth',
      alarmCountForMonth.totalAlarmCountPerMonth
    )
  },
  async loadRouteParams({ commit, rootState, state }: any, currentUser: any) {
    let counts = {
      customers: 0,
      clients: 0,
      sites: 0,
      cameras: 0
    }

    let treeNodes = rootState.config.nodes
    if (!treeNodes) {
      return
    }

    const userFilter = (treeNode: any) => {
      return treeNode.userFirestoreId === state.routeParams.userId
    }
    const clientFilter = (treeNode: any) => {
      return treeNode.itemId === state.routeParams.clientId
    }

    const siteFilter = (treeNode: any) => {
      return treeNode.itemId === state.routeParams.siteId
    }

    if (currentUser === 'Administrator') {
      if (state.routeParams.userId) {
        treeNodes = treeNodes.filter(userFilter)
        if (treeNodes.length > 0) {
          treeNodes = treeNodes[0].children
        }
      }
    }

    if (state.routeParams.clientId) {
      treeNodes = treeNodes.filter(clientFilter)
      if (treeNodes.length > 0) {
        treeNodes = treeNodes[0].children
      }
    }

    if (state.routeParams.siteId) {
      treeNodes = treeNodes.filter(siteFilter)
      if (treeNodes.length > 0) {
        treeNodes = treeNodes[0].children
      }
    }

    if (state.routeParams.cameraId) {
      treeNodes = []
    }

    countNodes(treeNodes, counts)
  },
  async setAllocatedQuota({ commit }: any, payload: string) {
    try {
      const { isQuotaEnabled, alarmQuota, quotaAlertPercentage } =
        await configProvider.getAllocatedQuota(payload)
      commit('setTotalAlarmQuotaPerMonth', {
        isQuotaEnabled,
        alarmQuota,
        quotaAlertPercentage
      })
    } catch (error) {
      console.error('Error getting allocated quota: ', error)
    }
  },
  async fetchNodeCount(
    { commit },
    payload: {
      type: 'user' | 'client' | 'site' | 'camera'
      userId?: string
      clientId?: string
      siteId?: string
    }
  ): Promise<number> {
    const { type, userId, clientId, siteId } = payload

    // Global level
    if (!userId && !clientId && !siteId) {
      switch (type) {
        case 'user':
          const userSnapshot = await userCollection.get()
          return userSnapshot.size
        case 'client':
          const clientSnapshot = await clientCollection.get()
          return clientSnapshot.size
        case 'site':
          const siteSnapshot = await siteCollection.get()
          return siteSnapshot.size
        case 'camera':
          const cameraSnapshot = await cameraCollection.get()
          return cameraSnapshot.size
      }
    }

    // User level
    if (userId && !clientId && !siteId) {
      switch (type) {
        case 'client':
          const userClientsSnapshot = await clientCollection
            .where('users', 'array-contains', userId)
            .get()
          return userClientsSnapshot.size
        case 'site':
          const userSitesSnapshot = await siteCollection
            .where('users', 'array-contains', userId)
            .get()
          return userSitesSnapshot.size
        case 'camera':
          const userCamerasSnapshot = await cameraCollection
            .where('users', 'array-contains', userId)
            .get()
          return userCamerasSnapshot.size
      }
    }

    // Client level
    if (userId && clientId && !siteId) {
      switch (type) {
        case 'site':
          const clientSnapshot = await clientCollection
            .where('clientId', '==', clientId)
            .get()
          if (!clientSnapshot.empty) {
            const clientRef = clientSnapshot.docs[0].ref
            const clientSitesSnapshot = await siteCollection
              .where('client', '==', clientRef)
              .where('users', 'array-contains', userId)
              .get()
            return clientSitesSnapshot.size
          }
          return 0
        case 'camera':
          const clientDocSnapshot = await clientCollection
            .where('clientId', '==', clientId)
            .get()
          if (!clientDocSnapshot.empty) {
            const clientRef = clientDocSnapshot.docs[0].ref
            const clientSites = await siteCollection
              .where('client', '==', clientRef)
              .where('users', 'array-contains', userId)
              .get()

            let clientCameraCount = 0
            for (const siteDoc of clientSites.docs) {
              const siteRef = siteDoc.ref
              const siteCamerasSnapshot = await cameraCollection
                .where('site', '==', siteRef)
                .where('users', 'array-contains', userId)
                .get()
              clientCameraCount += siteCamerasSnapshot.size
            }
            return clientCameraCount
          }
          return 0
      }
    }

    // Site level
    if (userId && clientId && siteId) {
      switch (type) {
        case 'camera':
          const clientDocSnapshot = await clientCollection
            .where('clientId', '==', clientId)
            .get()

          if (!clientDocSnapshot.empty) {
            const clientRef = clientDocSnapshot.docs[0].ref

            const siteSnapshot = await siteCollection
              .where('siteId', '==', siteId)
              .where('client', '==', clientRef)
              .where('users', 'array-contains', userId)
              .get()

            if (!siteSnapshot.empty) {
              const siteRef = siteSnapshot.docs[0].ref

              const siteCamerasSnapshot = await cameraCollection
                .where('site', '==', siteRef)
                .where('users', 'array-contains', userId)
                .get()

              return siteCamerasSnapshot.size
            }
          }
          return 0
      }
    }

    return 0
  },

  async fetchUsageMetrics(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
    }
  ) {
    commit('setUsageMetricsStatus', ChartDataStatus.LOADING)

    const formattedStartDate = moment(payload.startTime).toISOString()
    const formattedEndDate = moment(payload.endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      timezone
    }

    try {
      const response = await getMetrics(params)
      commit('setUsageMetricsData', response.data)
      commit('setUsageMetricsStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setUsageMetricsStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchUsageMetrics', error)
    }
  },
  async fetchPromiseQubeUsageData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      userLevel: UserLevel
    }
  ) {
    commit('setPromiseQubeUsageStatus', ChartDataStatus.LOADING)

    const formattedStartDate = moment(payload.startTime).toISOString()
    const formattedEndDate = moment(payload.endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'qube-data-summary',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId: payload.userId,
      clientId: payload.clientId,
      siteId: payload.siteId,
      cameraId: payload.cameraId,
      timezone,
      userLevel: payload.userLevel
    }

    try {
      const response = await getStats(params)
      commit('setPromiseQubeUsageData', response.data)
      commit('setPromiseQubeUsageStatus', ChartDataStatus.READY)
      return response.data
    } catch (error) {
      commit('setPromiseQubeUsageStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchPromiseQubeUsageData', error)
    }
  },
  async fetchIncidentsPerCustomersData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
    }
  ) {
    commit('setIncidentsCustomerStatus', ChartDataStatus.LOADING)

    const formattedStartDate = moment(payload.startTime).toISOString()
    const formattedEndDate = moment(payload.endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incidents-per-customer-chart',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setIncidentsCustomerData', response.data)
      commit('setIncidentsCustomerStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setIncidentsCustomerStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchIncidentsPerCustomersData', error)
    }
  },
  async fetchIncidentsOverTimeData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setIncidentsOverTimeStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incidents-over-time',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setIncidentsOverTimeData', response.data)
      commit('setIncidentsOverTimeStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setIncidentsOverTimeStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchIncidentsOverTimeData', error)
    }
  },
  async fetchIncidentsResultDistributionData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setIncidentsResultDistributionStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incidents-result-distribution',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setIncidentsResultDistributionData', response.data)
      commit('setIncidentsResultDistributionStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setIncidentsResultDistributionStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchIncidentsResultDistributionData', error)
    }
  },
  async fetchResultDistributionData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setResultDistributionStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'result-distribution',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setResultDistributionData', response.data)
      commit('setResultDistributionStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setResultDistributionStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchResultDistributionData', error)
    }
  },
  async fetchFilteredAlarmsAccumulatedOverTimeData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit(
      'setFilteredAlarmsAccumulatedOverTimeStatus',
      ChartDataStatus.LOADING
    )

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'reduction-chart',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setFilteredAlarmsAccumulatedOverTimeData', response.data)
      commit(
        'setFilteredAlarmsAccumulatedOverTimeStatus',
        ChartDataStatus.READY
      )
    } catch (error) {
      commit(
        'setFilteredAlarmsAccumulatedOverTimeStatus',
        ChartDataStatus.ERROR
      )
      console.log('Error in fetchFilteredAlarmsAccumulatedOverTimeData', error)
    }
  },
  async fetchUniqueCameraIncidentsOverTimeData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setUniqueCameraIncidentsOverTimeStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'unique-camera-incidents-over-time',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setUniqueCameraIncidentsOverTimeData', response.data)
      commit('setUniqueCameraIncidentsOverTimeStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setUniqueCameraIncidentsOverTimeStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchUniqueCameraIncidentsOverTimeData', error)
    }
  },
  async fetchIncidentsByInputTypeData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setIncidentsByInputTypeStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incidents-by-input-type',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setIncidentsByInputTypeData', response.data)
      commit('setIncidentsByInputTypeStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setIncidentsByInputTypeStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchIncidentsByInputTypeData', error)
    }
  },
  async fetchCamerasThrowsIncidentsData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setCamerasThrowsIncidentsStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'cameras-throws-incidents',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setCamerasThrowsIncidentsData', response.data)
      commit('setCamerasThrowsIncidentsStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setCamerasThrowsIncidentsStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchCamerasThrowsIncidentsData', error)
    }
  },
  async fetchSummaryData(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setSummaryStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incident-summary',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setSummaryData', response.data)
      commit('setSummaryStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setSummaryStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchSummaryData', error)
    }
  },
  async fetchIncidentWeekly(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit('setIncidentWeeklyStatus', ChartDataStatus.LOADING)

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incident-weekly',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setIncidentWeeklyData', response.data)
      commit('setIncidentWeeklyStatus', ChartDataStatus.READY)
    } catch (error) {
      commit('setIncidentWeeklyStatus', ChartDataStatus.ERROR)
      console.log('Error in fetchIncidentWeekly', error)
    }
  },

  async fetchIncidentResultDistributionDetailed(
    { commit },
    payload: {
      startTime: moment.Moment
      endTime: moment.Moment
      userId: string
      clientId: string
      siteId: string
      cameraId: string
      timeZone: string
    }
  ) {
    commit(
      'setIncidentResultDistributionDetailedStatus',
      ChartDataStatus.LOADING
    )

    const { startTime, endTime, userId, clientId, siteId, cameraId } = payload

    const formattedStartDate = moment(startTime).toISOString()
    const formattedEndDate = moment(endTime).toISOString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'visualization-type': 'incident-result-distribution-detailed',
      'start-date': formattedStartDate,
      'end-date': formattedEndDate,
      userId,
      clientId,
      siteId,
      cameraId,
      timezone
    }

    try {
      const response = await getStats(params)
      commit('setIncidentResultDistributionDetailedData', response.data)
      commit(
        'setIncidentResultDistributionDetailedStatus',
        ChartDataStatus.READY
      )
    } catch (error) {
      commit(
        'setIncidentResultDistributionDetailedStatus',
        ChartDataStatus.ERROR
      )
      console.log('Error in fetchIncidentResultDistributionDetailed', error)
    }
  }
}

export default {
  ...actions
}
