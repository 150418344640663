import { adminApi } from '@/services/backendServices/signedRequest'

export const getSiteConnectivityData = async (
  params?: Record<string, string | number>
) => {
  return await adminApi.request({
    method: 'GET',
    url: `/promiseq-qube/site-connectivity/${params.siteDocId}?timestamp=${params.timestamp}`
  })
}
