import {
  cameraCollection,
  clientCollection,
  firestore,
  siteCollection,
  storage,
  todosCollection,
  userCollection
} from '@/provider/firebase'
import firebase from 'firebase/compat/app'

const wait = (ms) => new Promise((r) => setTimeout(r, ms))

export default {
  namespaced: true,
  async deleteTodo(todo: any) {
    try {
      return todosCollection.doc(todo.id).delete()
    } catch (error) {
      console.error('Error deleting document: ', error)
    }
  },
  async update(id: string, payload) {
    try {
      return todosCollection.doc(id).update(payload)
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  },
  async setCompleted(todo: any) {
    try {
      return todosCollection.doc(todo.id).update({ completed: true })
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  },
  async setOpinion(payload: any) {
    try {
      return todosCollection.doc(payload.todo.id).update({
        [payload.mode]: payload.opinion,
        workerId: payload.workerId
      })
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  },
  async setFlag(todo: any) {
    try {
      return todosCollection.doc(todo.id).update({ flag: todo.flag })
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  },
  async setDisagreed(todo: any) {
    try {
      return todosCollection.doc(todo.id).update({
        flag: 'worker',
        disagreed: true
      })
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  },

  async matchKeyToUser(key: string) {
    const temporaryKeys = await userCollection
      .where('keys', 'array-contains', key)
      .get()

    const defaultKey = await userCollection.where('defaultKey', '==', key).get()

    const [temporarySnapshot, defaultSnapshot] = await Promise.all([
      temporaryKeys,
      defaultKey
    ])

    const temporaryKeysArray = temporarySnapshot.docs
    const defaultKeyArray = defaultSnapshot.docs

    const keysArray = temporaryKeysArray.concat(defaultKeyArray)

    return keysArray
  },

  //get the final result assessment + isAiOnly
  async getFinalResult(id: string) {
    return await todosCollection
      .where('id', '==', id)
      .where('completed', '==', true)
      .get()
      .then((result) => {
        let todo
        result.forEach((doc) => {
          todo = doc.data()
        })
        if (typeof todo !== 'undefined' && todo!.finalResult) {
          return {
            finalAssessment: todo.finalResult.finalAssessment,
            isAIOnly: todo.isAIOnly,
            personAssessment: todo.finalResult.personAssessment,
            vehicleMovement: todo.finalResult.vehicleMovement
          }
        }
        return 'not-ready'
      })
  },
  async getTodoById(id: string) {
    return await todosCollection
      .where('id', '==', id)
      .get()
      .then((result) => {
        let todo
        result.forEach((doc) => {
          todo = doc.data()
        })
        if (typeof todo !== 'undefined') {
          return todo
        }
        return 'not-found'
      })
  },
  async getLastThreeTodosByCameraRef(todo: any) {
    const { cameraRef, createdAt } = todo
    if (!(cameraRef && createdAt)) {
      return []
    }
    const date = new Date(
      createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
    )
    return await todosCollection
      .where('cameraRef', '==', firestore.doc(cameraRef))
      .where('fileMetadata.mediaType', '==', 'image')
      .where('createdAt', '<=', date)
      .orderBy('createdAt', 'desc')
      .limit(3)
      .get()
      .then((result) => {
        const todos = []
        result.forEach((doc) => {
          todos.push(doc.data())
        })
        return todos
      })
  },
  async fetchLastTodoByCameraRef(cameraDocId: string): Promise<any | null> {
    if (!cameraDocId) {
      return null
    }

    try {
      const snapshot = await todosCollection
        .where('cameraRef', '==', cameraCollection.doc(cameraDocId))
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      if (snapshot.empty) {
        return null
      }

      const lastTodo = snapshot.docs[0].data()
      return lastTodo
    } catch (error) {
      console.error('Error getting last todo by camera reference:', error)
      return null
    }
  },
  async fetchDownloadUrl(ref, delay = 10_000, retries = 2, factor = 2) {
    try {
      const url = await storage.ref(ref).getDownloadURL()
      return url
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying... attempts left: ${retries}`)
        await new Promise((resolve) => setTimeout(resolve, delay))
        return this.fetchDownloadUrl(ref, delay * factor, retries - 1, factor)
      }
      throw error
    }
  },
  async fetchLocalMediaUrl(filePath: string) {
    const localFunctions = firebase.app('on-premise').functions('europe-west1')
    localFunctions.useEmulator('localhost', 5000)
    return localFunctions.httpsCallable('getLocalMedia')({ filePath: filePath })
  },
  async getAccessedCameraRefs(
    userId: string,
    nodeType: string,
    nodeId: string
  ) {
    let cameraRefs: string[] = []
    let query: firebase.firestore.Query

    switch (nodeType) {
      case null:
        query = cameraCollection.where('users', 'array-contains', userId)
        break
      case 'clientId':
        const clientSnapshot = await clientCollection
          .where('clientId', '==', nodeId)
          .get()
        if (!clientSnapshot.empty) {
          const clientRef = clientSnapshot.docs[0].ref
          const sites = await siteCollection
            .where('client', '==', clientRef)
            .get()
          for (const siteDoc of sites.docs) {
            query = cameraCollection
              .where('site', '==', siteDoc.ref)
              .where('users', 'array-contains', userId)
            const cameras = await query.get()
            cameras.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
          }
        } else {
          console.warn(`No client found with clientId: ${nodeId}`)
        }
        break
      case 'siteId':
        const siteSnapshot = await siteCollection
          .where('siteId', '==', nodeId)
          .where('users', 'array-contains', userId)
          .get()
        if (!siteSnapshot.empty) {
          for (const siteDoc of siteSnapshot.docs) {
            const siteRef = siteDoc.ref
            query = cameraCollection
              .where('site', '==', siteRef)
              .where('users', 'array-contains', userId)
            const siteCameras = await query.get()
            siteCameras.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
          }
        } else {
          console.warn(`No site found with siteId: ${nodeId}`)
        }
        break
      case 'cameraId':
        query = cameraCollection
          .where('cameraId', '==', nodeId)
          .where('users', 'array-contains', userId)
        const camera = await query.get()
        camera.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
        break
      default:
        throw new Error(`Unsupported nodeType: ${nodeType}`)
    }

    if (query) {
      const result = await query.get()
      result.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
    }

    return cameraRefs
  },
  async getZonesForIncident(incidentId: string): Promise<string[] | null> {
    try {
      const incidentDoc = await todosCollection.doc(incidentId).get()
      if (!incidentDoc.exists) {
        console.warn(`No incident found with ID: ${incidentId}`)
        return null
      }

      const incidentData = incidentDoc.data()
      return incidentData?.zones ?? null
    } catch (error) {
      console.error(`Error fetching incident with ID: ${incidentId}`, error)
      throw new Error('Failed to fetch incident zones')
    }
  }
}
