export const reductionChartOptions: any = (titles: {
  vAxisTitle: string
  chartTitle: string
  hAxisTitle: string
  textColors: string
}) => {
  return {
    title: titles.chartTitle,
    titleTextStyle: {
      fontSize: 15,
      fontName: 'Poppins',
      titlePosition: 'center',
      color: titles.textColors
    },
    chartArea: {
      left: 50,
      right: 30,
      width: '100%',
      height: '80%'
    },
    hAxis: {
      title: titles.hAxisTitle,
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    vAxis: {
      title: titles.vAxisTitle,
      minValue: 0,
      format: '#',
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    series: {
      0: {
        color: '#4DD057'
      }
    },
    legend: {
      position: 'top',
      alignment: 'end',
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        fontName: 'Poppins',
        auraColor: 'none'
      }
    },
    height: 500,
    backgroundColor: 'none',
    tooltip: {
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12
      }
    }
  }
}

export const chartOptions: any = (titles: {
  vAxisTitle: string
  hAxisTitle: string
  chartTitle: string
  textColors: string
  seriesColor: string
}) => {
  return {
    title: titles.chartTitle,
    titleTextStyle: {
      fontSize: 15,
      fontName: 'Poppins',
      titlePosition: 'center',
      color: titles.textColors
    },
    hAxis: {
      title: titles.hAxisTitle,
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    vAxis: {
      title: titles.vAxisTitle,
      minValue: 0,
      format: '#',
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    chartArea: {
      left: 50,
      right: 30,
      width: '100%',
      height: '80%'
    },
    series: {
      0: {
        color: titles.seriesColor
      }
    },
    legend: {
      position: 'top',
      alignment: 'end',
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        fontName: 'Poppins',
        auraColor: 'none'
      }
    },
    height: 500,
    backgroundColor: 'none',
    tooltip: {
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12
      }
    }
  }
}

export const distributionChartOptions: any = (titles: {
  vAxisTitle: string
  chartTitle: string
  hAxisTitle: string
  textColors: string
}) => {
  return {
    title: titles.chartTitle,
    titleTextStyle: {
      fontSize: 15,
      fontName: 'Poppins',
      titlePosition: 'center',
      color: titles.textColors
    },
    chartArea: {
      left: 50,
      right: 30,
      width: '100%',
      height: '80%'
    },
    hAxis: {
      title: titles.hAxisTitle,
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      },
      format: 'MMM DD, hh:mm a'
    },
    vAxis: {
      title: titles.vAxisTitle,
      minValue: 0,
      format: '#',
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    series: {
      0: {
        color: '#FEA093',
        areaOpacity: 1
      },
      1: {
        color: '#A5E7A8',
        areaOpacity: 1
      },
      2: {
        color: '#FFDD55',
        areaOpacity: 1
      }
    },
    legend: {
      position: 'top',
      alignment: 'end',
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        fontName: 'Poppins',
        auraColor: 'none'
      }
    },
    height: 500,
    backgroundColor: 'none',
    tooltip: {
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12
      }
    }
  }
}

export const inputTypeChartOptions: any = (titles: {
  vAxisTitle: string
  chartTitle: string
  hAxisTitle: string
  textColors: string
}) => {
  return {
    title: titles.chartTitle,
    titleTextStyle: {
      fontSize: 15,
      fontName: 'Poppins',
      titlePosition: 'center',
      color: titles.textColors
    },
    chartArea: {
      left: 50,
      right: 30,
      width: '100%',
      height: '80%'
    },
    hAxis: {
      title: titles.hAxisTitle,
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      },
      format: 'MMM DD, hh:mm a'
    },
    vAxis: {
      title: titles.vAxisTitle,
      minValue: 0,
      format: '#',
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    series: {
      0: {
        color: '#6CD874',
        areaOpacity: 1
      },
      1: {
        color: '#9ED0FC',
        areaOpacity: 1
      },
      2: {
        color: '#FFE57F',
        areaOpacity: 1
      },
      3: {
        color: '#00B7D2',
        areaOpacity: 1
      },
      4: {
        color: '#E0B900',
        areaOpacity: 1
      },
      5: {
        color: '#D36086',
        areaOpacity: 1
      },
      6: {
        color: '#B59DFA',
        areaOpacity: 1
      },
      7: {
        color: '#F1F8E9',
        areaOpacity: 1
      },
      8: {
        color: '#ff5722',
        areaOpacity: 1
      }
    },
    legend: {
      position: 'top',
      alignment: 'end',
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      }
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        fontName: 'Poppins',
        auraColor: 'none'
      }
    },
    height: 500,
    backgroundColor: 'none',
    tooltip: {
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12
      }
    }
  }
}

export const promiseQubeConnectivityChartOptions = (titles: {
  textColors: string
}) => {
  // The verticle axis would have two values either 1 or 0.
  // If there is a connectivity value, verticle axis value will be 1.
  // Otherwise it will be 0. The horizontal axis contains the time in
  // hh:mm from the starting time of the fetch query.
  return {
    chartArea: {
      left: 50,
      right: 30,
      width: '100%',
      height: '80%'
    },
    hAxis: {
      titleTextStyle: {
        color: titles.textColors,
        fontSize: 12,
        fontName: 'Poppins',
        italic: false
      },
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12,
        color: titles.textColors
      },
      format: 'hh:mm'
    },
    vAxis: {
      ticks: [0, 1]
    },
    series: {
      0: {
        color: '#6CD874',
        areaOpacity: 1
      }
    },
    backgroundColor: 'none',
    legend: { position: 'none' },
    tooltip: {
      textStyle: {
        fontName: 'Poppins',
        fontSize: 12
      }
    }
  }
}
